import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import mediaQueries from '@utils/media-queries';
import { colors } from '@utils/vars';

const { highlight: highlightColor } = colors;

const {
  medium,
} = mediaQueries;

const Element = styled.figure`
  position: relative;
  width: 37%;
  max-width: ${({ width }) => width};
  height: auto;
  margin-right: 5%;
  ${({ type }) => (type === 'video' && css`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 18px solid ${highlightColor};
    transform: translate3d(-50%, -50%, 0);
  }
  `)}

  @media ${medium} {
    width: auto;
    max-width: 100%;
    margin: unset;
  }
`;

const Thumbnail = ({
  src,
  type,
}) => (
  <Element
    type={type}
  >
    <img
      alt="thumbnail"
      src={src}
    />
  </Element>
);

Thumbnail.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Thumbnail.defaultProps = {
  type: null,
};

export default Thumbnail;
