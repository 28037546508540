import { css } from 'styled-components';

/* eslint-disable sort-keys */
export const breakpoints = {
  xxlarge: 1900,
  xlarge: 1600,
  large: 1300,
  medium: 1100,
  small: 800,
  xsmall: 600,
  xxsmall: 350,
};

/*
* desktop-xx = large
* tablet-large = large
* tablet-s = small
* */

export const menuBreakpoints = {
  desktopXl: 2240,
  desktop: 1826,
  large: 1279,
  small: 768,
};

export const above = Object.keys(menuBreakpoints).reduce((acc, breakpoint) => {
  acc[breakpoint] = (...args) => css`
    @media(min-width: ${menuBreakpoints[breakpoint]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const below = Object.keys(menuBreakpoints).reduce((acc, breakpoint) => {
  acc[breakpoint] = (...args) => css`
    @media(max-width: ${menuBreakpoints[breakpoint]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

const queries = {};

Object.keys(breakpoints).forEach((size) => {
  queries[size] = `(max-width: ${breakpoints[size]}px)`;
});

export default queries;
