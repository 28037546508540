import GridTransparent from '@components/GridTransparent/GridTransparent';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ConsultationButton from '@components/Insights/ConsultationButton/ConsultationButton';
import {
  ActiveFilterTag,
  BackArrow,
  BackButton,
  BackButtonBackgroundGrid,
  BackButtonBackgroundInner,
  ChatButtonContainer,
  Container,
  CurrentItemContainer,
  FilterItem,
  FilterItemLabel,
  FiltersListContainer,
  FiltersListTitle,
  ItemsList,
  ItemsListWrapper,
  ItemsLoadingIndicator,
  ListTitle,
  ListTitleCount,
  LoadingContainer,
  NavButtonsContainer,
  OpenArrow,
  OpenButton,
} from '@components/Insights/InsightsSidebar/InsightsSidebar.styled';
import useTwoPass from '@utils/useTwoPass';
import InsightsSidebarItem from './InsightsSidebarItem';

const InsightsSidebar = ({
  data,
  items,
  itemsCount,
  allItemsCount,
  filters,
  isFetching,
  hasMorePosts,
  hasPostsError,
  fetchMorePosts,
  suggestedItems,
  suggestedItemsCount,
  chatInteractionId,
  chatInteractionText,
  prevPath,
  isMobileWidth,
}) => {
  const searchParams = typeof window !== 'undefined' ? window.location.search : '';
  const defaultPrevPath = `insights/${searchParams}`;
  const isBrowser = typeof document !== 'undefined';
  const isFetchingRef = useRef(isFetching);

  isFetchingRef.current = isFetching;
  const lastItemRef = useRef();
  const [
    isSidebarOpen,
    setSidebarOpen,
  ] = useState(false);
  const twoPass = useTwoPass();

  const toggleSidebar = useCallback(() => {
    if (isSidebarOpen) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    let intersectionObserver;

    if (lastItemRef.current && hasMorePosts) {
      intersectionObserver = new IntersectionObserver(((entries) => {
        if (entries[0].intersectionRatio <= 0.1) return;

        if (!isFetchingRef.current) {
          fetchMorePosts();
        }
      }), { threshold: 0.4 });

      intersectionObserver.observe(lastItemRef.current);
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, [
    fetchMorePosts,
    hasMorePosts,
    items,
    isFetching,
  ]);

  const hasFilters =
    !!(filters.topic || filters.industry || filters.technology || filters.theme || filters.type);
  const hasRelatedContent = allItemsCount > 0;
  const shouldShowSuggested =
    (!hasFilters && !filters.persona) ||
    ((!hasMorePosts || hasPostsError) && !hasRelatedContent);

  const listTitle = shouldShowSuggested ? 'Suggested content:' : 'Related content:';

  return (
    <Container isOpen={isSidebarOpen}>
      <NavButtonsContainer>
        <BackButton
          to={prevPath || defaultPrevPath}
        >
          <BackArrow />
          all insights
        </BackButton>

        <OpenButton onClick={toggleSidebar}>
          {listTitle}
          <OpenArrow isOpen={isSidebarOpen} />
        </OpenButton>
        <BackButtonBackgroundGrid>
          <BackButtonBackgroundInner>
            <GridTransparent />
          </BackButtonBackgroundInner>
        </BackButtonBackgroundGrid>
      </NavButtonsContainer>

      <CurrentItemContainer>
        <InsightsSidebarItem
          image={data.image}
          url={`/${data.slug}`}
          title={data.title}
          isActive
          type={data.content_type.medium_type}
        />
      </CurrentItemContainer>

      <FiltersListContainer key="filters-container">
        {hasFilters && (
          <>
            <FiltersListTitle>Content filtered by:</FiltersListTitle>
            {filters.topic && (
              <FilterItem>
                <FilterItemLabel>Topic:</FilterItemLabel>
                <ActiveFilterTag>{filters.topic}</ActiveFilterTag>
              </FilterItem>
            )}
            {filters.theme && (
              <FilterItem>
                <FilterItemLabel>Theme:</FilterItemLabel>
                <ActiveFilterTag>{filters.theme}</ActiveFilterTag>
              </FilterItem>
            )}
            {filters.industry && (
              <FilterItem>
                <FilterItemLabel>Industry:</FilterItemLabel>
                <ActiveFilterTag>{filters.industry}</ActiveFilterTag>
              </FilterItem>
            )}
            {filters.technology && (
              <FilterItem>
                <FilterItemLabel>Technology:</FilterItemLabel>
                <ActiveFilterTag>{filters.technology}</ActiveFilterTag>
              </FilterItem>
            )}
            {filters.type && (
              <FilterItem>
                <FilterItemLabel>Type:</FilterItemLabel>
                <ActiveFilterTag>{filters.type}</ActiveFilterTag>
              </FilterItem>
            )}
          </>
        )}
      </FiltersListContainer>

      <LoadingContainer key="loading-container">
        {!isBrowser && (
          <ItemsLoadingIndicator />
        )}
      </LoadingContainer>
      <ItemsListWrapper>
        {isBrowser && (
          <>
            <ListTitle>
              {listTitle}
              {' '}
              <ListTitleCount>
                {shouldShowSuggested ? suggestedItemsCount : itemsCount}
              </ListTitleCount>
            </ListTitle>

            <ItemsList
              as={isMobileWidth ? 'div' : undefined}
              key={twoPass}
            >
              {!shouldShowSuggested && items.map((item) => (
                <InsightsSidebarItem
                  key={item.title}
                  image={item.image}
                  url={item.url}
                  title={item.title}
                  type={item.content_type.medium_type}
                  prevPath={prevPath}
                  ref={lastItemRef}
                />
              ))}
              {shouldShowSuggested && suggestedItems.map((item) => (
                <InsightsSidebarItem
                  key={item.title}
                  image={item.image}
                  url={item.url}
                  title={item.title}
                  type={item.content_type.medium_type}
                  prevPath={prevPath}
                />
              ))}

              {isFetching && (
                <LoadingContainer>
                  <ItemsLoadingIndicator />
                </LoadingContainer>
              )}
            </ItemsList>
          </>
        )}

        <ChatButtonContainer itemsCount={allItemsCount || suggestedItems.length}>
          <ConsultationButton
            driftId={chatInteractionId}
            text={chatInteractionText}
          />
        </ChatButtonContainer>
      </ItemsListWrapper>
    </Container>
  );
};

InsightsSidebar.propTypes = {
  allItemsCount: PropTypes.number,
  chatInteractionId: PropTypes.number,
  chatInteractionText: PropTypes.string,
  data: PropTypes.shape({
    content_type: PropTypes.shape({
      medium_type: PropTypes.string,
    }),
    image: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  fetchMorePosts: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    industry: PropTypes.string,
    persona: PropTypes.string,
    technology: PropTypes.string,
    theme: PropTypes.string,
    topic: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  hasMorePosts: PropTypes.bool,
  hasPostsError: PropTypes.bool,
  isFetching: PropTypes.bool,
  isMobileWidth: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    content_type: PropTypes.shape({
      medium_type: PropTypes.string,
    }),
    image: PropTypes.string,
    url: PropTypes.string,
  })),
  itemsCount: PropTypes.number.isRequired,
  prevPath: PropTypes.string,
  suggestedItems: PropTypes.arrayOf(PropTypes.shape({
    content_type: PropTypes.shape({
      medium_type: PropTypes.string,
    }),
    image: PropTypes.string,
    url: PropTypes.string,
  })),
  suggestedItemsCount: PropTypes.number.isRequired,
};
InsightsSidebar.defaultProps = {
  allItemsCount: 0,
  chatInteractionId: 0,
  chatInteractionText: undefined,
  hasMorePosts: true,
  hasPostsError: false,
  isFetching: false,
  isMobileWidth: false,
  items: [],
  prevPath: undefined,
  suggestedItems: [],
};

export default InsightsSidebar;
