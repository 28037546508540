/* eslint-disable react/no-danger */
/* eslint-disable no-new */
import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import logoImage from '@assets/images/logo.svg';
import mediaQueries, { breakpoints } from '@utils/media-queries';
import {
  Col, Container, Hidden, Row,
} from 'react-awesome-styled-grid';
import {
  ButtonCube,
  Wave,
} from '@components';
import {
  RainSection,
} from '@utils/rain-section';
import {
  colors, spacings,
} from '@utils/vars';
import {
  driftHandler,
} from '@utils/helpers';

const {
  small,
  medium,
} = mediaQueries;

const {
  border: borderColor,
  darkerGray,
} = colors;

const {
  tabletPadding,
} = spacings;

const ChatEnabledWrapper = styled.div`
  width: 'fit-content';
`;

const Jumbotron = styled.div`
  position: relative;
  background-color: ${darkerGray};
  color: #fff;

  @media ${medium} {
    z-index: 1;
    min-height: 330px;
  }
`;

const Logo = styled.img`
  width: auto;
  max-width: 181px;
  height: auto;
  max-height: 100%;
`;

const Rain = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  > canvas {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  @media ${medium} {
    padding: 22px 0 60px 0;
  }
`;

const JumbotronImage = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 50vw;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right,  rgba(38,38,38,1) 0%,rgba(4,4,4,0) 88%,rgba(0,0,0,0) 99%);
  }

  @media ${medium} {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top,  rgba(38,38,38,1) 0%,rgba(4,4,4,0) 88%,rgba(0,0,0,0) 99%);
    }
  }

  @media (min-width: ${breakpoints.xxlarge}px) {
    &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left,  rgba(38,38,38,1) 10%,rgba(4,4,4,0) 88%,rgba(0,0,0,0) 99%);
  }
  }
`;

const Title = styled.div`
  padding-bottom: 17px;
  font-weight: bold;
  font-size: 40px;
`;

const Subtitle = styled.div`
  width: fit-content;
  font-size: 14px;
  font-family: "fira_sanslight_italic", sans-serif;
`;

const HomeLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 120px;

  @media ${small} {
    height: 50px;
  }
`;

const HomeLink = styled.a`
  display: flex;
  align-items: center;
  height: calc(100% - 10px);
  border-right: 1px solid ${borderColor};
  padding-right: 62px;

  @media ${small} {
    border-right: none;
  }
`;
const HeaderTitle = styled.span`
  padding-left: ${tabletPadding};
  font-size: 18px;

  @media ${small} {
    display: none;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const HeaderWave = styled((props) => <Wave {...props} />)`
  margin: 11px -25px 46px 0;

  @media ${small} {
    margin: 11px 0 46px 0;
  }
`;

const Header = ({
  headerTitle,
  buttonLabel,
  driftId,
  image,
  isChatEnabled,
  subtitle,
  title,
}) => {
  const rainEl = useRef(null);
  const [
    isChatActive,
    setChatActive,
  ] = useState(false);
  const [
    isChatLoading,
    setChatLoading,
  ] = useState(false);
  const computedButtonLabel = useMemo(() => (isChatLoading ? 'opening chat . . .' : buttonLabel), [
    buttonLabel,
    isChatLoading,
  ]);

  useEffect(() => {
    let rainRegistered = false;

    if (rainEl.current && !rainRegistered) {
      new RainSection(rainEl.current);
      rainRegistered = true;
    }
  }, [rainEl]);

  return (
    <header>
      <Container>
        <Row justify="center">
          <Col
            md={10}
            lg={10}
            xl={10}
            align="center"
            justify="space-between"
          >
            <Row
              style={{
                flexWrap: 'nowrap',
                width: '100%',
              }}
              align="center"
              justify="space-between"
            >
              <HomeLinkWrapper>
                <HomeLink href="https://merixstudio.com">
                  <Logo src={logoImage} />
                </HomeLink>
              </HomeLinkWrapper>
              <HeaderTitle dangerouslySetInnerHTML={{ __html: headerTitle }} />
            </Row>
          </Col>
        </Row>
      </Container>
      <Jumbotron>
        <Container>
          <Row justify="flex-end">
            <Col
              md={5}
              sm={4}
            >
              <Rain
                rain-section="rain-section"
                ref={rainEl}
              >
                <Title dangerouslySetInnerHTML={{ __html: title.replace('&nbsp;', ' ') }} />
                <Subtitle>
                  <span dangerouslySetInnerHTML={{ __html: subtitle }} />
                  <HeaderWave />
                </Subtitle>
                {isChatEnabled && (
                  <ChatEnabledWrapper brightentrigger="true">
                    <ButtonCube
                      isLoading={isChatLoading}
                      onClick={driftHandler({
                        driftId,
                        isChatActive,
                        setChatActive,
                        setChatLoading,
                      })}
                    >
                      {computedButtonLabel}
                    </ButtonCube>
                  </ChatEnabledWrapper>
                )}
              </Rain>
            </Col>
            <Hidden xs>
              <Col
                style={{ position: 'relative' }}
                sm={4}
                lg={6}
              >
                <JumbotronImage image={image}>
                  <img
                    alt={headerTitle}
                    src={image}
                  />
                </JumbotronImage>
              </Col>
            </Hidden>
          </Row>
        </Container>
      </Jumbotron>
    </header>
  );
};

Header.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  driftId: PropTypes.number,
  headerTitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isChatEnabled: PropTypes.bool.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Header.defaultProps = {
  driftId: undefined,
};

export default Header;
