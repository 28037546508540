/* eslint-disable sort-keys */

export const colors = {
  border: '#e4e4e4',
  darkGray: '#272727',
  darkerGray: '#262626',
  gray: '#666',
  highlight: '#ff4e00',
  light: '#eee',
  lightGray: '#969696',
  markerYellow: '#ffff00',
  mediumLight: '#ccc',
  pdfBg: '#404040',
  shadow: 'rgba(184,184,184,0.5)',
  transparentGray: 'rgba(153,153,153,0.2)',
  white: '#fff',
  black: '#000',
};

export const dimensions = {
  contentWidth: '85vw',
  contentWithTagsWidth: '82.5vw',
  nextPageBoxWidth: '15vw',
  nextPageBoxWithTagsWidth: '18vw',
  sidebarWidth: '15vw',
  sidebarWithTagsWidth: '17.5vw',
};

export const spacings = {
  mobilePadding: '24px',
  tabletPadding: '40px',
  desktopPadding: '80px',
};

export const transitions = {
  duration: '0.4s',
};

export const font = {
  size: '16px',
  pontiac: 'Pontiac',
  firaSans: 'fira_sansregular',
};
