import styled from 'styled-components';
import { below } from '@utils/media-queries';

export const AboutUsContainer = styled.div`
  ${below.large`
    height: 100%;
  `}
`;

export const Description = styled.p`
  margin-top: 40px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 24px;
  font-family: 'Pontiac', sans-serif;
  line-height: 40px;

  ${below.desktopXl`
    margin-top: 10px;
    font-size: 14px;
    line-height: 28px;
  `};
`;
