/* eslint-disable no-new */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';
import {
  colors,
} from '@utils/vars';
import {
  Col, Container, Hidden, Row, Visible,
} from 'react-awesome-styled-grid';
import {
  Icon,
} from '@components';

const {
  small,
  medium,
  large,
  xlarge,
} = mediaQueries;
const {
  gray,
  highlight,
  lightGray,
} = colors;

const Wrapper = styled.section`
  width: 100%;
  height: 48px;
  background-color: #181818;
  font-weight: 200;

  @media ${medium} {
    height: 96px;
  }

  @media ${small} {
    height: 50px;
  }

  .clutch-info {
    overflow: hidden;
    height: 100%;

    @media ${small} {
      width: 100%;
      padding-right: 8px;
      padding-left: 8px;
    }

    &--sticky {
      transform: translateY(0);
    }

    &__block {
      display: flex;
      flex: unset;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100%;


    @media ${large} {
      width: auto;
    }
    @media ${medium} {
      flex: 1 1 50%;
      height: 50%;
    }

    @media ${small} {
        position: relative;
        width: 100%;
        height: 50px;

        &:last-child {
          border-bottom: none;
        }

        &:nth-child(2) {
          display: flex;
        }
      }
    }

    &__logo-figure {
      height: 20px;
    }

    &__logo-img {
      height: 100%;
    }

    &__link {
      display: flex;
    }

    &__link--arrow {
      position: relative;
      margin-right: 5px;
      padding-right: 22px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 0;
        height: 0;
        border-width: 5.5px 0 5.5px 9px;
        border-style: solid;
        border-color: transparent transparent transparent ${highlight};
        transition: 0.18s ease-out;
        transform: translate(0, -50%);
      }

      &:hover::after {
        transform: translate(3px, -50%);
      }

      @media ${small} {
        padding-right: 16px;
      }
    }

    &__text {
      color: ${gray};
      font-weight: 400;
      font-size: 14px;

      @media ${xlarge} {
        font-size: 12px;
      }

      &--with-logo {
        display: flex;
        align-items: center;
        padding-right: 3px;
      }
    }

    &__reviews {
      margin-left: 28px;
      font-size: 14px;

    @media ${medium} {
        display: none;
      }

      &--mobile {
        display: none;
        margin-left: 0;

        .icon-clutch {
          font-size: 20px;
        }

        @media ${medium} {
          display: block;
        }
      }
    }

    &__actual {
      color: ${highlight};
      font-weight: 700;
    }

    &__max,
    &__clients {
      color: ${lightGray};
      font-weight: 700;
    }
  }
  a {
    color: ${gray};
  }
`;

const Clutch = ({
  clients,
  firstText,
  rating,
  reviewsUrl,
  secondText,
}) => (
  <Wrapper>
    <Container className="clutch-info">
      <Row
        align="center"
        justify="space-between"
        style={{ height: '100%' }}
      >
        <Hidden xs>
          <Col
            sm={4}

            className="clutch-info__block"
          >
            <a
              href="https://clutch.co/profile/merixstudio"
              target="_blank"
              rel="noopener noreferrer"
              className="clutch-info__link"
            >
              <figure className="clutch-info__logo-figure">
                <img
                  className="clutch-info__logo-img"
                  src="/clutch-full.svg"
                  alt=""
                />
              </figure>
            </a>
            <p className="clutch-info__reviews">
              <a
                href={reviewsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="clutch-info__link clutch-info__link--arrow"
              >
                Read reviews
              </a>
            </p>
          </Col>
        </Hidden>
        <Col
          sm={4}
          className="clutch-info__block"
        >
          <Row
            style={{ width: '100%' }}
            align="center"
            justify="space-between"
          >
            <Visible xs>
              <a
                href="https://clutch.co/profile/merixstudio"
                target="_blank"
                rel="noopener noreferrer"
                className="clutch-info__link desktop-hide"
              >
                <Icon name="Clutch" />
              </a>
            </Visible>
            <p className="clutch-info__reviews clutch-info__reviews--mobile">
              <a
                href="https://clutch.co/profile/merixstudio#reviews"
                target="_blank"
                rel="noopener noreferrer"
                className="clutch-info__link clutch-info__link--arrow"
              >
                Read reviews
              </a>
            </p>
            <p className="clutch-info__text">
              Rated
              {' '}
              <span className="clutch-info__actual">
                {rating}
              </span>
              {' '}
              /
              {' '}
              <span className="clutch-info__max">5.0</span>
              {' '}
              by
              {' '}
              <span className="clutch-info__clients">{clients}</span>
              {' '}
              clients
              {' '}
            </p>
          </Row>
        </Col>
        <Hidden xs>
          <Col
            sm={4}

            className="clutch-info__block"
          >
            <p className="clutch-info__text clutch-info__text--with-logo">
              <Icon name="clutch" />
              <a
                href="https://clutch.co/profile/merixstudio"
                target="_blank"
                rel="noopener noreferrer"
                className="clutch-info__link"
              >
                {firstText}
              </a>
            </p>
          </Col>
        </Hidden>
        <Hidden xs>
          <Col
            sm={4}
            className="clutch-info__block"
          >
            <p className="clutch-info__text clutch-info__text--with-logo">
              <Icon name="clutch" />
              <a
                href="https://clutch.co/profile/merixstudio"
                target="_blank"
                rel="noopener noreferrer"
                className="clutch-info__link"
              >
                {secondText}
              </a>
            </p>
          </Col>
        </Hidden>
      </Row>
    </Container>
  </Wrapper>
);

Clutch.propTypes = {
  clients: PropTypes.number.isRequired,
  firstText: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  reviewsUrl: PropTypes.string.isRequired,
  secondText: PropTypes.string.isRequired,
};

export default Clutch;
