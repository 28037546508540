import styled from 'styled-components';
import { CustomScroller } from '@components';
import { colors } from '@utils/vars';
import LoadingIndicator from '@components/Insights/LoadingIndicator/LoadingIndicator';
import { Link } from 'gatsby';
import mediaQueries, { breakpoints } from '@utils/media-queries';

const {
  xxsmall, xsmall, small, medium, large, xlarge, xxlarge,
} = mediaQueries;

export const Container = styled.div`
  display: flex;
  flex: 0 0 22.76%;
  flex-direction: column;
  min-width: 320px;
  margin-right: 54px;
  margin-left: -25px;
  padding: 0 25px;

  @media ${xlarge} {
    margin-right: 14px;
  }

  @media ${large} {
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: auto;
    min-width: 0;
    height: 185px;
    box-shadow: 0 -8px 10px 0 rgba(0,0,0,0.07);
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    background: #fff;
    transition: transform 0.35s ease;
    transform: ${({ isOpen }) => (
  isOpen ?
    'translate3d(0, 0, 0)' :
    'translate3d(0, calc(100% - 41px), 0)'
)};
  }
`;

export const BackArrow = styled.span`
  display: inline-block;
  margin-right: 25px;
  border-top: 9px solid transparent;
  border-right: 9px solid ${colors.highlight};
  border-bottom: 9px solid transparent;

  @media ${large} {
    margin-right: 10px;
    border-top-width: 7px;
    border-right-width: 7px;
    border-bottom-width: 7px;
  }
`;

export const BackButton = styled(Link)`
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 28px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  @media ${large} {
    flex: 0 1 200px;
    justify-content: center;
    align-self: stretch;
    margin-bottom: 0;
  }

  @media ${xsmall} {
    font-size: 14px;
  }

  @media ${xxsmall} {
    flex-basis: 110px;
  }
`;

export const OpenArrow = styled.span`
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 10px;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${colors.highlight};
  border-left: 7px solid transparent;
  transition: transform 0.25s ease;
  transform: ${({ isOpen }) => (isOpen ? 'scale(1)' : 'scale(-1)')};
`;

export const OpenButton = styled.button`
  display: none;
  flex: 0 1 200px;
  border: 0;
  padding: 0 10px;
  background-color: transparent;
  color: #000;
  cursor: pointer;

  :focus:not(:focus-visible) {
    outline: none;
  }

  @media ${large} {
    display: block;
  }

  @media ${xsmall} {
    font-size: 14px;
  }
`;

export const NavButtonsContainer = styled.div`
  position: sticky;
  top: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;

  @media ${large} {
    position: relative;
    top: auto;
    flex: 0 0 41px;
    flex-direction: row;
    justify-content: space-around;
    height: 41px;
    border-bottom: 1px solid ${colors.border};
    padding-right: 0;
    padding-left: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1px;
      height: 100%;
      background-color: ${colors.border};
    }
  }

  @media ${xxsmall} {
    justify-content: space-between;

    &:after {
      left: 110px;
    }
  }

`;

export const BackButtonBackgroundGrid = styled.div`
  position: absolute;
  top: -20px;
  right: -10px;
  bottom: 10px;
  left: -10px;
  z-index: 2;
  overflow: hidden;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  background: #fff;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 0%,  rgba(0,0,0,1) 50%,  rgba(0,0,0,1) 68%, rgba(0,0,0,0.1) 100%);

  @media ${large} {
    display: none;
  }
`;

export const BackButtonBackgroundInner = styled.div`
  position: relative;
  top: -20px;
  width: 100vw;
  height: 100%;
  > div {
    margin-left: -5rem;
  }
`;

export const CurrentItemContainer = styled.div`
  @media ${large} {
    display: none;
  }
`;

export const ItemsListWrapper = styled.div`
  position: sticky;
  top: 50px;
`;

/* stylelint-disable no-descending-specificity */
export const ItemsList = styled(CustomScroller)`
  display: block;
  max-height: 545px;
  margin: 0 -25px 20px;
  padding: 0 25px;

  @media (min-width: ${breakpoints.large + 1}px) {

    /* scroll wrapper styles */
    & > div:first-child {
      max-height: inherit;
      margin-right: -25px;
      margin-left: -45px !important;

      /* scroll inner-wrapper styles */
      > div {
        max-height: inherit;
        padding: 0 45px 0 25px !important;
      }
    }

    /* scroll thumb styles */
    & > div:last-child {
      right: 4px;
      width: 4px;
      background-color: ${colors.highlight};

      :hover,
      :focus,
      :active {
        right: 4px !important;
        width: 4px !important;
      }
    }
    &:hover > div:last-child {
      opacity: 1 !important;
    }
  }

  @media ${xxlarge} {
    max-height: calc(24.2vw + 75px);
  }

  @media (max-width: 1555px) {
    max-height: 447px;
  }

  @media ${large} {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0;
    padding: 15px 5rem;

    &:after {
      content: '.';
      display: inline-flex;
      width: 1px;
      color: transparent;
    }
  }

  @media ${medium} {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  @media ${small} {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

`;

export const ListTitle = styled.h3`
  font-size: 16px;

  @media ${xlarge} {
    margin-right: 16px;
  }

  @media ${large} {
    display: none;
  }
`;

export const ListTitleCount = styled.span`
  color: ${colors.highlight};
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: transparent !important;

  &:empty {
    padding: 0;
  }
`;

export const ItemsLoadingIndicator = styled(LoadingIndicator)`
  width: 32px;
  height: 32px;
`;

export const FiltersListContainer = styled.div`
  margin-bottom: 40px;
  &:empty {
    margin-bottom: 0;
  }
  @media ${large} {
    display: none;
  }
`;

export const FiltersListTitle = styled.h4`
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 16px;
`;

export const FilterItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

export const FilterItemLabel = styled.span`
  display: inline-flex;
  margin-right: 16px;
  font-size: 14px;
`;

export const ActiveFilterTag = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.09);
  margin-left: 6px;
  border-radius: 8px;
  padding: 3px 10px;
  background: #fff;
  color: #000;
  font-size: 14px;
  line-height: 18px;
`;

export const ChatButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${large} {
    display: none;
  }
`;
