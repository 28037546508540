import styled from 'styled-components';
import { Link } from 'gatsby';
import mediaQueries, { breakpoints } from '@utils/media-queries';
import { colors } from '@utils/vars';

const {
  small,
  medium,
  xlarge,
} = mediaQueries;

export const Container = styled.div`
  width: 32.15%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .09);
  margin-bottom: 1.5%;
  margin-left: 1.76%;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;

  @media (min-width: ${breakpoints.medium + 1}px) {
    &:nth-of-type(3n+1) {
      margin-left: 0;
    }
  }

  @media ${medium} {
    width: 49%;
    margin-bottom: 2%;
    &:nth-of-type(2n+1) {
      margin-left: 0;
    }
  }


  @media ${small} {
    width: 100%;
    margin-bottom: 4%;
    margin-left: 0;
  }
`;

export const ThumbnailContainerLink = styled(Link)`
  position: relative;
  overflow: hidden;
  display: block;
  margin-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: transparent;
    transition: background 0.4s ease;
  }

  &:hover {
    &:after {
      background: rgba(0,0,0,.7);
    }
  }
`;

export const Thumbnail = styled.img``;

export const TitleLink = styled(Link)`
  display: inline-block;
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  display: inline-block;
  font-weight: 500;
  font-size: 20px;

  @media ${xlarge} {
   font-size: 16px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -10px 0 0;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: ${({ type }) => (type === 'video' ? '50%' : '60%')};
  left: 50%;
  z-index: 5;
  opacity: ${({ type }) => (type === 'video' ? 1 : 0)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: all 0.4s ease;
  transform: translate(-50%, -50%);

  ${ThumbnailContainerLink}:hover & {
    top: 50%;
    opacity: 1;
  }
`;

const iconsData = {
  blog: {
    size: '39px',
  },
  pdf: {
    size: '35px',
  },
  url: {
    size: '35px',
  },
  video: {
    size: '56px',
  },
};

export const Icon = styled.div.attrs(({
  iconName,
}) => ({
  className: `icon-${iconName}`,
}))`
  display: inline-block;
  color: ${colors.highlight};
  font-size: ${({ type }) => iconsData[type]?.size || '35px'};
`;

export const IconTitle = styled.span`
  opacity: ${({ type }) => (type === 'video' ? 0 : 1)};
  display: block;
  max-height: ${({ type }) => (type === 'video' ? '0px' : '30px')};
  margin-top: 5px;
  color: ${colors.highlight};
  font-size: 16px;
  line-height: 24px;
  transition: all 0.4s ease;

  ${ThumbnailContainerLink}:hover & {
    opacity: 1;
    max-height: 30px;
  }
`;
