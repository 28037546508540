import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Head = ({
  meta,
  title,
}) => (
  <Helmet>
    <title>{title}</title>
    <meta
      name="robots"
      content="noindex, nofollow"
    />

    {
        Object.keys(meta).map((key) => (
          <meta
            key={key}
            property={`og:${key}`}
            content={`${meta[key]}`}
          />
        ))
      }
  </Helmet>
);

Head.propTypes = {
  meta: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
};

Head.defaultProps = {
  title: 'Content Experience by Merixstudio',
};

export default Head;
