import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Col,
  Container,
  Row,
} from 'react-awesome-styled-grid';
import {
  Carousel,
  GridTransparent,
  Select,
} from '@components';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  filterItems,
  getSelectItems,
  SELECT_TYPES,
} from '@utils/helpers';
import mediaQueries from '@utils/media-queries';
import { spacings } from '@utils/vars';

const {
  small,
  medium,
} = mediaQueries;

const { mobilePadding } = spacings;

const Content = styled.section`
  position: relative;
  width: 100%;
  padding-top: ${({ paddingTop }) => `${paddingTop + 115}px`};
  padding-bottom: 170px;

  @media ${medium} {
    padding-top: ${({ paddingTop }) => `${paddingTop + 40}px`};
    padding-bottom: 10px;
  }

  @media ${small} {
    padding: 50px 0;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const SelectCol = styled((props) => <Col {...props} />)``;

const FilterTypeCol = styled(SelectCol)`
  margin-left: 46px;

  @media ${small} {
    margin-left: 0;
    padding-top: ${mobilePadding};
  }
`;

const CarouselsContainer = ({
  carousels,
  paddingTop,
  slug,
  windowWidth,
}) => {
  const [
    tags,
    setTags,
  ] = useState(getSelectItems(carousels, SELECT_TYPES.tag));

  const [
    types,
    setTypes,
  ] = useState(getSelectItems(carousels, SELECT_TYPES.contentType));

  const [
    selectedTag,
    selectTag,
  ] = useState(null);

  const [
    selectedType,
    selectType,
  ] = useState(null);

  const [
    filteredItems,
    setFilteredItems,
  ] = useState(carousels);

  const onSelectChange = (type) => (item) => {
    const { value } = item || {};
    const isTagSelect = type === SELECT_TYPES.tag;
    const items = value ?
      filterItems({
        items: carousels,
        tag: isTagSelect ? value : selectedTag,
        type: isTagSelect ? selectedType : value,
      }) :
      carousels;

    if (isTagSelect) {
      if (!value || selectTag === value) {
        selectTag(null);

        if (typeof window !== 'undefined') {
          window.history.replaceState(null, null, window.location.pathname);
        }
      } else {
        selectTag(value);
      }
      setTypes(getSelectItems(carousels, SELECT_TYPES.contentType, value && items));
    } else {
      selectType(value);
      setTags(getSelectItems(carousels, SELECT_TYPES.tag, value && items));
    }

    setFilteredItems(items);
  };

  return (
    <Content paddingTop={paddingTop}>
      <GridTransparent />
      <Container>
        <Row
          style={{ marginBottom: '66px' }}
          id="filters"
        >
          <SelectCol
            offset={{
              md: 1,
            }}
            xs={4}
            sm={3}
            md={3}
            lg={3}
            xl={3}
          >
            <Select
              handleChange={onSelectChange(SELECT_TYPES.tag)}
              options={tags}
              placeholder="Filter by theme"
              selectedOption={selectedTag}
            />
          </SelectCol>
          <FilterTypeCol
            xs={4}
            sm={3}
            md={3}
            lg={3}
            xl={3}
          >
            <Select
              handleChange={onSelectChange(SELECT_TYPES.contentType)}
              options={types}
              placeholder="Filter by content type"
              selectedOption={selectedType}
            />
          </FilterTypeCol>
          <Col />
        </Row>
        <Row justify="center">
          <Col
            md={10}
            lg={10}
            xl={10}
          >
            {filteredItems.map((carousel) => (
              carousel.items.length > 0 && (
                <Carousel
                  carousel={carousel}
                  filterTags={onSelectChange(SELECT_TYPES.tag)}
                  key={carousel.name}
                  selectedTag={selectedTag}
                  slug={slug}
                  windowWidth={windowWidth}
                />
              )
            ))}
          </Col>
        </Row>
      </Container>
    </Content>
  );
};

CarouselsContainer.propTypes = {
  carousels: PropTypes.arrayOf(PropTypes.object).isRequired,
  paddingTop: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default CarouselsContainer;
