/* eslint-disable no-new */
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  Col,
  Container,
  Row,
} from 'react-awesome-styled-grid';
import {
  GridStairs,
  GridTransparent,
} from '@components';
import mediaQueries, { breakpoints } from '@utils/media-queries';
import {
  colors,
  spacings,
} from '@utils/vars';
import {
  reviewsStairs,
  reviewsStairsMobile,
} from '@utils/grid';

const {
  medium,
  large,
} = mediaQueries;
const { mobilePadding } = spacings;
const {
  highlight: highlightColor,
  mediumLight: mediumLightColor,
  light: lightColor,
} = colors;

const delayMixin = () => {
  let styles = '';

  for (let i = 0; i < 4; i += 1) {
    styles += `
      &:nth-child(${i + 1}) {
        transition-delay: ${i * 0.1 + 0.3}s;
      }`;
  }

  return css`${styles}`;
};

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  min-height: 800px;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-image: url(${({ image }) => image});
    background-position: 50% 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .testimonials-slider {
    position: relative;
    min-height: 311px;
    padding-bottom: 191px;

    &__slides {
      min-height: inherit;
    }

    &__slide {
      position: absolute;
      z-index: 1;
      align-items: center;
      min-height: inherit;


      @media ${medium} {
        flex-direction: column-reverse;
        width: 100%;
      }
    }

    &__avatar {
      position: relative;
      opacity: 0;
      height: 160px;
      padding: 25px 0 25px 25px;
      font-size: 0;

      ::after {
        content: '“';
        position: absolute;
        top: 24%;
        left: 12px;
        opacity: 0.58;
        display: block;
        color: ${highlightColor};
        font-weight: 900;
        font-size: 300px;
        font-family: 'Pontiac', sans-serif;
        line-height: 154px;
        transform: translate(100px, 45px);

        @media ${medium} {
          content: '';
        }
      }

      @media ${large} {
        height: 140px;
      }

      @media ${medium} {
        width: 100%;
      }
    }

    &__avatar-figure {
      overflow: hidden;
      width: 110px;
      height: 110px;
      transition: all 0.4s;
      transform: rotate(135deg);

      @media ${large} {
        width: 90px;
        height: 90px;
      }

      @media ${medium} {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(135deg);
      }
    }

    &__avatar-image {
      position: relative;
      top: 50%;
      left: 50%;
      width: 140%;
      transition: all 0.4s;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &__content {
      position: relative;
      padding-left: 40px;

      > * {
        opacity: 0;
        transition: all 0.4s;
        transform: translateY(25px);
      }

      @media ${medium} {
        width: 100%;
        padding-left: 0;
      }
    }

    &__text {
      flex: 1 1 ccs(7, 5);
      padding-right: ccs(7, 0.5);
      color: ${lightColor};
      font-size: 24px;
      font-family: 'fira_sanslight_italic', sans-serif;
      line-height: 32px;


      @media ${large} {
        padding-right: 0;
      }

      @media ${medium} {
        text-align: center;
      }
    }

    &__author {
      width: 100%;
      margin-top: 25px;
      color: ${mediumLightColor};
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;

      @media ${medium} {
        text-align: center;
      }
    }

    &__company {
      width: 100%;
      margin-top: 11px;
      color: ${mediumLightColor};
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;

      @media ${medium} {
        text-align: center;
      }
    }

    &__slide--active {
      z-index: 2;

      .testimonials-slider__avatar {
        opacity: 1;
        transition-delay: 0.4s;

        @media ${medium} {
          padding-top: 50px;
        }
      }

      .testimonials-slider__avatar-figure {
        transition-delay: 0.4s;
        transform: rotate(45deg);

        @media ${medium} {
          position: absolute;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
        }
      }

      .testimonials-slider__content {
        > * {
          opacity: 1;
          transform: none;
          ${delayMixin()}
        }
      }
    }
  }
`;

const Rating = styled.a`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 48px 0 0;
  color: #ddd;
  font-size: 22px;

  .clients-are-saying {
    &-slider__logo {
      height: 20px;
    }

    &-slider__single-star {
      width: 16px;
      height: 16px;
      margin: 0 0 0 5px;
      background-size: 16px;
      background-repeat: no-repeat;

      &--blank {
        background-image: url('/star-empty.svg');
      }

      &--filled {
        background-image: url('/star-full.svg');
      }
    }

    &-slider__stars {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0 0 0 15px;

      &-wrapper {
        position: relative;
        width: 120px;
        height: 16px;
      }
    }
  }

  @media ${medium} {
    justify-content: center;

    .clients-are-saying-slider__single-star {
      &__heading {
        font-size: 32px;
      }

      &-slider {
        &__content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &__rating {
          margin: 15px 0 25px;
        }
      }
    }
  }
`;

const Title = styled.h2`
  margin-top: 130px;
  color: #fff;
  font-weight: 300;
  font-size: 36px;
  font-family: Pontiac, sans-serif;
  line-height: 47px;
  text-align: center;
  letter-spacing: 0;

  .bold {
    font-weight: bold;
  }

  @media ${medium} {
    margin-top: 0;
    margin-bottom: ${mobilePadding};
    font-size: 30px;
  }
`;
const Reviews = ({
  image,
  reviews,
  windowWidth,
}) => {
  const slideInterval = 5000;
  const [
    activeSlide,
    setActiveSlide,
  ] = useState(0);
  // eslint-disable-next-line max-len
  const stairs = useMemo(() => (windowWidth > breakpoints.small ? reviewsStairs : reviewsStairsMobile), [windowWidth]);
  const padding = useMemo(() => (windowWidth > breakpoints.small ? 168 : 50), [windowWidth]);

  setTimeout(() => {
    if (activeSlide + 1 === reviews.length) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  }, slideInterval);

  return (
    <Wrapper image={image}>
      <GridStairs
        colorDown="white"
        offsets={stairs}
      />
      <GridTransparent zIndex={1} />
      <Container style={{ paddingTop: Math.max(...stairs) + padding }}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >
            <Title>
              <span className="bold">
                Clients&nbsp;
              </span>
              are saying
            </Title>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
          >
            <div className="testimonials-slider">
              <div className="testimonials-slider__slides">
                {reviews.map(({
                  namew: author,
                  person: company,
                  comment: content,
                  photo: imageUrl,
                  comment_url: ratingUrl,
                }, index) => (
                  <Row
                    className={
                      `testimonials-slider__slide${index === activeSlide ?
                        ' testimonials-slider__slide--active' :
                        ''
                      }`
                    }
                    key={company}
                  >
                    <Col
                      md={(12 / 7) * 2}
                      lg={(12 / 7) * 2}
                      xl={(12 / 7) * 2}
                      className="testimonials-slider__avatar"
                    >
                      <figure className="testimonials-slider__avatar-figure">
                        <div
                          className="testimonials-slider__avatar-image"
                        >
                          <img
                            src={imageUrl}
                            alt={author}
                          />
                        </div>
                      </figure>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={(12 / 7) * 5}
                      className="testimonials-slider__content"
                    >
                      <div className="testimonials-slider__text">{content}</div>
                      <div className="testimonials-slider__author">
                        {author}
                      </div>
                      <div className="testimonials-slider__company">{company}</div>
                      <Rating
                        href={ratingUrl}
                      >
                        <img
                          src="/clutch-full.svg"
                          className="clients-are-saying-slider__logo"
                          alt="clutch rating"
                        />
                        <div className="clients-are-saying-slider__stars-wrapper">
                          <div className="clients-are-saying-slider__stars clients-are-saying-slider__stars--blank">
                            <div className="clients-are-saying-slider__single-star clients-are-saying-slider__single-star--blank" />
                            <div className="clients-are-saying-slider__single-star clients-are-saying-slider__single-star--blank" />
                            <div className="clients-are-saying-slider__single-star clients-are-saying-slider__single-star--blank" />
                            <div className="clients-are-saying-slider__single-star clients-are-saying-slider__single-star--blank" />
                            <div className="clients-are-saying-slider__single-star clients-are-saying-slider__single-star--blank" />
                          </div>
                          <div
                            className="clients-are-saying-slider__stars clients-are-saying-slider__stars--filled"
                          />
                        </div>
                      </Rating>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

Reviews.propTypes = {
  image: PropTypes.string.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default Reviews;
