import styled from 'styled-components';
import { below } from '@utils/media-queries';

export const OverlaySocialsStyled = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;

  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  font-size: 14px;
  transition: opacity 0.3s ease;

  ${below.large`
    display: none;
  `}
`;
