import styled, { css } from 'styled-components';
import mediaQueries from '@utils/media-queries';
import {
  colors,
  transitions,
} from '@utils/vars';

const {
  medium,
  xlarge,
} = mediaQueries;

const { highlight: highlightColor } = colors;

const { duration } = transitions;

const Tag = styled.button`
  display: inline-block;
  margin: 5px 9px 0 0;
  border: 1px solid ${highlightColor};
  background-color: ${({
    selectedTag,
    tag,
  }) => (selectedTag === tag ? `${highlightColor}` : '#fff')};
  color: ${({
    selectedTag,
    tag,
  }) => (selectedTag === tag ? '#fff' : '#000')};
  font-weight: 400;
  line-height: 14px;
  white-space: nowrap;
  transition: background-color ${duration}, color ${duration};
  cursor: pointer;

  &:hover {
    background-color: ${highlightColor};
    color: #fff;
  }
  ${({ large }) => (
    large ?
      css`
        border-radius: 6px;
        padding: 4px 11px;
        font-size: 13px;
      ` :
      css`
        font-size: 11px;
        padding: 0 6px;
        border-radius: 2px;

        @media ${xlarge} {
          padding: 0 4px;
          font-size: 10px;
        }

        @media ${medium} {
          border-radius: 5px;
          padding: 5px;
        }
  `)
}
`;

export default Tag;
