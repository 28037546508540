import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Col,
  Container,
  Row,
} from 'react-awesome-styled-grid';
import InsightsSelect from '@components/Insights/InsightsSelect/InsightsSelect';
import mediaQueries from '@utils/media-queries';

const {
  xsmall, small, large, xlarge,
} = mediaQueries;

const FilterCol = styled(Col)`
  flex-grow: 1;
  max-width: none !important;
  margin-left: 35px;

  &:first-child {
    margin-left: 0;
  }

  @media ${large} {
    flex-basis: 20%;
    margin-left: 3%;

    &:nth-child(2) {
      margin-left: 0;
    }
  }

  @media ${small} {
    flex-basis: 45%;
    margin-top: 15px;

    &:nth-child(4) {
      margin-left: 0;
    }
  }

  @media ${xsmall} {
    flex-basis: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
`;

const Hint = styled.span`
  font-size: 22px;

  @media ${xlarge} {
   font-size: 18px;
  }
`;

const HintCols = styled(Col)`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  justify-content: center;

  @media ${large} {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 20px;
  }

  @media ${small} {
    margin-bottom: 0;
  }
`;

const HomepageFilters = ({
  availableFilterOptions,
  technologies,
  industries,
  contentTypes,
  themes,
  onFilterChange,
}) => (
  <Container>
    <Row>
      <Col
        xl={10}
        lg={12}
        offset={{
          lg: 0,
          xl: 1,
        }}
      >
        <Row>
          <HintCols>
            <Hint>
              Filter content by:
            </Hint>
          </HintCols>
          <FilterCol
            xs={3}
            sm={2}
            md={2}
            lg={2}
            xl={2}
          >
            <InsightsSelect
              handleChange={(value, event) => {
                onFilterChange('theme', value, event);
              }}
              options={themes}
              placeholder="Themes"
              availableOptions={availableFilterOptions?.themes}
            />
          </FilterCol>
          <FilterCol
            xs={3}
            sm={2}
            md={2}
            lg={2}
            xl={2}
          >
            <InsightsSelect
              handleChange={(value, event) => {
                onFilterChange('technology', value, event);
              }}
              options={technologies}
              placeholder="Technologies"
              availableOptions={availableFilterOptions?.technologies}
            />
          </FilterCol>
          <FilterCol
            xs={3}
            sm={2}
            md={2}
            lg={2}
            xl={2}
          >
            <InsightsSelect
              handleChange={(value, event) => {
                onFilterChange('industry', value, event);
              }}
              options={industries}
              placeholder="Industry"
              availableOptions={availableFilterOptions?.industries}
            />
          </FilterCol>
          <FilterCol
            xs={3}
            sm={2}
            md={2}
            lg={2}
            xl={2}
          >
            <InsightsSelect
              handleChange={(value, event) => {
                onFilterChange('type', value, event);
              }}
              options={contentTypes}
              placeholder="Type"
              availableOptions={availableFilterOptions?.content_types}
            />
          </FilterCol>
        </Row>
      </Col>
    </Row>
  </Container>
);

const selectOptionPropType = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
});

HomepageFilters.propTypes = {
  availableFilterOptions: PropTypes.shape({}),
  contentTypes: PropTypes.arrayOf(selectOptionPropType),
  industries: PropTypes.arrayOf(selectOptionPropType),
  onFilterChange: PropTypes.func.isRequired,
  technologies: PropTypes.arrayOf(selectOptionPropType),
  themes: PropTypes.arrayOf(selectOptionPropType),
};

HomepageFilters.defaultProps = {
  availableFilterOptions: null,
  contentTypes: [],
  industries: [],
  technologies: [],
  themes: [],
};

export default HomepageFilters;
