import styled from 'styled-components';
import { below } from '@utils/media-queries';

export const OverlayContentListStyled = styled.li`
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }


  ${below.desktopXl`
     margin-top: 24px;
  `};

  ${below.large`
    margin-top: 16px;
  `};

  @media (max-height: 768px) {
    margin-top: 16px;
  }

  &--small {
    margin-top: 32px;


  ${below.desktopXl`
     margin-top: 24px;
  `};

  ${below.large`
    margin-top: 16px;
  `};

    @media (max-height: 768px) {
      margin-top: 16px;
    }
  }

  &:not(&--small) + &--small {
    margin-top: 64px;

  ${below.desktopXl`
    margin-top: 48px;
  `};

    ${below.large`
     margin-top: 32px;
  `};

    @media (max-height: 768px) {
      margin-top: 16px;
    }

    @media (max-width: 1280px) and (max-height: 768px) {
      margin-top: 16px;
    }
  }
`;
