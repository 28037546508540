import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '@utils/vars';
import mediaQueries from '@utils/media-queries';

const {
  small, medium, large, xlarge,
} = mediaQueries;

export const Container = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  height: 0;
  box-shadow: 0 5px 18px -1px rgba(0,0,0,0.09);
  margin-bottom: 25px;
  border: 2px solid;
  border-color: ${({ $isActive }) => ($isActive ? colors.highlight : 'transparent')};
  border-radius: 10px;
  padding-bottom: 43.93%;
  background-color: #fff;
  pointer-events: ${({ $isActive }) => ($isActive ? 'none' : 'auto')};

  @media ${large} {
    display: inline-flex;
    flex: 0 0 300px;
    width: 300px;
    margin-right: 25px;
    margin-bottom: 0;
    padding-bottom: 110px;

    &:last-child {
      margin-right: 5rem;
    }
  }

  @media ${medium} {
    &:last-child {
      margin-right: 2.5rem;
    }
  }

  @media ${small} {
    &:last-child {
      margin-right: 1.5rem;
    }
  }
`;
export const RatioWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const RatioInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 23px;

  @media ${xlarge} {
    padding: 14px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  flex: 0 0 30%;
  width: 30%;
  margin-right: 5%;

  &:after {
    content: ${({ type }) => (type === 'video' ? "''" : 'none')};
    position: absolute;
    top: 50%;
    left: 50%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 18px solid ${colors.highlight};
    transform: translate(-50%, -50%);
  }
`;
export const Image = styled.img`
  width: auto;
`;

export const Title = styled.h4`
  flex: 1 0 auto;
  max-width: 65%;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;

  @media ${xlarge} {
    font-size: 13px;
    line-height: 18px;
  }
`;
