import styled from 'styled-components';
import { colors } from '@utils/vars';

const { white } = colors;

export const StyledOverlayContactLink = styled.a`
  display: block;
  color: ${white};
  font-weight: 400;
  font-size: 14px;
  font-family: 'fira_sansregular', serif;
  line-height: 32px;
`;
