import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import mediaQueries from '@utils/media-queries';
import styled from 'styled-components';
import {
  Button,
  NavItem,
  SidebarHeader,
} from '@components';

import {
  colors,
  dimensions,
  transitions,
} from '@utils/vars';

import { checkMobile } from '@utils/helpers';

const {
  xsmall,
  small,
  medium,
  xlarge,
} = mediaQueries;

const {
  border: borderColor,
  darkGray,
} = colors;

const {
  sidebarWidth,
  sidebarWithTagsWidth,
} = dimensions;

const { duration } = transitions;

const thumbnailWidth = typeof window !== 'undefined' && window.matchMedia(`${xsmall}`).matches ? '265' : '300';

const Container = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ hasTags }) => (hasTags ? `${sidebarWithTagsWidth}` : `${sidebarWidth}`)};
  height: 100vh;
  border-right: 1px solid ${borderColor};
  background-color: #fff;

  @media ${xlarge} {
    width: ${({ hasTags }) => (hasTags ? '20vw' : '18vw')};
  }

  @media ${medium} {
    width: ${({ hasTags }) => (hasTags ? '25vw' : '22vw')};
  }

  @media ${small} {
    top: unset;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: unset;
    border: none;
    background-color: ${darkGray};
    transition: transform ${duration};
    transform: ${({ navigationVisibility }) => (navigationVisibility ? 'translate3d(0, 0, 0)' : 'translate3d(0, calc(100% - 41px), 0)')}
  }
`;

const Wrapper = styled.div`
  overflow-y: scroll;
  width: 100%;

  @media ${small} {
    overflow-y: unset;
  }
`;

const List = styled.ul`
  position: relative;
  overflow: auto;

  @media ${small} {
    white-space: nowrap;
    scroll-snap-type: x mandatory;
  }
`;

const Sidebar = ({
  allItems,
  isChatActive,
  content,
  cta,
  currentIndex,
  driftId,
  filterTags,
  hasTags,
  navigationVisibility,
  selectedTag,
  setChatActive,
  setNavigationVisibility,
  setShowMore,
  showMore,
  slug,
}) => {
  const landingLink = slug.startsWith('lp') ? slug : '';
  const isMobile = checkMobile();
  const calculateVisibleItems = () => {
    const minVisibleItems = 8;

    if (isMobile || showMore) {
      return content.length;
    }

    return currentIndex > 7 ? currentIndex + 1 : minVisibleItems;
  };

  const [
    visibleItems,
    setVisibleItems,
  ] = useState(calculateVisibleItems());

  const wrapperRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    if (isMobile) {
      listRef.current.scroll((currentIndex * thumbnailWidth) - thumbnailWidth / 2, 0);
    } else {
      wrapperRef.current.scroll(0, sessionStorage.getItem('sidebarScroll'));
    }
  }, [
    currentIndex,
    isMobile,
    listRef,
  ]);

  return (
    <Container
      hasTags={hasTags}
      navigationVisibility={navigationVisibility}
    >
      <SidebarHeader
        cta={cta}
        driftId={driftId}
        filterTags={filterTags}
        isChatActive={isChatActive}
        hasTags={hasTags}
        items={allItems}
        onClick={() => setNavigationVisibility(!navigationVisibility)}
        selectedTag={selectedTag}
        setChatActive={setChatActive}
        navigationVisibility={navigationVisibility}
        landingLink={landingLink}
      />
      <Wrapper
        id="navigation-wrapper"
        navigationVisibility={navigationVisibility}
        ref={wrapperRef}
      >
        <List ref={listRef}>
          {content.slice(0, visibleItems).map((item) => {
            const data = {
              ...item,
            };

            return (
              <NavItem
                key={data.innerSlug}
                content={data}
                currentIndex={currentIndex}
                filterTags={filterTags}
                hasTags={hasTags}
                selectedTag={selectedTag}
                showMore={showMore}
                slug={slug}
                wrapperRef={wrapperRef}
              />
            );
          })}
        </List>
      </Wrapper>
      {content.length > visibleItems ? (
        <Button
          onClick={() => {
            setVisibleItems(content.length);
            setShowMore(!showMore);
            const searchParams = typeof window !== 'undefined' ? window.location.search : '';
            const urlParams = new URLSearchParams(searchParams);

            urlParams.append('more', true);

            if (typeof window !== 'undefined') {
              window.history.replaceState(null, null, `${window.location.pathname}?${urlParams}`);
            }
          }}
          text="See More"
        />
      ) : null}
    </Container>
  );
};

Sidebar.propTypes = {
  allItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  cta: PropTypes.string.isRequired,
  currentIndex: PropTypes.number.isRequired,
  driftId: PropTypes.number.isRequired,
  filterTags: PropTypes.func.isRequired,
  hasTags: PropTypes.bool,
  isChatActive: PropTypes.bool.isRequired,
  navigationVisibility: PropTypes.bool.isRequired,
  selectedTag: PropTypes.string,
  setChatActive: PropTypes.func.isRequired,
  setNavigationVisibility: PropTypes.func.isRequired,
  setShowMore: PropTypes.func.isRequired,
  showMore: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
  hasTags: true,
  selectedTag: null,
  showMore: false,
};

export default Sidebar;
