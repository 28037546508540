import { createGlobalStyle } from 'styled-components';

const PdfAnnotations = createGlobalStyle`
.annotationLayer .linkAnnotation > a:hover, .annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
  opacity: 0;
  box-shadow: none;
  background: none;
}
`;

export default PdfAnnotations;
