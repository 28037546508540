import styled, { css } from 'styled-components';
import {
  above,
  below,
} from '@utils/media-queries';
import { colors } from '@utils/vars';

const {
  highlight,
  white,
} = colors;

export const OverlaySubMenuContainer = styled.div`

  ${below.desktopXl`
    margin-left: 40px;
  `}

  ${({ isExpanded }) => isExpanded && css`
    ${below.large`
      flex: 0 0 80%;
      height: 100%;
      margin-left: 0;
      padding-top: 70px;
    `}
  `}
`;

export const Item = styled.div`
  display: flex;

  ${below.large`
    height: 100%;
  `};
`;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 50%;

  ${below.large`
    overflow: auto;
    justify-content: flex-start;
    max-height: 100%;
    height: 100%;
  `};
`;

export const BackButton = styled.button`
  margin-bottom: 80px;
  border: none;
  background: none;
  cursor: pointer;

  ${below.desktopXl`
    margin-bottom: 40px;
  `}

  img {
    width: 16px;
    height: 22px;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;

  ${below.desktopXl`
    font-size: 20px;
    line-height: 32px;
  `}
`;

export const Categories = styled.div`
  background: transparent;
  margin-bottom: ${({ reduceMargin }) => (reduceMargin ? '0' : '40px')};
`;

export const ColumnWithThumb = styled.div`
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  align-items: center;
  margin-left: 165px;

  ${below.desktopXl`
    flex-basis: 50%;
    margin-left: 95px;
  `}

  ${below.desktop`
    flex-basis: 50%;
    margin-left: 40px;
  `}

  ${below.large`
    display: none;
  `}
`;

export const Label = styled.div`
  margin-bottom: 39px;
  color: rgba(255, 255, 255, 0.2);
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;

  ${below.desktopXl`
    margin-bottom: 16px;
    font-size: 14px;
  `}
`;

export const ListItem = styled.li`
  list-style: ${({ withMarker }) => (withMarker ? 'disc' : 'none')};
  margin-top: 24px;
  color: rgba(255, 255, 255, 0.5);

  ${below.desktopXl`
    margin-top: 12px;
  `}

  &:first-child {
    margin-top: 0;
  }
`;

export const ListItemAnchor = styled.a`
  color: rgba(255, 255, 255, 0.4);
  font-size: 24px;
  font-family: 'Pontiac', sans-serif;
  line-height: 32px;
  transition: color 0.3s ease;

  ${below.desktopXl`
    font-size: 14px;
    line-height: 28px;
  `}

  &:hover {
    color: ${white};
  }
`;

export const ReadMoreButton = styled.a`
  margin-top: ${({ reduceMargin }) => (reduceMargin ? '0' : '40px')};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 195px;
  height: 64px;
  border: none;
  padding: 10px;
  background: ${highlight};
  color: ${white};
  font-weight: 700;
  font-family: 'Pontiac', sans-serif;
  line-height: 24px;
  white-space: nowrap;

  ${below.desktopXl`
    min-width: 175px;
    height: 48px;
    font-size: 16px;
  `};
`;

export const ReadMoreIconStyled = styled.span`
  margin-left: 16px;
`;

export const CustomScrollElement = styled.div`
  margin-right: 30px;
  max-height: 30vh;
  overflow-y: scroll;
  width: 100%;

  ${below.large`
    max-height: 100%;
  `}

  ${above.desktop`
    width: 100%
  `}

  scrollbar-color: ${highlight} transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background: ${highlight};
    width: 6px;
    margin-left: 12px;
  }

  &::-webkit-scrollbar-track {
    background: black;
  }

  &::-webkit-scrollbar-thumb {
    background: ${highlight};
    border-radius: 28px;
  }
`;
