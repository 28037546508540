import React from 'react';
import PropTypes from 'prop-types';
import {
  OverlayContentStyled,
  OverlayContentUlStyled,
} from './OverlayContent.styled';
import { OverlayList } from './OverlayList/OverlayList';
import { OverlaySubMenu } from './OverlaySubMenu/OverlaySubMenu';
import { MenuContext } from '../../MenuContext';

const listItems = [
  {
    dataSection: 'why-merix',
    label: 'Why merix',
    url: '/',
  },
  {
    dataSection: 'about-us',
    label: 'About us',
    url: '/',
  },
  {
    dataSection: 'services',
    label: 'Services',
    url: '/',
  },
  {
    dataSection: 'our-work',
    label: 'Our work',
    url: '/',
  },
  {
    dataSection: 'insights',
    label: 'Insights',
    small: true,
    url: '/',
  },
  {
    dataSection: 'careers',
    label: 'Careers',
    small: true,
    url: '/',
  },
  {
    dataSection: 'contact',
    label: 'Contact',
    small: true,
    url: '/',
  },
];

export const OverlayContent = ({
  isOpened,
  activeElement,
  closeMenu,
}) => (
  <MenuContext.Consumer>
    {({
      isExpanded,
      isVisible,
    }) => (
      <OverlayContentStyled
        isExpanded={isExpanded}
        isVisible={isVisible}
      >
        <OverlayContentUlStyled isExpanded={isExpanded}>
          <OverlayList
            activeElement={activeElement}
            isOpened={isOpened}
            listItems={listItems}
          />
        </OverlayContentUlStyled>
        <OverlaySubMenu
          activeElement={activeElement}
          closeMenu={closeMenu}
          isExpanded={isExpanded}
        />
      </OverlayContentStyled>
    )}
  </MenuContext.Consumer>
);
OverlayContent.propTypes = {
  activeElement: PropTypes.string,
  closeMenu: PropTypes.func.isRequired,
  isOpened: PropTypes.func.isRequired,
};

OverlayContent.defaultProps = {
  activeElement: '',
};
