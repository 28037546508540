import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PdfEmbed from '@components/PdfEmbed/PdfEmbed';
import VideoPlayer from '@components/VideoPlayer/VideoPlayer';
import Button from '@components/Button/Button';
import mediaQueries from '@utils/media-queries';
import IframeResizer from 'iframe-resizer-react';
import { GalleryInstance } from '@utils/gallery';
import {
  downloadFile,
  pushGTMEvent,
} from '@utils/helpers';
import { Helmet } from 'react-helmet';

const {
  large,
} = mediaQueries;

const Wrapper = styled.main`
  width: 100%;

  @media ${large} {
    margin-bottom: 40px;
  }
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  height: auto;

  @media ${large} {
    height: calc(100vh - 243px)
  }

  @media (max-width: 815px) {
    height: calc(100vh - 171px)
  }
`;

const StyledIframeResizer = styled(IframeResizer)`
  width: 1px;
  min-width: 100%;
  height: 900px;
`;

const handleVideoData = (video) => {
  const {
    desc,
    dur,
    fileUrl,
    thumb,
    title,
    upDate,
  } = video;
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    contentUrl: fileUrl,
    description: desc,
    duration: dur,
    name: title,
    thumbnailUrl: [thumb],
    uploadDate: upDate,
  };

  return JSON.stringify(ldJson);
};

const InsightsContent = ({
  data,
  hasTags,
}) => {
  const {
    src,
    title,
    type,
    video,
  } = data;

  const onDownloadPdfButtonClick = useCallback(() => {
    pushGTMEvent({
      action: 'Download pdf',
      category: 'PDF file',
      event: 'pdf',
      label: title,
    });

    downloadFile({
      src,
      title,
    });
  }, [
    title,
    src,
  ]);

  switch (type) {
  case 'link':
  case 'url':
  case 'blog': {
    const srcForIframe = src.includes('?') ?
        `${src}&iframe-embedded=1` :
        `${src}?iframe-embedded=1`;

    const onIframeLoad = ({ currentTarget: iframe }) => {
      const iframeDocument = iframe.contentDocument;

      if (iframeDocument) {
        GalleryInstance.registerIframeImagesEvent(iframeDocument);
      }
    };

    return (
      <Wrapper
        hasTags={hasTags}
      >
        <StyledIframeResizer
          onLoad={onIframeLoad}
          src={srcForIframe}
          scrolling="yes"
          data-hj-allow-iframe
          inPageLinks
        />
      </Wrapper>
    );
  }

  case 'pdf':
    return (
      <Wrapper hasTags={hasTags}>
        <Button
          onClick={onDownloadPdfButtonClick}
          type="download"
          text="Download PDF"
        />
        <PdfEmbed
          url={src}
        />
      </Wrapper>
    );

  case 'video':
    return (
      <Wrapper hasTags={hasTags}>
        <Helmet>
          <script type="application/ld+json">
            {handleVideoData(video)}
          </script>
        </Helmet>
        <StyledVideoPlayer
          url={src}
          title={title}
        >
          Your browser doesn&apos;t support HTML video
        </StyledVideoPlayer>
      </Wrapper>
    );

  default:
    return (
      <Wrapper hasTags={hasTags}>
        it&apos;s something else
      </Wrapper>
    );
  }
};

InsightsContent.propTypes = {
  data: PropTypes.shape({
    src: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  hasTags: PropTypes.bool,
};

InsightsContent.defaultProps = {
  hasTags: true,
};

export default InsightsContent;
