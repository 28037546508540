import React from 'react';
import PropTypes from 'prop-types';
import FadeIn from 'react-fade-in';
import HexImage from '@assets/images/hex.svg';
import MapImage from '@assets/images/map.svg';
import { Icon } from '@components';
import { colors } from '@utils/vars';
import {
  BackButton,
  Item,
  Label,
  ReadMoreButton,
  ReadMoreIconStyled,
} from '../../OverlaySubMenu.styled';
import {
  ContactColumnContent,
  ContactContainer,
  ContactLink,
  ContactSection,
  SubTitle,
} from './Contact.styled';

const {
  highlight,
  white,
} = colors;

const contentTexts = [
  {
    email: 'press@merixstudio.com',
    subtitle: 'Thank you for your interest!',
    title: 'Press and media',
  },
  {
    email: 'jobs@merixstudio.com',
    subtitle: 'We\'re always looking for talented people!',
    title: 'Careers',
  },
  {
    email: 'contact@merixstudio.com',
    subtitle: 'Looking for tech partner ? Let\'s talk',
    title: 'Project inquiries',
  },
];

export const Contact = ({ closeMenu }) => (
  <ContactContainer>
    <Item>
      <ContactColumnContent>
        <FadeIn>
          <BackButton onClick={closeMenu}>
            <Icon
              color={highlight}
              name="MenuBackIcon"
            />
          </BackButton>
          {contentTexts.map((element) => (
            <ContactSection key={element.title}>
              <Label>{element.title}</Label>
              <SubTitle>{element.subtitle}</SubTitle>
              <ContactLink>{element.email}</ContactLink>
            </ContactSection>
          ))}
          <ReadMoreButton href="https://www.merixstudio.com/#get-an-estimate">
            Get an estimate
            <ReadMoreIconStyled>
              <Icon
                name="ReadMoreIcon"
                color={white}
              />
            </ReadMoreIconStyled>
          </ReadMoreButton>
        </FadeIn>
      </ContactColumnContent>

      <div className="overlay-submenu__column overlay-submenu__column--thumb map-image">
        <img
          src={MapImage}
          alt=""
          className="overlay-submenu__image overlay-submenu__animated-thumb"
        />
        <div className="map-image__pin">
          <div className="map-image__hex">
            <span className="map-image__hex-title">Merixstudio US</span>
            <span
              className="map-image__hex-text"
            >
              3537 36th Street Astoria, NY 11106 United States
            </span>
            <img
              src={HexImage}
              alt=""
            />
          </div>
        </div>
        <div className="map-image__pin">
          <div className="map-image__hex">
            <span className="map-image__hex-title">Merixstudio UK</span>
            <span className="map-image__hex-text">13 Harbury Rd Bristol UK BS9 4PN United Kingdom</span>
            <img
              src={HexImage}
              alt=""
            />
          </div>
        </div>
        <div className="map-image__pin">
          <div className="map-image__hex">
            <span className="map-image__hex-title">Merixstudio DE</span>
            <span className="map-image__hex-text">Schinekstraße 9 Berlin 12047 Germany</span>
            <img
              src={HexImage}
              alt=""
            />
          </div>
        </div>
        <div className="map-image__pin">
          <div className="map-image__hex">
            <span className="map-image__hex-title">Merixstudio HQ</span>
            <span className="map-image__hex-text">Małachowskiego 10 61-129 Poznań Poland</span>
            <img
              src={HexImage}
              alt=""
            />
          </div>
        </div>
        <div className="hex-cursor" />
      </div>
    </Item>
  </ContactContainer>
);

Contact.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};

