import PropTypes from 'prop-types';
import React, {
  useMemo, useState,
} from 'react';
import styled from 'styled-components';
import logoImage from '@assets/images/logo.svg';
import Button from '@components/Button/Button';
import mediaQueries from '@utils/media-queries';
import { colors } from '@utils/vars';
import { driftHandler } from '@utils/helpers';
import {
  FilterDropdown, LandingLink,
} from '@components';

const {
  small,
  medium,
  xlarge,
} = mediaQueries;

const { shadow: shadowColor } = colors;

const Container = styled.header`
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 5px 0 ${shadowColor};

  @media ${small} {
    width: 100%;
    box-shadow: 0 2px 11px 0 #161616;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 40px 50px;

  @media ${xlarge} {
    padding: 20px 25px;
  }

  @media ${medium} {
    padding: 10px;
  }

  @media ${small} {
    flex-flow: row nowrap;
    padding: 0;
  }
`;

const HomeLink = styled.a`
  display: block;
  margin: 0 0 35px;

  @media ${small} {
    display: none;
  }
`;

const Logo = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
`;

const SidebarHeader = ({
  cta,
  driftId,
  filterTags,
  hasTags,
  isChatActive,
  items,
  landingLink,
  navigationVisibility,
  onClick,
  selectedTag,
  setChatActive,
}) => {
  const [
    isChatLoading,
    setChatLoading,
  ] = useState(false);
  const computedButtonLabel = useMemo(() => (isChatLoading ? 'opening chat . . .' : cta), [
    cta,
    isChatLoading,
  ]);

  return (
    <Container>
      <Wrapper>
        <HomeLink href="https://merixstudio.com">
          <h1>
            <Logo src={logoImage} />
          </h1>
        </HomeLink>
        {landingLink !== '' ? (
          <LandingLink
            text="Back to grid view"
            landingUrl={`${landingLink}#filters`}
          />
        ) : (
          <Button
            type="cta"
            text={computedButtonLabel}
            isLoading={isChatLoading}
            onClick={driftHandler({
              driftId,
              isChatActive,
              setChatActive,
              setChatLoading,
            })}
          />
        )}
        <Button
          type="toggler"
          text="keep on viewing"
          navigationVisibility={navigationVisibility}
          onClick={onClick}
        />
      </Wrapper>
      {hasTags && (
      <FilterDropdown
        items={items}
        filterTags={filterTags}
        selectedTag={selectedTag}
      />
      )}
    </Container>
  );
};

SidebarHeader.propTypes = {
  cta: PropTypes.string.isRequired,
  driftId: PropTypes.number.isRequired,
  filterTags: PropTypes.func.isRequired,
  hasTags: PropTypes.bool.isRequired,
  isChatActive: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  landingLink: PropTypes.string.isRequired,
  navigationVisibility: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedTag: PropTypes.string,
  setChatActive: PropTypes.func.isRequired,
};

SidebarHeader.defaultProps = {
  selectedTag: null,
};

export default SidebarHeader;
