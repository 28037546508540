import React from 'react';
import PropTypes from 'prop-types';

import icons from '@assets/icons';

const Icon = ({
  color,
  name,
  ...rest
}) => {
  const IconElement = icons[name];

  if (!IconElement) {
    return null;
  }

  return (
    <IconElement
      style={{ color }}
      {...rest}
    />
  );
};

Icon.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  rest: PropTypes.objectOf(PropTypes.object),
};

Icon.defaultProps = {
  color: '',
  rest: {},
};

export default Icon;
