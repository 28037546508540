import styled from 'styled-components';

export const OverlaySocialsLabelStyled = styled.span`
  display: ${({ hideSocialLabel }) => (hideSocialLabel ? 'none' : 'block')};
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  font-size: 14px;
  transition: opacity 0.3s ease;
`;
