import PropTypes from 'prop-types';
import React from 'react';
import { OverlaySubMenuContainer } from './OverlaySubMenu.styled';

import { WhyMerix } from './OverlaySubMenuItems/WhyMerix/WhyMerix';
import { AboutUs } from './OverlaySubMenuItems/AboutUs/AboutUs';
import { Services } from './OverlaySubMenuItems/Services/Services';
import { OurWork } from './OverlaySubMenuItems/OurWork/OurWork';
import { Insights } from './OverlaySubMenuItems/Insights/Insights';
import { Careers } from './OverlaySubMenuItems/Careers/Careers';
import { Contact } from './OverlaySubMenuItems/Contact/Contact';

export const OverlaySubMenu = ({
  activeElement,
  closeMenu,
  isExpanded,
}) => {
  const renderSwitch = (selectedMenuElement) => {
    switch (selectedMenuElement) {
    case 'why-merix':
      return (
        <WhyMerix
          closeMenu={closeMenu}
          isExpanded={isExpanded}
        />
      );
    case 'about-us':
      return (
        <AboutUs
          closeMenu={closeMenu}
          isExpanded={isExpanded}
        />
      );
    case 'services':
      return (
        <Services
          closeMenu={closeMenu}
          isExpanded={isExpanded}
        />
      );
    case 'our-work':
      return (
        <OurWork
          closeMenu={closeMenu}
          isExpanded={isExpanded}
        />
      );
    case 'insights':
      return (
        <Insights
          closeMenu={closeMenu}
          isExpanded={isExpanded}
        />
      );
    case 'careers':
      return (
        <Careers
          closeMenu={closeMenu}
          isExpanded={isExpanded}
        />
      );
    case 'contact':
      return (
        <Contact
          closeMenu={closeMenu}
          isExpanded={isExpanded}
        />
      );
    default:
      return null;
    }
  };

  return (
    <OverlaySubMenuContainer isExpanded={isExpanded}>
      {renderSwitch(activeElement)}
    </OverlaySubMenuContainer>
  );
};

OverlaySubMenu.propTypes = {
  activeElement: PropTypes.string,
  closeMenu: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

OverlaySubMenu.defaultProps = {
  activeElement: '',
};
