import styled from 'styled-components';
import { Container as GridContainer } from 'react-awesome-styled-grid';
import { ButtonCube } from '@components';
import { colors } from '@utils/vars';
import LoadingIndicator from '@components/Insights/LoadingIndicator/LoadingIndicator';
import mediaQueries from '@utils/media-queries';

const {
  xxsmall, xsmall, medium,
} = mediaQueries;

export const Container = styled(GridContainer)`
  position: relative;
  margin-top: 20px;
`;

export const ResultsInfo = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 13px;
  font-size: 18px;
  line-height: 24px;
  white-space: nowrap;

  @media ${medium} {
    flex-wrap: wrap;
  }
`;

export const ResultsCount = styled.strong`
  display: inline-flex;
  margin-right: 6px;
  padding-top: 3px;
  font-weight: 600;
`;

export const InfoLabel = styled.span`
  margin-right: 13px;
  padding-top: 3px;
`;

export const FilterTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 30px;

  @media ${medium} {
    flex: 0 0 100%;
    width: 100%;
    margin-top: 20px;
  }
`;

export const SortCol = styled.div``;

export const SortLabel = styled.span`
  margin-right: 30px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  white-space: nowrap;
`;

export const InfoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;

  @media ${xsmall} {
    flex-direction: column-reverse;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  @media ${medium} {
    position: absolute;
    right: 0;
  }

  @media ${xsmall} {
    position: static;
    right: auto;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
`;

export const SelectContainer = styled.div`
  width: 200px;


  @media ${xsmall} {
    flex-grow: 1;
    width: 100%;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LoadMoreButton = styled(ButtonCube)`
  width: 400px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;

  @media ${xsmall} {
    font-size: 14px;
  }

  @media ${xxsmall} {
    font-size: 12px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActiveFilterTag = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.09);
  margin-bottom: 6px;
  margin-left: 6px;
  border-radius: 8px;
  padding: 3px 10px;
  background: #fff;
  color: #000;
  font-size: 14px;
  line-height: 18px;

  @media ${medium} {
    margin-right: 6px;
    margin-left: 0;
  }
`;

export const ClearFilterButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-left: 15px;
  outline: none;
  border: none;
  border-radius: 40px;
  background: transparent;
  font-size: 18px;
  line-height: 23px;
  transition: background-color 0.2s ease;
  cursor: pointer;

  :after {
    content: '×';
    display: flex;
    margin-top: -3px;
    color: ${colors.highlight};
    transition: color 0.2s ease;
  }

  :hover,
  :focus {
    background-color: ${colors.highlight};

    :after {
      color: #fff;
    }
  }

`;
export const InfoLoadingIndicator = styled(LoadingIndicator)`
  display: inline-flex;
  width: 14px;
  height: 14px;
  margin-top: 8px;
  margin-right: 6px;
`;

export const ContentLoadingIndicator = styled(LoadingIndicator)`
  width: 40px;
  height: 40px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

export const ErrorMessage = styled.p`
  margin: 0;
  font-size: 18px;
`;
