import styled from 'styled-components';
import HexagonCursor from '@assets/images/hexagon.svg';
import { below } from '@utils/media-queries';
import { ColumnContent } from '../../OverlaySubMenu.styled';

const setMapImagePosition = () => {
  if (document.querySelectorAll('.map-image').length >= 1) {
    const mapImage = document.getElementsByClassName('map-image')[0].parentElement;
    const resolutionCalculationFix = window.innerWidth <= 1400 ? 330 : 500;
    const mapImageRightOffset = Math.round(-1 * (window.innerWidth - mapImage.getBoundingClientRect().right) + resolutionCalculationFix);

    return `${mapImageRightOffset}px`;
  }
};

export const ContactContainer = styled.div`
  ${below.large`
    height: 100%;
  `}
.map-image {
  right: ${setMapImagePosition};
  position: absolute;
  cursor: url(${HexagonCursor}) 2 2, auto;
  ${below.large`
    max-width: 850px;
    display: none;
  `};
  &__text {
    z-index: 1;
    max-width: 290px;
  }
  &__pin {
    position: absolute;
    height: 6%;
    &:hover .map-image__hex {
      visibility: visible;
    }
    &:nth-of-type(1) {
      top: 29.5%;
      left: 38%;
      min-width: 6%;
    }
    &:nth-of-type(2) {
      top: 28.5%;
      left: 68.5%;
      min-width: 5%;
    }
    &:nth-of-type(3) {
      top: 26.5%;
      left: 74%;
      min-width: 5%;
    }
    &:nth-of-type(4) {
      top: 25.5%;
      left: 79.5%;
      min-width: 5%;
    }
  }
  &__hex {
    visibility: hidden;
    position: absolute;
    width: 220px;
    height: 190px;
    top: 10px;
    transform: translate(-50%, -100%);
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-visible {
      visibility: visible;
    }
    &-title {
      color: #FF4E00;
      font-family: 'Pontiac', serif;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      max-width: 107px;
      margin-bottom: 8px;
    }
    &-text {
      color: #FFFFFF;
      font-family: 'fira_sansregular', sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      max-width: 140px;
    }
    > img {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
`;

export const ContactColumnContent = styled(ColumnContent)`
  z-index: 1;
  max-width: 290px;
`;

export const SubTitle = styled.h3`
  margin-top: 20px;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;

  ${below.desktopXl`
    margin-top: 10px;
    font-size: 14px;
    line-height: 28px;
  `};
`;

export const ContactSection = styled.div`
  margin-top: 40px;

  ${below.desktopXl`
    margin-top: 20px;
  `};
`;

export const ContactLink = styled.a`
  cursor: pointer;
  display: inline-block;
  margin-top: 12px;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  font-family: 'fira_sansregular', sans-serif;
  line-height: 32px;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  ${below.desktopXl`
    font-size: 14px;
    line-height: 28px;
  `};
`;
