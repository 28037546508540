export const linkIcons = {
  blog: {
    margin: '2px',
    name: 'blog',
    size: '39px',
    title: 'read',
  },
  pdf: {
    margin: '6px',
    name: 'pdf-file',
    size: '35px',
    title: 'view',
  },
  url: {
    margin: '7px',
    name: 'url',
    size: '35px',
    title: 'browse',

  },
  video: {
    margin: '7px',
    name: 'play-circle',
    size: '56px',
    title: 'play',
  },
};
