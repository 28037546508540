import React from 'react';
import PropTypes from 'prop-types';
import { OverlayListItemIcon } from '../OverlayListItemIcon/OverlayListItemIcon';
import { OverlayListButtonSmallStyled } from './OverlayListButtonSmall.styled';
import { MenuContext } from '../../../../MenuContext';

export const OverlayListButtonSmall = ({
  label,
  isActive,
  isOpened,
  dataSection,
}) => (
  <MenuContext.Consumer>
    {({ isExpanded }) => (
      <OverlayListButtonSmallStyled
        isExpanded={isExpanded}
        data-section={dataSection}
        type="button"
        setActiveElement={isActive}
        className={`${isActive} small-icon`}
        onClick={() => isOpened(dataSection)}
      >
        <span>
          {label}
        </span>
        <OverlayListItemIcon />
      </OverlayListButtonSmallStyled>
    )}
  </MenuContext.Consumer>
);

OverlayListButtonSmall.propTypes = {
  dataSection: PropTypes.string.isRequired,
  isActive: PropTypes.string,
  isOpened: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

OverlayListButtonSmall.defaultProps = {
  isActive: '',
};
