import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { colors } from '@utils/vars';
import PdfAnnotations from '@utils/pdf-annotations';
import {
  Document,
  Page,
  pdfjs,
} from 'react-pdf';

const { small } = mediaQueries;

const { pdfBg: pdfBackground } = colors;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  background: ${pdfBackground};

  & canvas {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  @media ${small} {
    height: calc(100% - 40px)
  }
`;

const StyledPage = styled(Page)`
  padding: 10px 0;
`;

const PdfEmbed = ({
  url, className,
}) => {
  const [
    numPages,
    setNumPages,
  ] = useState(null);

  const onDocumentLoad = (document) => setNumPages(document.numPages);

  return (
    <Wrapper className={className}>
      <PdfAnnotations />
      <Document
        file={url}
        onLoadSuccess={onDocumentLoad}
      >
        {
          Array.from(
            new Array(numPages),
            (el, index) => (
              <StyledPage
                key={`page-${index}`}
                pageNumber={index + 1}
                width={typeof window !== 'undefined' && window.innerWidth < 800 ? window.innerWidth - 20 : 750}
              />
            )
          )
        }
      </Document>
    </Wrapper>
  );
};

PdfEmbed.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
};

PdfEmbed.defaultProps = {
  className: undefined,
};

export default PdfEmbed;
