import styled from 'styled-components';
import {
  colors,
} from '@utils/vars';

const {
  highlight: highlightColor,
} = colors;

const Wave = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-image: url("/wave.svg");
    background-position: bottom;
    background-size: contain;
    background-repeat: repeat-x;
    color: ${highlightColor};
  }
`;

export default Wave;
