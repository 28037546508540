import React from 'react';
import { Icon } from '@components';
import { OverlayEstimateIconStyled } from './OverlayEstimateIcon.styled';
import { MenuContext } from '../../../MenuContext';

export const OverlayEstimateIcon = () => (
  <MenuContext.Consumer>
    {({ isExpanded }) => (
      <OverlayEstimateIconStyled isExpanded={isExpanded}>
        <Icon name="ArrowRight" />
      </OverlayEstimateIconStyled>
    )}
  </MenuContext.Consumer>
);
