import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import {
  Col,
  Container,
  Row,
} from 'react-awesome-styled-grid';
import { navigate } from 'gatsby';
import ArrowLeft from '@assets/images/carousel-arrow-left.svg';
import ArrowRight from '@assets/images/carousel-arrow-right.svg';
import { breakpoints } from '@utils/media-queries';
import {
  Arrow,
  CarouselsListWrapper,
  CategoriesList,
  CategoryButton,
  ItemContainer,
  ItemContent,
  ItemContentImage,
  ItemDescription,
  ItemImage,
  ItemTag,
  ItemTags,
  ItemTitle,
  ReadMoreButton,
  SingleCarouselWrapper,
  Title,
  ViewAllButton,
} from './CollectionsCarousel.styled';

const CollectionsCarousel = ({
  collections, categories, title,
}) => {
  const [
    activeCategory,
    setActiveCategory,
  ] = useState(categories?.[0]);
  const sliderRef = useMemo(() => collections.map(() => null), [collections]);

  sliderRef.fill(useRef());
  const activeCollection = collections.find((item) => item.topic.name === activeCategory);

  const visibleSlides = 1;

  const handleViewAllClick = useCallback(() => {
    navigate(`/${activeCollection.slug}`);
  }, [activeCollection]);

  const handleCategoryButtonClick = useCallback((category) => () => {
    const viewportWidth = typeof window !== 'undefined' && document.documentElement.clientWidth;

    if (!viewportWidth || viewportWidth > breakpoints.small) {
      setActiveCategory(category);
    } else {
      const collection = collections.find((item) => item.topic.name === category);

      navigate(`/${collection.slug}`);
    }
  }, [collections]);

  const renderItem = useMemo(() => (data, collection) => (
    <ItemContainer>
      <ItemImage>
        <img
          src={data.image}
          alt={data.title}
        />
      </ItemImage>

      <ItemContent>
        <ItemContentImage>
          <img
            src={data.image}
            alt={data.title}
          />
        </ItemContentImage>
        <ItemTitle>{data.title}</ItemTitle>
        <ItemTags>
          {data.subtopics.map((item) => (
            item.name && (
              <ItemTag
                key={item.name}
              >
                {item.name}
              </ItemTag>
            )
          ))}
          {data.industry.map((item) => (
            item.name && (
              <ItemTag
                key={item.name}
              >
                {item.name}
              </ItemTag>
            )
          ))}
          {data.technology.map((item) => (
            item.name && (
              <ItemTag
                key={item.name}
              >
                {item.name}
              </ItemTag>
            )
          ))}
        </ItemTags>
        <ItemDescription>{data.meta_description}</ItemDescription>
        <ReadMoreButton to={`${data.url}/?topic=${encodeURIComponent(collection.topic.name)}`}>
          read more
        </ReadMoreButton>
      </ItemContent>
    </ItemContainer>
  ), []);

  return (
    <Container>
      <Row>
        <Col>

          <Row>
            <Col>
              <Title>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <CategoriesList>
                {categories.map((item) => (
                  <CategoryButton
                    key={item}
                    isActive={activeCategory === item}
                    onClick={handleCategoryButtonClick(item)}
                  >
                    {item.toLowerCase()}
                  </CategoryButton>
                ))}
              </CategoriesList>
            </Col>
          </Row>
          <Row>
            <Col>
              <CarouselsListWrapper>
                {collections.map(({
                  topic, content_elements: items,
                }, index) => (
                  <SingleCarouselWrapper
                    isActive={activeCategory === topic.name}
                    key={topic.name}
                  >
                    <CarouselProvider
                      naturalSlideHeight={100}
                      naturalSlideWidth={100}
                      style={{ display: 'flex' }}
                      totalSlides={items.length}
                      visibleSlides={visibleSlides}
                    >
                      {items.length > 0 && (
                        <ButtonBack className="carousel-button carousel-button--prev">
                          <Arrow
                            src={ArrowLeft}
                            direction="left"
                          />
                        </ButtonBack>
                      )}
                      <Slider
                        style={{
                          flex: '1',
                        }}
                        ref={sliderRef[index]}
                      >
                        {items.map((item, i) => (
                          <Slide
                            className="slide--collections"
                            index={i}
                            innerClassName="slide__inner--collections"
                            key={item.slug}
                          >
                            {renderItem(item, activeCollection)}
                          </Slide>
                        ))}
                      </Slider>
                      {items.length > 0 && (
                        <ButtonNext className="carousel-button carousel-button--next">
                          <Arrow
                            src={ArrowRight}
                            direction="right"
                          />
                        </ButtonNext>
                      )}
                    </CarouselProvider>
                  </SingleCarouselWrapper>
                ))}
              </CarouselsListWrapper>

              <ViewAllButton onClick={handleViewAllClick}>
                View all
                {' '}
                {activeCategory.toLowerCase()}
                {' '}
                insights
              </ViewAllButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

CollectionsCarousel.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  collections: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
};

CollectionsCarousel.defaultProps = {
  categories: [],
  collections: [],
  title: 'Content <strong>Collections</strong>',
};

export default CollectionsCarousel;
