import React from 'react';
import {
  StyledCloseButton,
  StyledCloseButtonIcon,
} from './CloseIcon.styled';

export const CloseIcon = () => (
  <StyledCloseButton
    className="overlay-menu__close-button"
  >
    Close
    <StyledCloseButtonIcon className="overlay-menu__close-button-icon">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.444 0 18 1.556 10.555 9 18 16.444 16.444 18 9 10.555 1.556 18 0 16.444 7.444 9 0 1.556 1.556 0 9 7.444 16.444 0Z"
          fill="#FFF"
          fillRule="evenodd"
        />
      </svg>
    </StyledCloseButtonIcon>
  </StyledCloseButton>
);
