import Clutch from './clutch.svg';
import Facebook from './facebook.svg';
import Twitter from './twitter.svg';
import Linkedin from './linkedin.svg';
import Instagram from './instagram.svg';
import Dribble from './dribbble.svg';
import Behance from './behance.svg';
import ArrowRight from './arrow-right.svg';
import MenuBackIcon from './menu-back-icon.svg';
import ReadMoreIcon from './read-more-icon.svg';
import PlusIcon from './plus-icon.svg';
import MerixMenuLogo from './merix-logo-menu.svg';

export default {
  ArrowRight,
  Behance,
  Clutch,
  Dribble,
  Facebook,
  Instagram,
  Linkedin,
  MenuBackIcon,
  MerixMenuLogo,
  PlusIcon,
  ReadMoreIcon,
  Twitter,
};
