import React from 'react';
import { Icon } from '@components';
import { colors } from '@utils/vars';
import { OverlayHeaderStyled } from './OverlayHeader.styled';

const { white } = colors;

export const OverlayHeader = () => (
  <OverlayHeaderStyled>
    <a href="/">
      <Icon
        color={white}
        name="MerixMenuLogo"
      />
    </a>
  </OverlayHeaderStyled>
);
