import qs from 'query-string';
import React, {
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'react-awesome-styled-grid';
import useInsightsPosts, {
  DEFAULT_SORT_OPTION,
  SORT_MOST_POPULAR,
  SORT_NEWEST,
} from '@services/useInsightsPosts';
import InsightsSelect from '@components/Insights/InsightsSelect/InsightsSelect';
import SinglePost from './SinglePost/SinglePost';
import ListProgress from './ListProgress/ListProgress';
import {
  ActiveFilterTag,
  ClearFilterButton,
  Container,
  ContentLoadingIndicator,
  ErrorContainer,
  ErrorMessage,
  FilterTagsContainer,
  InfoContainer,
  InfoLabel,
  InfoLoadingIndicator,
  ItemsContainer,
  LoadingContainer,
  LoadMoreButton,
  ResultsCount,
  ResultsInfo,
  SelectContainer,
  SortContainer,
  SortLabel,
} from './InsightsPosts.styled';

const sortOptions = [
  {
    label: 'Most recent',
    value: SORT_NEWEST,
  },
  {
    label: 'Most popular',
    value: SORT_MOST_POPULAR,
  },
];

const InsightsPosts = ({
  contentTypes,
  industries,
  technologies,
  themes,
  topic,
  persona,
  onFilterClearClick,
  onFilterChange,
  onSortChange,
  selectedSort,
  pagePath,
}) => {
  const pageSize = 9;

  const {
    filters, filtersQueryString,
  } = useMemo(() => {
    const newFilters = {
      industry: industries,
      ordering: selectedSort,
      persona,
      technology: technologies,
      theme: themes,
      topic,
      type: contentTypes,
    };

    const newFiltersQueryString = qs.stringify({
      ...newFilters,
      ordering: newFilters.ordering === DEFAULT_SORT_OPTION ? '' : newFilters.ordering,
    }, {
      skipEmptyString: true,
      skipNull: true,
    });

    return {
      filters: newFilters,
      filtersQueryString: newFiltersQueryString,
    };
  }, [
    contentTypes,
    industries,
    persona,
    technologies,
    themes,
    topic,
    selectedSort,
  ]);

  const hasFilters = !!(industries || technologies || themes || contentTypes);

  const [
    postsState,
    fetchData,
  ] = useInsightsPosts(filters, { pageSize });
  const isFirstRender = useRef(true);

  const handleSortChange = (option) => {
    onSortChange('ordering', option);
  };

  const handleTagClicked = (filterName, value) => {
    onFilterChange(filterName, { value });
  };

  const clearFilter = (filterName) => () => {
    onFilterClearClick(filterName);
  };

  const onLoadMore = () => fetchData();

  useEffect(() => {
    if (!isFirstRender.current) {
      fetchData({
        isRefetch: true,
        params: filters,
      });
    } else {
      isFirstRender.current = false;
    }
  }, [filters]);

  return (
    <Container>
      <Row>
        <Col
          xl={10}
          lg={12}
          offset={{
            lg: 0,
            xl: 1,
          }}
        >
          <InfoContainer>
            <ResultsInfo>
              {(postsState.isFetching && postsState.allItemsCount === 0) ?
                <InfoLoadingIndicator /> :
                <ResultsCount>{postsState.allItemsCount}</ResultsCount>}
              <InfoLabel>
                {hasFilters ? 'Insights on:' : 'Insights'}
              </InfoLabel>
              <FilterTagsContainer>
                {filters.theme && (
                  <ActiveFilterTag>
                    {filters.theme}
                    <ClearFilterButton onClick={clearFilter('theme')} />
                  </ActiveFilterTag>
                )}
                {filters.technology && (
                  <ActiveFilterTag>
                    {filters.technology}
                    <ClearFilterButton onClick={clearFilter('technology')} />
                  </ActiveFilterTag>
                )}
                {filters.industry && (
                  <ActiveFilterTag>
                    {filters.industry}
                    <ClearFilterButton onClick={clearFilter('industry')} />
                  </ActiveFilterTag>
                )}
                {filters.type && (
                  <ActiveFilterTag>
                    {filters.type}
                    <ClearFilterButton onClick={clearFilter('type')} />
                  </ActiveFilterTag>
                )}
              </FilterTagsContainer>
            </ResultsInfo>
            <SortContainer>
              <SortLabel>Sort by:</SortLabel>
              <SelectContainer>
                <InsightsSelect
                  placeholder=""
                  options={sortOptions}
                  selectedOption={selectedSort}
                  handleChange={handleSortChange}
                  isClearable={false}
                />
              </SelectContainer>
            </SortContainer>
          </InfoContainer>
          <ItemsContainer>
            {postsState.data.map((data) => (
              <SinglePost
                activeFilters={filters}
                filtersQueryString={filtersQueryString}
                key={data.slug}
                data={data}
                pagePath={pagePath}
                onTagClick={handleTagClicked}
              />
            ))}
          </ItemsContainer>
          {postsState.isFetching && (
            <LoadingContainer>
              <ContentLoadingIndicator />
            </LoadingContainer>
          )}
          {postsState.error && (
            <ErrorContainer>
              <ErrorMessage>Unknown error occured. Please try again.</ErrorMessage>
            </ErrorContainer>
          )}
          {!postsState.error && (
            <ListProgress
              allItemsCount={postsState.allItemsCount}
              itemsCount={postsState.data.length}
            />
          )}
          {postsState.hasNextPage && (
            <LoadMoreButton
              onClick={onLoadMore}
              disabled={postsState.isFetching}
            >
              Load more
            </LoadMoreButton>
          )}
        </Col>
      </Row>
    </Container>
  );
};

InsightsPosts.propTypes = {
  contentTypes: PropTypes.string,
  industries: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  onFilterClearClick: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  pagePath: PropTypes.string.isRequired,
  persona: PropTypes.string,
  selectedSort: PropTypes.string,
  technologies: PropTypes.string,
  themes: PropTypes.string,
  topic: PropTypes.string,
};

InsightsPosts.defaultProps = {
  contentTypes: undefined,
  industries: undefined,
  persona: undefined,
  selectedSort: DEFAULT_SORT_OPTION,
  technologies: undefined,
  themes: undefined,
  topic: undefined,
};

export default InsightsPosts;
