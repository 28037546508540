import styled, { css } from 'styled-components';
import { colors } from '@utils/vars';
import { below } from '@utils/media-queries';

const {
  black,
  highlight,
  white,
} = colors;

export const OverlayMenuStyled = styled.div`
  position: fixed;
  top: 0;
  z-index: 105;
  clip: rect(0, 100vw, 100vh, 0);
  width: 100vw;
  height: 100vh;
  padding: 50px 72px 72px;
  background: ${highlight};
  color: ${white};
  font-family: 'Pontiac', sans-serif;
  transition: background 0.2s ease, clip 0.2s ease;
  transition-delay: 0s, 0.1s;

  ${({ visible }) => visible && css`
    clip: rect(0, 100vw, 100vh, 0);
    ${below.desktop`
      transition-delay: 0s, 0s;
      height: 100%;
    `};
    ${below.large`
      padding: 16px 32px 32px;
      transition: background 0.2s ease, clip 0.2s ease;
      transition-delay: 0s, 0.1s;
    `};
`};

  ${({ visible }) => !visible && css`
    clip: rect(40px,97.5vw,40px,90.9vw);
  `};

  ${({ isExpanded }) => isExpanded && css`
    background-color: ${black};
  `};
`;
