import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { below } from '@utils/media-queries';
import { OverlayContact } from './OverlayContact/OverlayContact';
import { OverlayEstimateButton } from './OverlayEstimateButton/OverlayEstimateButton';
import { OverlayMenuStyled } from './OverlayMenu.styled';
import { OverlayHeader } from './OverlayHeader/OverlayHeader';
import { OverlayContent } from './OverlayContent/OverlayContent';
import { OverlaySocials } from './OverlaySocials/OverlaySocials';

const OverlayMenuInnerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    ${below.small`
      height: calc(100% - 64px);
    `}
`;

export const OverlayMenu = ({
  activeElement,
  chatId,
  isExpanded,
  isOpened,
  closeExpand,
  visible,
}) => {
  const hideElements = !!isExpanded;

  return (
    <OverlayMenuStyled
      visible={visible}
      isExpanded={isExpanded}
    >
      <OverlayMenuInnerStyled>
        <OverlayHeader />
        <OverlayContent
          activeElement={activeElement}
          isOpened={isOpened}
          closeMenu={closeExpand}
        />
        <OverlayContact hideContacts={hideElements} />
        <OverlaySocials hideSocialLabel={hideElements} />
        <OverlayEstimateButton chatId={chatId} />
      </OverlayMenuInnerStyled>
    </OverlayMenuStyled>
  );
};

OverlayMenu.propTypes = {
  activeElement: PropTypes.string,
  chatId: PropTypes.number,
  closeExpand: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isOpened: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
OverlayMenu.defaultProps = {
  activeElement: '',
  chatId: undefined,
};
