// This fixes mismatch in HTML attributes when generating dynamic pages
// see https://github.com/gatsbyjs/gatsby/discussions/17914

import {
  useEffect,
  useState,
} from 'react';

export default () => {
  const [
    isClient,
    setIsClient,
  ] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
};
