import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect, { components } from 'react-select';
import { colors } from '@utils/vars';
import mediaQueries from '@utils/media-queries';

const { xlarge } = mediaQueries;
const { highlight } = colors;

const valueStyles = {
  color: '#000000',
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'calc(100% - 5px)',
};
const customStyles = {
  container: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '10px',
    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.09)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    alignItems: 'center',
    display: 'flex',
    padding: '0 9px',
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '0',
    boxShadow: '0 2px 5px 0 rgba(184,184,184,0.5)',
    marginBottom: '0',
    marginTop: '0',
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingBottom: '8px',
    paddingTop: '8px',
  }),
  option: (provided, {
    isSelected, isDisabled,
  }) => ({
    ...provided,
    '&:hover': {
      background: isDisabled ? '#fff' : 'rgba(255, 78, 0, 0.06)',
      color: isDisabled ? 'rgba(0,0,0,0.87)' : highlight,
    },
    background: isSelected ? 'rgba(255, 78, 0, 0.06)' : '#fff',
    color: isSelected ? highlight : 'rgba(0,0,0,0.87)',
  }),
  placeholder: (provided) => ({
    ...provided,
    ...valueStyles,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...valueStyles,
  }),
};

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  ${({ direction }) => (direction === 'up' ? 'border-bottom' : 'border-top')}: 8px solid #f00;
`;

const classNamePrefix = 'insights-select';

const StyledReactSelect = styled(ReactSelect)`
  && .${classNamePrefix}__control {
    min-height: 53px;
  }

  && .${classNamePrefix}__value-container {
    padding: 10px 20px;
  }

  && .${classNamePrefix}__option {
    padding: 10px 20px;
  }

  && .${classNamePrefix}__option--is-disabled {
    color: rgba(0, 0, 0, .5);
  }

  @media ${xlarge} {
    && .${classNamePrefix}__control {
      min-height: 43px;
    }

    && .${classNamePrefix}__value-container {
      padding: 6px 12px;
    }

    && .${classNamePrefix}__option {
      padding: 10px 12px;
    }
  }
`;

const DropdownIndicator = (props) => {
  const { selectProps: { menuIsOpen } } = props;

  return (
    <components.DropdownIndicator {...props}>
      <Arrow direction={menuIsOpen ? 'up' : 'down'} />
    </components.DropdownIndicator>
  );
};

const parseOptions = (allOptions, availableOptions) => {
  const initialState = {
    active: [],
    disabled: [],
  };

  const optionsByStatus = allOptions.reduce((accum, option) => {
    const opt = {
      ...option,
      isDisabled: (availableOptions && !availableOptions.includes(option.value)) || false,
    };

    if (opt.isDisabled) {
      accum.disabled.push(opt);
    } else {
      accum.active.push(opt);
    }

    return accum;
  }, initialState);

  return [
    ...optionsByStatus.active,
    ...optionsByStatus.disabled,
  ];
};

const InsightsSelect = ({
  placeholder,
  handleChange,
  isClearable,
  options,
  selectedOption,
  availableOptions,
}) => {
  const value = selectedOption && options.find((item) => item.value === selectedOption);

  const parsedOptions = useMemo(() => parseOptions(options, availableOptions), [
    options,
    availableOptions,
  ]);

  return (
    <StyledReactSelect
      components={{
        DropdownIndicator,
      }}
      onChange={handleChange}
      options={parsedOptions}
      placeholder={placeholder}
      styles={customStyles}
      value={value || (selectedOption &&
        {
          label: selectedOption,
          value: selectedOption,
        })}
      isClearable={isClearable}
      classNamePrefix={classNamePrefix}
    />
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }).isRequired,
};

InsightsSelect.propTypes = {
  availableOptions: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedOption: PropTypes.string,
};

InsightsSelect.defaultProps = {
  availableOptions: undefined,
  isClearable: true,
  selectedOption: null,
};

export default InsightsSelect;
