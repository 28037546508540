import styled from 'styled-components';
import { Link } from 'gatsby';
import ButtonCube, { Label } from '@components/ButtonCube/ButtonCube';
import InsightsTag from '@components/Insights/InsightsTag/InsightsTag';
import {
  colors,
} from '@utils/vars';
import mediaQueries from '@utils/media-queries';

const { highlight } = colors;
const {
  xxsmall, xsmall, small, medium, large, xlarge,
} = mediaQueries;

export const Title = styled.h3`
  flex: 1 1 auto;
  color: #fff;
  font-size: 50px;
  line-height: 80px;
  text-align: center;

  strong {
    font-weight: bold;
  }

  @media ${large} {
    font-size: 40px;
    line-height: 1.4;
  }
`;

export const CategoriesList = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  @media ${small} {
     flex-direction: column;
  }
`;

export const CategoryButton = styled.button`
  position: relative;
  margin-left: 100px;
  border: 1px solid transparent;
  padding: 18px 6px;
  background: transparent;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
    color: red;
  }

  &:after {
    content: '';
    position: absolute;
    right: 6px;
    bottom: ${({ isActive }) => (isActive ? '-4px' : '4px')};
    left: 6px;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    height: 4px;
    background-image: url("/wave.svg");
    background-position: bottom;
    background-size: contain;
    background-repeat: repeat-x;
    transition: all 0.3s ease;
  }

  @media ${xlarge} {
    font-size: 16px;
  }

  @media ${large} {
    margin-left: 40px;
  }

  @media ${medium} {
    margin-left: 20px;
  }

  @media ${small} {
    margin-left: 0;

    &:after {
      display: none;
    }
  }
`;

export const Arrow = styled.img``;

export const SingleCarouselWrapper = styled.div`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const CarouselsListWrapper = styled.section`
  margin-top: 100px;
  margin-bottom: 60px;

  .slide__inner--collections {
    position: unset;
  }

  .slide--collections {
    height: unset;
    padding-bottom: unset !important;

      .slide__inner--collections {
        padding-right: 5px;
        padding-left: 5px;
      }
  }

  .carousel__slider-tray-wrapper,
  .carousel__slider-tray {
    height: inherit;
  }

  .carousel-button {
    align-self: center;
    width: 41px;
    border: none;
    padding: 5px;
    background: transparent;
    transition: transform 0.3s ease;

    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      outline: none;
      transform: scale(1.1);
    }
  }

  .carousel-button--prev {
    margin-right: 10px;

    &:active {
      transform: scale(1.1) translateX(-7px);
    }
  }

  .carousel-button--next {
    margin-left: 10px;


    &:active {
      transform:  scale(1.1) translateX(7px);
    }
  }

  @media ${xlarge} {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  @media ${small} {
    display: none;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemImage = styled.div`
  flex: 0 0 48.5%;

  img {
    border: 2px solid ${highlight};
  }

  @media ${large} {
    flex-basis: 30%;
  }

  @media ${small} {
    display: none;
  }
`;
export const ItemContent = styled.div`
  display: flex;
  flex: 0 0 48.5%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${large} {
    flex-basis: 67%;
  }

  @media ${small} {
    display: block;
    flex: 1 1 100%;
  }
`;

export const ItemContentImage = styled.div`
  float: right;
  display: none;
  max-width: 30%;
  padding-bottom: 2px;
  padding-left: 10px;

  img {
    border: 2px solid ${highlight};
  }

  @media ${small} {
    display: block;
  }

  @media ${xsmall} {
    display: none;
  }
`;

export const ItemTitle = styled.h4`
  margin-bottom: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 26px;
  line-height: 40px;

  @media ${xlarge} {
    font-size: 20px;
    line-height: 32px;
  }

  @media ${large} {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const ItemDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 40px;
  color: ${colors.lightGray};
  font-size: 18px;
  line-height: 27px;

  @media ${xlarge} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${large} {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ReadMoreButton = styled(Link)`
  position: relative;
  display: inline-block;
  padding-right: 34px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  transition: all 0.3s ease;

  &:hover,
  &:focus,
  &:active {
    color: ${highlight};
  }

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    right: 0;
    display: block;
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 9px solid ${highlight};
    transition: all 0.3s ease;
  }

  &:active:after {
    right: -3px;
  }

  @media ${medium} {
    padding-right: 18px;
    font-size: 16px;

    &:after {
      top: 6px;
      border-top-width: 7px;
      border-bottom-width: 7px;
      border-left-width: 7px;
    }
  }

`;

export const ViewAllButton = styled(ButtonCube)`
  align-self: center;
  width: fit-content;
  font-size: 18px;

  ${Label} {
    width: fit-content;
  }

  @media ${xsmall} {
    font-size: 14px;
  }

  @media ${xxsmall} {
    font-size: 12px;
  }

  @media ${small} {
    display: none;
  }
`;

export const ItemTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -10px 0 0;

  @media ${small} {
    display: none;
  }
`;

export const ItemTag = styled(InsightsTag).attrs({
  forwardedAs: 'span',
})`
  background-color: transparent;
  color: #fff;
`;
