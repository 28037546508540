import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  Col,
  Container,
  Hidden,
  Row,
} from 'react-awesome-styled-grid';
import { colors } from '@utils/vars';
import mediaQueries from '@utils/media-queries';
import logoImage from '@assets/images/merix-logo-small.svg';

const {
  small, large, xsmall, xlarge,
} = mediaQueries;

const Logo = styled.img`
  width: auto;
  max-width: 181px;
  height: auto;
  max-height: 100%;
`;

const HomeLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 122px;

  @media ${xlarge} {
     height: 100px;
  }

  @media (max-width: 815px) {
    height: 50px;
  }
`;

const HomeLink = styled(Link)`
  display: flex;
  align-items: center;
  height: calc(100% - 10px);
  padding-left: 12px;

  @media ${small} {
    height: calc(100% - 19px);
    border-right: none;
    padding-left: 0;
  }
`;

const HomeLinkText = styled.span`
  margin-left: 22px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;

  @media ${small} {
    margin-left: 10px;
    font-size: 14px;
  }
`;

const MenuLink = styled.a`
  display: inline-block;
  margin: 0 30px;
  color: #919191;
  font-size: 14px;
  cursor: pointer;

  @media ${large} {
    margin: 0 15px;
  }
`;

const FollowText = styled.span`
  color: #919191;
  font-size: 14px;
`;

const SocialLink = styled.a`
  margin-top: 5px;
  margin-left: 39px;

  @media ${large} {
    margin-left: 10px;
  }
`;

const SocialIcon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
`;

const MenuLinksRow = styled(Row)`
  @media ${xsmall} {
    display: none;
  }
`;

const Subtitle = styled.span`
  position: relative;
  margin-left: 25px;
  padding-left: 25px;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    opacity: 0.5;
    display: block;
    width: 1px;
    height: 30px;
    background: ${colors.lightGray};
    transform: rotate(9deg);
  }


  @media ${small} {
    margin-left: 10px;
    padding-left: 10px;
    font-size: 13px;

    &:before {
      top: 0;
      height: 20px;
    }
  }
`;

const HeaderNavbar = ({
  menuItems,
  socialItems,
  subtitle,
  openMenu,
  setRevealMenuButton,
  setOpenedFromNavigation,
}) => {
  const openMenuFromNavigation = () => {
    openMenu();
    setRevealMenuButton(true);
    setOpenedFromNavigation(true);
  };

  return (
    <Container>
      <Row justify="center">
        <Col
          md={12}
          lg={12}
          xl={12}
          align="center"
          justify="space-between"
        >
          <Row
            style={{
              flexWrap: 'nowrap',
              width: '100%',
            }}
            align="center"
            justify="space-between"
          >
            <Col
              md={4}
              lg={4}
              xl={4}
              align="flex-start"
              justify="space-between"
            >
              <HomeLinkWrapper>
                <HomeLink to="/insights/">
                  <Logo src={logoImage} />
                  <HomeLinkText>Insights</HomeLinkText>
                </HomeLink>

                {subtitle && (
                  <Subtitle>{subtitle}</Subtitle>
                )}
              </HomeLinkWrapper>
            </Col>

            <Col
              xs={4}
              sm={4}
              align={{
                sm: 'center',
                xs: 'flex-end',
              }}
              justify="space-between"
            >
              <MenuLinksRow align="center">
                {menuItems?.length && menuItems.map(({
                  url, title,
                }) => (
                  <MenuLink
                    href={url}
                    key={url}
                    target="_blank"
                    rel="noopener"
                  >
                    {title}
                  </MenuLink>
                ))}
                <MenuLink
                  onClick={openMenuFromNavigation}
                  rel="noopener"
                >
                  menu
                </MenuLink>
              </MenuLinksRow>
            </Col>
            <Col
              md={4}
              lg={4}
              xl={4}
              align="flex-end"
              justify="center"
            >
              <Hidden xs>
                <Row align="center">
                  <FollowText>follow us:</FollowText>
                  {socialItems?.length && socialItems.map(({
                    title, url, icon,
                  }) => (
                    <SocialLink
                      key={title}
                      href={url}
                      target="_blank"
                      rel="noopener"
                    >
                      <SocialIcon src={icon} />
                    </SocialLink>
                  ))}
                </Row>
              </Hidden>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

HeaderNavbar.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  openMenu: PropTypes.func.isRequired,
  setOpenedFromNavigation: PropTypes.func.isRequired,
  setRevealMenuButton: PropTypes.func.isRequired,
  socialItems: PropTypes.arrayOf(PropTypes.shape({})),
  subtitle: PropTypes.string,
};

HeaderNavbar.defaultProps = {
  menuItems: [],
  socialItems: [],
  subtitle: undefined,
};

export default HeaderNavbar;
