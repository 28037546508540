import styled from 'styled-components';
import { below } from '@utils/media-queries';

export const WhyMerixContainer = styled.div`
  ${below.large`
    height: 100%;
  `}
`;

export const SubTitle = styled.h3`
  margin-top: 20px;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;

  ${below.desktopXl`
    margin-top: 10px;
    font-size: 14px;
    line-height: 28px;
  `}
`;

export const Description = styled.div`
  margin-top: 40px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 24px;
  font-family: 'Pontiac', sans-serif;
  line-height: 40px;

  ${below.desktopXl`
    margin-top: 10px;
    font-size: 14px;
    line-height: 28px;
  `}
`;

export const UnorderedList = styled.ul`
  margin-left: 1.3em;
`;
