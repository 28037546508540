import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Col, Container, Row,
} from 'react-awesome-styled-grid';
import {
  colors, spacings,
} from '@utils/vars';
import {
  borderMixin,
} from '@utils/grid';
import mediaQueries from '@utils/media-queries';

const {
  darkerGray,
} = colors;

const {
  mobilePadding,
  tabletPadding,
  desktopPadding,
} = spacings;

const {
  small,
  medium,
} = mediaQueries;

const Wrapper = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

const Side = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  &::before {
    content: '';
    position: absolute;
    width: ${desktopPadding};

    @media ${medium} {
      width: ${tabletPadding};
    }

    @media ${small} {
      width: ${mobilePadding};
    }
  }
`;

const SideLeft = styled(Side)`
  ${({ offset }) => offset}
`;

const SideRight = styled(Side)`
    &::after {
      ${borderMixin()};
      top: ${({ previousOffset }) => previousOffset}px;
      left: calc(-2px - ${desktopPadding});
      z-index: 1;
      opacity: 0.3;
      width: 2px;
      height: ${({ previousOffset }) => -(previousOffset)}px;

      @media ${medium} {
        left:calc(-2px - ${tabletPadding});
      }

      @media ${small} {
        left: calc(-2px - ${mobilePadding});
      }
    }

    &::before {
      left: -${desktopPadding};

      @media ${medium} {
        left: -${tabletPadding};
      }

      @media ${small} {
        left: -${mobilePadding};
      }
    }
    ${({ offset }) => offset}
`;

const OffsetCol = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
    }
    ${({ offset }) => offset}
  `;

const GridStairs = ({
  colorDown,
  colorUp,
  offsets,
}) => {
  const offsetCols = offsets
    .filter((e, i) => i < offsets.length - 1 && i !== 0);

  const offsetMixin = (offset) => {
    if (offset >= 0) {
      return css`
        &::before {
          height: ${offset}px;
          background: ${colorDown};
        }`;
    }

    return css`
      &::before {
        top: ${offset}px;
        height: ${-(offset)}px;
        background: ${colorUp};
      }`;
  };

  return (
    <Wrapper>
      <SideLeft offset={offsetMixin(offsets[0])} />
      <Container style={{
        height: '100%',
        pointerEvents: 'none',
      }}
      >
        <Row style={{
          height: '100%',
          position: 'relative',
          zIndex: '1',
        }}
        >
          {offsetCols.map((offset, i) => (
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              // eslint-disable-next-line react/no-array-index-key
              key={`key${i}`}
              style={{
                height: '100%',
              }}
            >
              <OffsetCol offset={offsetMixin(offset)} />
            </Col>
          ))}
        </Row>
      </Container>
      <SideRight
        offset={offsetMixin(offsets[(offsets.length - 1)])}
        previousOffset={offsets[(offsets.length - 2)]}
      />
    </Wrapper>
  );
};

GridStairs.propTypes = {
  colorDown: PropTypes.string,
  colorUp: PropTypes.string,
  offsets: PropTypes.arrayOf(PropTypes.number).isRequired,
};

GridStairs.defaultProps = {
  colorDown: darkerGray,
  colorUp: 'white',
};

export default GridStairs;
