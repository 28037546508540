import React, {
  useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import mediaQueries from '@utils/media-queries';
import { Link } from 'gatsby';
import {
  Icon,
  Tag,
  Tags,
  Thumbnail,
} from '@components';
import {
  colors,
  transitions,
} from '@utils/vars';
import {
  checkMobile,
  getUrlParams,
} from '@utils/helpers';

const {
  xlarge,
  medium,
  small,
  xsmall,
} = mediaQueries;

const {
  highlight: highlightColor,
  lightGray: lightGrayColor,
} = colors;

const { duration } = transitions;

const ListItem = styled.li`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-weight: 700;
  font-family: 'Pontiac', sans-serif;
  line-height: 18px;
  transition: color ${duration};

  &:hover {
    color: ${highlightColor};

    @media ${small} {
      color: #fff;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-image: url("/wave.svg");
    background-position: bottom;
    background-size: contain;
    background-repeat: repeat-x;
    transition: transform ${duration};
    transform: translate3d(-100%, 0, 0);
  }
  ${({
    currentIndex,
    index,
  }) => (index === currentIndex && css`
    color: ${highlightColor};

    &::after {
      transform: translate3d(0, 0, 0);
    }
  `)}
  ${({ hasTags }) => (hasTags && css`
    & a {
      justify-content: space-between;
      align-items: flex-start;
      padding: 30px 25px;

      @media ${xlarge} {
        padding: 20px 15px;
      }

      @media ${medium} {
        padding: 10px;
      }

      @media ${small} {
        align-items: center;
        padding: 25px;
      }
    }
  `)}

  @media ${small} {
    opacity: 0.55;
    display: inline-block;
    width: 300px;
    color: #fff;
    transition: opacity ${duration};
    scroll-snap-align: center;

    &::after {
      content: unset;
    }
  ${({
    currentIndex,
    index,
  }) => (index === currentIndex && css`
    opacity: 1;
  `)}
  }

  @media ${xsmall} {
    width: 265px;
  }
`;

const Title = styled.div`
  width: ${({ hasTags }) => (hasTags ? '55%' : 'calc(100% - 41px)')};

  @media ${medium} {
    width: 100%;
    padding: 5px 0;
  }

  @media ${small} {
    width: 90%;
    font-size: 14px;
    white-space: normal;
  }
`;

const LinkElement = styled(Link)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 45px 25px 45px 40px;

  @media ${medium} {
    flex-direction: column;
  }
`;

const NavItem = ({
  content,
  content: {
    id,
    index,
    innerSlug,
    thumbnail,
    tags,
    title,
    type,
  },
  currentIndex,
  filterTags,
  hasTags,
  selectedTag,
  showMore,
  slug,
  wrapperRef,
}) => {
  const urlParams = getUrlParams(showMore, selectedTag);
  const { current: wrapperElem } = wrapperRef;
  const isMobile = checkMobile();
  const itemRef = useRef(null);

  useEffect(() => {
    if (typeof sessionStorage !== 'undefined' && !sessionStorage.getItem('sidebarScroll') && wrapperElem) {
      wrapperElem.scroll({
        behavior: 'smooth',
        left: 0,
        top: itemRef?.current?.offsetTop,
      });
    }
  }, [wrapperElem]);

  if (hasTags) {
    return (
      <ListItem
        currentIndex={currentIndex}
        id={innerSlug}
        hasTags={hasTags}
        index={index}
        ref={currentIndex === index ? itemRef : null}
      >
        <LinkElement
          to={`/${slug}/${innerSlug}/${urlParams ? `${urlParams}` : ''}`}
          state={{ content }}
          id={id}
          onClick={() => {
            if (!isMobile) {
              sessionStorage.setItem('sidebarScroll', document.getElementById('navigation-wrapper').scrollTop);
            }
          }}
        >
          <Thumbnail
            src={thumbnail}
            type={type}
          />
          <Title hasTags={hasTags}>
            {title}
            <Tags>
              {tags.map((tag) => (
                <Tag
                  key={`tag-${tag}`}
                  onClick={(event) => {
                    event.preventDefault();
                    filterTags(tag);
                  }}
                  selectedTag={selectedTag}
                  tag={tag}
                >
                  {tag}
                </Tag>
              ))}
            </Tags>
          </Title>
        </LinkElement>
      </ListItem>
    );
  }

  return (
    <ListItem
      currentIndex={currentIndex}
      id={id}
      index={index}
      ref={itemRef}
    >
      <LinkElement
        to={`/${slug}/${innerSlug}/${urlParams ? `${urlParams}` : ''}`}
        state={{ content }}
        id={id}
        onClick={() => {
          if (!isMobile) {
            sessionStorage.setItem('sidebarScroll', wrapperElem?.scrollTop);
          }
        }}
      >
        <Icon
          name={type}
          color={lightGrayColor}
        />
        <Title>{title}</Title>
      </LinkElement>
    </ListItem>
  );
};

NavItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    innerSlug: PropTypes.string,
    tags: PropTypes.array,
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  currentIndex: PropTypes.number.isRequired,
  filterTags: PropTypes.func.isRequired,
  hasTags: PropTypes.bool.isRequired,
  selectedTag: PropTypes.string,
  showMore: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  wrapperRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
};

NavItem.defaultProps = {
  selectedTag: null,
  showMore: false,
};

export default NavItem;
