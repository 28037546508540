import React from 'react';
import PropTypes from 'prop-types';
import {
  Background, Progress,
} from './ProgressBar.styled';

const ProgressBar = ({
  total, value = 2, ...props
}) => {
  const percentage = (value / total) * 100;

  return (
    <Background {...props}>
      <Progress percentage={percentage} />
    </Background>
  );
};

ProgressBar.propTypes = {
  total: PropTypes.number,
  value: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  total: 100,
};

export default ProgressBar;
