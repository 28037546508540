import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Image, ImageContainer, RatioInner, RatioWrapper, Title,
} from './InsightsSidebarItem.styled';

const Item = forwardRef(({
  image,
  url,
  title,
  isActive,
  prevPath,
  type,
}, ref) => {
  const searchParams = typeof window !== 'undefined' ? window.location.search : '';

  return (
    <Container
      to={`${url}${searchParams}`}
      $isActive={isActive}
      ref={ref}
      state={{ prevPath }}
    >
      <RatioWrapper>
        <RatioInner>
          <ImageContainer type={type}>
            <Image
              src={image}
              alt=""
            />
          </ImageContainer>
          <Title>{title}</Title>
        </RatioInner>
      </RatioWrapper>
    </Container>
  );
});

Item.propTypes = {
  image: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  prevPath: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Item.defaultProps = {
  isActive: false,
  prevPath: undefined,
};

export default Item;
