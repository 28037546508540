import React from 'react';
import { StyledOverlayContactLink } from './OverlayContactLink.styled';

const contactLabel = 'contact@merixstudio.com';

export const OverlayContactLink = () => (
  <StyledOverlayContactLink href="#">
    {contactLabel}
  </StyledOverlayContactLink>
);
