import React from 'react';
import Helmet from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import mediaQueries from '@utils/media-queries';
import Reset from '@utils/reset';

const {
  xsmall,
} = mediaQueries;

const DriftGlobalStyle = createGlobalStyle`
#drift-widget {
  top: 0 !important;

  @media ${xsmall} {
    top: unset !important;
  }
}

.body--hidden-widget #drift-widget {
  transform: translate3d(200%, 0, 0);
}
`;
const CommonScripts = () => (
  <>
    <DriftGlobalStyle />
    <Helmet>
      <link
        rel="stylesheet"
        href="/fonts.css"
      />
      <link
        rel="stylesheet"
        href="/icons.css"
      />
    </Helmet>
    <Helmet>
      <script>
        {`
          "use strict";

          !function() {
              var t = window.driftt = window.drift = window.driftt || [];
              if (!t.init) {
              if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
              t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
              t.factory = function(e) {
                  return function() {
                  var n = Array.prototype.slice.call(arguments);
                  return n.unshift(e), t.push(n), t;
                  };
              }, t.methods.forEach(function(e) {
                  t[e] = t.factory(e);
              }), t.load = function(t) {
                  var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                  o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js", o.id="drift-script";
                  var i = document.getElementsByTagName("script")[0];
                  i.parentNode.insertBefore(o, i);
              };
              }
          }();
          drift.SNIPPET_VERSION = '0.3.1';
          `}

      </script>
    </Helmet>
    <Reset />
  </>
);

export default CommonScripts;
