import styled from 'styled-components';
import { menuBreakpoints } from '../../../../utils/media-queries';

export const OverlayContactStyled = styled.div`
  display: ${({ hideContacts }) => (hideContacts ? 'none' : 'block')};
  position: absolute;
  bottom: 109px;
  left: 0;
  transition: opacity 0.3s ease;

  @media (max-width: ${menuBreakpoints.large}px) {
    display: none;
  }
`;

export const OverlayContactMenuLabelStyled = styled.div`
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  font-size: 14px;
  transition: opacity 0.3s ease;
`;
