import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './InsightsTag.styled';

const InsightsTag = ({
  children, isActive, ...props
}) => (
  <Container
    {...props}
    $isActive={isActive}
  >
    {children}
  </Container>
);

InsightsTag.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
};

InsightsTag.defaultProps = {
  isActive: false,
};

export default InsightsTag;
