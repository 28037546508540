import React from 'react';
import refreshImg from '@assets/images/icon-refresh.svg';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Image = styled.img`
   animation: ${rotate} 1.2s linear infinite;
`;

const LoadingIndicator = (props) => (
  <Image
    src={refreshImg}
    alt="loading..."
    {...props}
  />
);

export default LoadingIndicator;
