import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styled from 'styled-components';
import {
  colors,
  transitions,
} from '@utils/vars';
import mediaQueries from '@utils/media-queries';
import { Wave } from '@components';

const {
  highlight: highlightColor,
} = colors;

const {
  small,
} = mediaQueries;

const LinkElement = styled(Link)`
  display: flex;
  font-weight: bold;
  font-size: 16px;

  [class^="Wave"] {
    opacity: 0;
    margin-top: 10px;
    transition: ${transitions.duration} ease-out;
  }

  :hover {
    [class^="Wave"] {
      opacity: 1;
    }
  }
  @media ${small} {
    color: #fff;
    font-size: 14px;
  }
`;

const Text = styled.div`
  @media ${small} {
    display: flex;
    align-items: center;
  }
`;

const Arrow = styled.div`
  width: 0;
  height: 0;
  margin: 6px 18px 0 0;
  border-top: 5.5px solid transparent;
  border-right: 7px solid ${highlightColor};
  border-bottom: 5.5px solid transparent;
  transition: ${transitions.duration} ease-out;

  ${LinkElement}:hover & {
    transform: translate(-50%, 0);
  }

  @media ${small} {
    margin: 4px 18px 0 0;
  }
`;

const LandingLink = ({
  landingUrl,
  text,
}) => (
  <LinkElement
    to={`/${landingUrl}`}
  >
    <Arrow />
    <Text>
      {text}
      <Wave />
    </Text>
  </LinkElement>
);

LandingLink.propTypes = {
  landingUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default LandingLink;
