import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';

const { small } = mediaQueries;

const Tags = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px 0 0;

  @media ${small} {
    display: none;
  }
`;

export default Tags;
