import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';

import {
  colors,
  transitions,
} from '@utils/vars';

import { pushGTMEvent } from '@utils/helpers';

const { small } = mediaQueries;
const { highlight: highlightColor } = colors;
const { duration } = transitions;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #000;

  @media ${small} {
    height: calc(100vh - 40px);
  }
`;

const Player = styled(ReactPlayer)`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  cursor: pointer;
`;

const Play = styled.div`
  position: absolute;
  top: 49%;
  left: 50%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 100px;
  height: 100px;
  border: 2px solid ${highlightColor};
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity ${duration};
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(50% + 3px);
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid ${highlightColor};
    transform: translate3d(-50%, -50%, 0);
  }

  @media ${small} {
    display: none;
  }
`;

const VideoPlayer = ({
  className,
  title,
  url,
}) => {
  const [
    visible,
    setVisible,
  ] = useState(true);

  const [
    videoDuration,
    setVideoDuration,
  ] = useState(null);

  const handleEvents = (type, played) => {
    const progress = played ? Number(played.toFixed(2)) * 100 : null;

    switch (type) {
    case 'play':
      setVisible(false);
      pushGTMEvent({
        action: 'Played video',
        label: title,
      });
      break;

    case 'pause':
      setVisible(true);
      pushGTMEvent({
        action: 'Paused video',
        label: title,
      });
      break;

    case 'ended':
      pushGTMEvent({
        action: '100%',
        label: title,
      });
      break;

    case 'progress':
      if (progress === 25 || progress === 50 || progress === 75) {
        pushGTMEvent({
          action: `${progress}%`,
          label: title,
        });
      }
      break;

    default:
      break;
    }
  };

  return (
    <Wrapper className={className}>
      <Player
        url={url}
        controls
        width="100%"
        height="100%"
        onEnded={() => handleEvents('ended')}
        onPlay={() => handleEvents('play')}
        onPause={() => handleEvents('pause')}
        onProgress={(event) => handleEvents('progress', event.played)}
        onDuration={(vidDuration) => setVideoDuration(vidDuration * 1000)}
        pip={false}
        playing
        progressInterval={videoDuration / 4}
      />
      <Play visible={visible} />
    </Wrapper>
  );
};

VideoPlayer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

VideoPlayer.defaultProps = {
  className: undefined,
};

export default VideoPlayer;
