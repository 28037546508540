import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

const photoswipeTemplate = `
  <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="pswp__bg"></div>
      <div class="pswp__scroll-wrap">

          <div class="pswp__container">
              <div class="pswp__item"></div>
              <div class="pswp__item"></div>
              <div class="pswp__item"></div>
          </div>

          <div class="pswp__ui pswp__ui--hidden">

              <div class="pswp__top-bar">

                  <div class="pswp__counter"></div>

                  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
                  <button class="pswp__button pswp__button--share" title="Share"></button>
                  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
                  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                  <div class="pswp__preloader">
                      <div class="pswp__preloader__icn">
                        <div class="pswp__preloader__cut">
                          <div class="pswp__preloader__donut"></div>
                        </div>
                      </div>
                  </div>
              </div>

              <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                  <div class="pswp__share-tooltip"></div>
              </div>

              <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
              </button>

              <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
              </button>

              <div class="pswp__caption">
                  <div class="pswp__caption__center"></div>
              </div>
          </div>
      </div>
  </div>`;

export class Gallery {
  constructor() {
    this.galleryWrapper = null;
    this.items = [];
    this.initialized = false;
    this.wysiwygClass = '.wysiwyg';

    this.options = {
      barsSize: {
        bottom: 0,
        top: 0,
      },
      bgOpacity: 0.85,
      captionEl: false,
      clickToCloseNonZoomable: false,
      closeOnScroll: false,
      fullscreenEl: true,
      history: false,
      mainClass: 'pswp--minimal--dark',
      shareEl: false,
      tapToClose: false,
      tapToToggleControls: true,
    };

    this.addGalleryWrapper();
  }

  addGalleryWrapper() {
    if (typeof document === 'undefined' || this.galleryWrapper) {
      return false;
    }

    const node = document.createElement('div');

    node.innerHTML = photoswipeTemplate;
    [this.galleryWrapper] = node.children;

    document.body.appendChild(this.galleryWrapper);

    if (node.remove) {
      node.remove();
    }

    return true;
  }

  init() {
    this.images = Array.from(this.element.querySelectorAll(`${this.wysiwygClass} img`));

    if (this.images.length) {
      this.addGalleryWrapper();

      this.items = this.images.map((node) => ({
        src: node.src,
        w: node.naturalWidth || node.clientWidth,
        h: node.naturalHeight || node.clientHeight,
      }));

      this.initialized = true;
    }
  }

  registerIframeImagesEvent(element) {
    this.element = element;

    this.element.addEventListener('click', (e) => {
      if (e.target.nodeName === 'IMG') {
        if (e.target.closest('a') || !e.target.closest(this.wysiwygClass)) {
          return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (!this.initialized) {
          this.init();
        }

        const index = this.images.findIndex((img) => img.src === e.target.src) || 0;
        const gallery = new PhotoSwipe(this.galleryWrapper, PhotoSwipeUI, this.items, {
          ...this.options,
          index,
        });

        gallery.init();
      }
    });
  }
}

export const GalleryInstance = new Gallery();
