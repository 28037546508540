import styled from 'styled-components';
import { below } from '@utils/media-queries';

export const OverlayHeaderStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;

  ${below.small`
    & svg {
      width: 103px;
      height: 32px;
    }
  `}
`;
