/* eslint-disable no-new */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';

import {
  Clutch,
} from '@components';
import {
  colors,
} from '@utils/vars';

const {
  small,
  medium,
  xlarge,
} = mediaQueries;
const {
  darkerGray,
} = colors;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  transform-origin: 50% 100%;
`;

const BottomBar = ({
  clutch,
}) => (
  <Wrapper>
    <Clutch {...clutch} />
  </Wrapper>
);

BottomBar.propTypes = {
  clutch: PropTypes.shape({
    clients: PropTypes.number,
    firstText: PropTypes.string,
    rating: PropTypes.number,
    reviewsUrl: PropTypes.string,
    secondText: PropTypes.string,
  }).isRequired,
};

export default BottomBar;
