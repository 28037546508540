import styled, { css } from 'styled-components';
import { colors } from '@utils/vars';
import mediaQueries from '@utils/media-queries';

const { xlarge } = mediaQueries;

export const Container = styled.button`
  display: inline-flex;
  height: 31px;
  margin-top: 10px;
  margin-right: 15px;
  border: 1px solid ${colors.highlight};
  border-radius: 8px;
  padding: 6px 10px;
  background: ${({ $isActive }) => ($isActive ? colors.highlight : '#fff')};
  color:  ${({ $isActive }) => ($isActive ? '#fff' : '#000')};
  font-size: 14px;
  line-height: 1.28;
  transition: all 0.3s ease;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  &:last-child {
    margin-right: 0;
  }
  ${({ onClick }) => (
  !onClick ?
    null :
    css`
        &:hover,
        &:focus {
          outline:none;
          background-color: ${colors.highlight};
          color: #fff;
        }
      `
)}

  @media ${xlarge} {
    height: 27px;
    padding: 4px 8px;
    font-size: 12px;
  }
`;
