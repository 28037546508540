import axios from 'axios';
import mediaQueries from '@utils/media-queries';
import 'array-flat-polyfill';

const { small } = mediaQueries;

const getAllTags = (items) => Array.from(
  new Set(
    (items.map(
      (item) => item.tags
    ).flat())
  )
);

const getTagsCount = (tags, items, selectedTag) => {
  const sorted = tags.sort((a, b) => a.localeCompare(b));

  if (selectedTag) {
    sorted.splice(sorted.indexOf(selectedTag), 1);
  }

  const resorted = [
    selectedTag,
    ...sorted,
  ];

  const result = selectedTag ?
    resorted.map((tag) => ({
      count: items.filter((item) => item.tags.includes(tag)).length,
      tagName: tag,
    })) :
    sorted.map((tag) => ({
      count: items.filter((item) => item.tags.includes(tag)).length,
      tagName: tag,
    }));

  return result;
};

const getAllItems = (carousels) => carousels.map(({ items }) => items).flat();

const SELECT_TYPES = {
  contentType: 'contentType',
  tag: 'tag',
};

const getSelectItems = (items, selectType, applicableItems) => {
  const allItems = selectType === SELECT_TYPES.tag ?
    getAllTags(getAllItems(items)) :
    [
      ...new Set(
        getAllItems(items).map((item) => item.type)
      ),
    ];

  if (!applicableItems) {
    return allItems.map((value) => ({
      label: value,
      value,
    }));
  }

  const allApplicableItems = selectType === SELECT_TYPES.tag ?
    getAllTags(getAllItems(applicableItems)) :
    [
      ...new Set(
        getAllItems(applicableItems).map((item) => item.type)
      ),
    ];

  return allItems.map((value) => ({
    isDisabled: !allApplicableItems.includes(value),
    label: value,
    value,
  }));
};

const filterItems = ({
  tag,
  type,
  items,
}) => items
  .map((element) => ({
    ...element,
    items: element.items.filter((item) => {
      if (tag && type) {
        return item.type === type && item.tags.includes(tag);
      }

      if (type) {
        return item.type === type;
      }

      return item.tags.includes(tag);
    }),
  }));

const getSlug = (url) => (url ? url.split(' ').join('-') : '/');

const getUrlParams = (showMore, selectedTag) => {
  const multiple = !!(showMore && selectedTag);
  const more = showMore ? '?more=true' : '';
  const tag = selectedTag ? `?tag=${encodeURIComponent(selectedTag)}` : '';
  const url = multiple ? `?more=true&tag=${encodeURIComponent(selectedTag)}` : `${more}${tag}`;

  return url;
};

// Temporary disable tags other than subtopics - simulate BE bug fixed for Insights
const createTags = ({
  subtopics, // industry, persona, technology,
}) => [
  ...subtopics.filter((el) => el.name).map((subtopic) => subtopic.name),
  // ...industry.filter((el) => el.name).map((industrtEl) => industrtEl.name),
  // ...persona.filter((el) => el.name).map((personaEl) => personaEl.name),
  // ...technology.filter((el) => el.name).map((technologyEl) => technologyEl.name),
];
const parseContent = (content) => {
  const {
    chat_interaction_id,
    content_elements: elements,
    cta_text,
    slug,
    use_images,
  } = content;

  const items = elements.map((item, index) => {
    const {
      content: {
        blog,
        id,
        content_type: {
          medium_type,
        },
        external_link,
        file_pdf,
        file_video,
        image,
        meta_description,
        meta_title,
        slug: innerSlug,
        subtopics,
        industry,
        persona,
        technology,
        title,
      },
    } = item.content.content ?
      item.content :
      item;

    return {
      id,
      index,
      innerSlug,
      meta: {
        description: meta_description,
        image,
        title: meta_title,
      },
      src: external_link || file_pdf || file_video || (blog && `https://www.merixstudio.com/blog/${blog.slug}`) || null,
      tags: createTags({
        industry,
        persona,
        subtopics,
        technology,
      }),
      thumbnail: image,
      title,
      type: medium_type === 'link' ? 'url' : medium_type.toLowerCase(),
    };
  });

  return {
    chat_interaction_id,
    cta_text,
    items,
    slug,
    use_images,
  };
};

const parseLandingContent = (landingContent) => {
  const {
    chat_interaction_id,
    clutch,
    section_elements: elements,
    cta_text,
    slug,
  } = landingContent;
  const carousels = [];

  elements.forEach((sectionElement) => {
    const {
      content_elements,
      title: sectionName,
    } = sectionElement.section;

    const carousel = {
      items: [],
      name: sectionName,
    };

    content_elements.forEach((element, index) => {
      const {
        blog,
        id,
        content_type: {
          medium_type,
        },
        external_link,
        file_pdf,
        file_video,
        image,
        meta_description,
        meta_title,
        slug: innerSlug,
        subtopics,
        industry,
        persona,
        technology,
        title,
      } = element.content;

      carousel.items.push({
        id,
        index,
        innerSlug,
        meta: {
          description: meta_description,
          image,
          title: meta_title,
        },
        src: external_link || file_pdf || file_video || (blog && `https://www.merixstudio.com/blog/${blog.slug}`) || null,
        tags: createTags({
          industry,
          persona,
          subtopics,
          technology,
        }),
        thumbnail: image,
        title,
        type: medium_type === 'link' ? 'url' : medium_type.toLowerCase(),
      });
    });
    carousels.push(carousel);
  });

  return {
    carousels,
    chat_interaction_id,
    clutch: {
      clients: clutch.clients,
      firstText: clutch.first_text,
      rating: clutch.rating,
      reviewsUrl: clutch.reviews_url,
      secondText: clutch.second_text,
    },
    cta_text,
    slug,
  };
};

const getOptionsByAttr = (data = [], attr = 'name') => data.map((item) => ({
  label: item[attr],
  value: item[attr],
}));

const parseHomepageContent = (data) => data;

const checkMobile = () => (typeof window !== 'undefined' ? window.matchMedia(small).matches : false);

const pushGTMEvent = (({
  action,
  category = 'HTML5 Video',
  label,
  event = 'video',
}) => {
  try {
    if (typeof window === 'undefined' || !action || !label) throw new Error();

    if (!window.dataLayer) window.dataLayer = [];

    const content = {
      event,
      eventAction: action,
      eventCategory: category,
      eventLabel: label,
    };

    window.dataLayer.push(content);
  } catch (e) {
    console.error('Cannot add action to gtm data layer');
  }
});

const downloadFile = async ({
  src,
  title,
}) => {
  try {
    if (!window) throw new Error();

    const response = await axios.get(src, {
      responseType: 'blob',
    });

    const link = window.document.createElement('a');

    document.body.appendChild(link);

    link.style = 'display: none';
    link.href = URL.createObjectURL(response.data);
    link.download = title;
    link.click();
    link.remove();
  } catch {
    console.error('Cannot download file');
  }
};

const driftHandler = ({
  isChatActive,
  setChatActive,
  setChatLoading,
  driftId,
}) => () => {
  const { drift } = window;

  if (isChatActive) {
    drift.api.hideChat();
    setChatActive(false);
    document.body.classList.add('body--hidden-widget');
  } else {
    setChatLoading(true);
    drift.load('z44svwrsy9m6');
    drift.on('ready', (api) => {
      api.startInteraction({ interactionId: driftId });
      document.body.classList.remove('body--hidden-widget');
      drift.on('sidebarClose', () => {
        setChatActive(false);
        document.body.classList.add('body--hidden-widget');

        return false;
      });

      drift.on('sidebarOpen', () => {
        setChatActive(true);
        setChatLoading(false);
      });

      drift.on('startConversation', () => {
        setChatLoading(false);
        setChatActive(true);
      });
    });
  }
};

const sortByLabel = (a, b) => {
  const labelA = a.label.toLowerCase();
  const labelB = b.label.toLowerCase();

  if (labelA < labelB) {
    return -1;
  }

  if (labelA > labelB) {
    return 1;
  }

  return 0;
};

export {
  checkMobile,
  driftHandler,
  downloadFile,
  getAllTags,
  SELECT_TYPES,
  getSelectItems,
  filterItems,
  getOptionsByAttr,
  getSlug,
  getTagsCount,
  getUrlParams,
  parseContent,
  parseHomepageContent,
  parseLandingContent,
  pushGTMEvent,
  sortByLabel,
};
