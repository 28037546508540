import React from 'react';
import { StyledHamburgerSpan } from './HamburgerIcon.styled';

export const HamburgerIcon = () => (
  <>
    Menu
    <StyledHamburgerSpan>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 10v2H0v-2h16Zm0-5v2H0V5h16Zm0-5v2H0V0h16Z"
          fill="#FFF"
          fillRule="evenodd"
        />
      </svg>
    </StyledHamburgerSpan>
  </>
);
