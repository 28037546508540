import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';
import {
  Head,
  PdfEmbed,
  VideoPlayer,
} from '@components';
import { dimensions } from '@utils/vars';
import { GalleryInstance } from '@utils/gallery';

const {
  small,
  medium,
  xlarge,
} = mediaQueries;
const {
  contentWidth,
  contentWithTagsWidth,
} = dimensions;

const Wrapper = styled.main`
  width: ${({ hasTags }) => (hasTags ? `${contentWithTagsWidth}` : `${contentWidth}`)};
  max-width: 85%;

  @media ${xlarge} {
    width: ${({ hasTags }) => (hasTags ? '80vw' : '82vw')};
  }

  @media ${medium} {
    width: ${({ hasTags }) => (hasTags ? '75vw' : '78vw')}
  }

  @media ${small} {
    width: 100vw;
    max-width: unset;
  }
`;

const Iframe = styled.iframe`
  display: block;
  width: 100%;
  height: 100vh;

  @media ${small} {
    height: calc(100vh - 40px);
  }
`;

const Content = ({
  data,
  hasTags,
  className,
}) => {
  const {
    meta,
    src,
    title,
    type,
  } = data;
  const iframeRef = useRef(null);
  const srcForIframe = src.includes('?') ?
      `${src}&iframe-embedded=1` :
      `${src}?iframe-embedded=1`;

  switch (type) {
  case 'url':
  case 'blog':

    if (iframeRef.current && iframeRef.current.contentDocument) {
      const iframeDocument = iframeRef.current.contentDocument;

      if (iframeDocument) {
        GalleryInstance.registerIframeImagesEvent(iframeDocument);
      }
    }

    return (
      <Wrapper
        hasTags={hasTags}
        className={className}
      >
        <Head
          meta={meta}
          title={title}
        />
        <Iframe
          ref={iframeRef}
          src={srcForIframe}
          data-hj-allow-iframe
        />
      </Wrapper>
    );

  case 'pdf':
    return (
      <Wrapper
        hasTags={hasTags}
        className={className}
      >
        <Head
          meta={meta}
          title={title}
        />
        <PdfEmbed url={src} />
      </Wrapper>
    );

  case 'video':
    return (
      <Wrapper
        hasTags={hasTags}
        className={className}
      >
        <Head
          meta={meta}
          title={title}
        />
        <VideoPlayer
          url={src}
          title={title}
        >
          Your browser doesn&apos;t support HTML video
        </VideoPlayer>
      </Wrapper>
    );

  default:
    return (
      <Wrapper
        hasTags={hasTags}
        className={className}
      >
        <Head
          meta={meta}
          title={title}
        />
        it&apos;s something else
      </Wrapper>
    );
  }
};

Content.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    meta: PropTypes.object,
    src: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  hasTags: PropTypes.bool,
};

Content.defaultProps = {
  className: undefined,
  hasTags: true,
};

export default Content;
