import styled from 'styled-components';
import { colors } from '@utils/vars';

const { white } = colors;

export const OverlaySocialsIconsStyled = styled.a`
  display: inline-block;
  margin-right: 40px;
  line-height: 0;
  color: ${white};

  &:last-child {
    margin-right: 0;
  }
`;
