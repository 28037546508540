import React from 'react';
import PropTypes from 'prop-types';
import { OverlayContactLink } from './OverlayContactLink/OverlayContactLink';
import {
  OverlayContactMenuLabelStyled,
  OverlayContactStyled,
} from './OverlayContact.styled';

const contactLabel = 'Contact us';

export const OverlayContact = ({ hideContacts }) => (
  <OverlayContactStyled hideContacts={hideContacts}>
    <OverlayContactMenuLabelStyled>
      {contactLabel}
    </OverlayContactMenuLabelStyled>
    <OverlayContactLink />
  </OverlayContactStyled>
);

OverlayContact.propTypes = {
  hideContacts: PropTypes.bool.isRequired,
};

