import styled from 'styled-components';
import ProgressBar from '@components/ProgressBar/ProgressBar';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const InfoText = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
`;

export const ProgressContainer = styled.div`
  width: 200px;
`;

export const ListProgressBar = styled(ProgressBar)`
  height: 6px;
`;
