import styled, { css } from 'styled-components';
import { below } from '@utils/media-queries';
import { colors } from '@utils/vars';

const { white } = colors;

export const OverlayListButtonSmallStyled = styled.button`
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  background: none;
  color: #fff;
  transition: color 0.2s ease;

  span:last-child {
    width: 12px;
    height: 12px;
    margin: 0 0 1px 6px;
  }

  ${below.large`
    font-size: 20px;
  `};

  ${({ isExpanded }) => isExpanded && css`
    color: rgba(255, 255, 255, 0.2);
  `}


  &.${(props) => props.setActiveElement} {
    color: ${white};
  }
`;
