/* eslint-disable react/no-danger */
/* eslint-disable no-new */
import React, {
  useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container,
} from 'react-awesome-styled-grid';
import styled from 'styled-components';
import mediaQueries, { breakpoints } from '@utils/media-queries';
import { RainSection } from '@utils/rain-section';
import {
  HeaderNavbar,
} from '@components';
import {
  colors,
} from '@utils/vars';

const {
  small, medium, xlarge,
} = mediaQueries;

const {
  darkerGray,
} = colors;

const XL_HEADER_HEIGHT = '504px';

const Jumbotron = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  height: 630px;
  padding-bottom: 152px;
  background-color: ${darkerGray};
  color: #fff;

  @media ${xlarge} {
    height: ${XL_HEADER_HEIGHT};
  }

  @media ${medium} {
    z-index: 1;
    height: auto;
    min-height: 382px;
    padding-top: 30px;
    padding-bottom: 60px;
  }
  @media ${small} {
    z-index: 1;
    min-height: 282px;
    padding-top: 30px;
    padding-bottom: 60px;
  }
`;

const CenterContainer = styled(Container)`
  display: flex;
`;

const TitleContainer = styled.div`
  z-index: 1;
  max-width: 870px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    z-index: 1;
    opacity: 1;
    width: 74%;
    height: 630px;
    background: linear-gradient(to right,rgba(38,38,38,0.7) 58%,rgba(38,38,38,0) 100%);
  }

  @media ${xlarge} {
    max-width: 750px;

    &::before {
      height: 504px;
    }
  }

  @media ${medium} {
    max-width: 100%;

    &::before {
      height: 382px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top,  rgba(38,38,38,1) 0%,rgba(4,4,4,0) 88%,rgba(0,0,0,0) 99%);
    }
  }

  @media ${small}{
    &::before {
      height: 282px;
    }
  }
`;

const Rain = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  > canvas {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: auto !important;
    height: 630px;

    @media ${xlarge} {
      height: 504px;
    }

    @media ${medium} {
      height: 382px;
    }

    @media ${small} {
      height: 282px;
    }
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: flex;
  justify-content: flex-end;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .1;
    background-color: rgb(38,38,38);
  }
  ${({ hasVideo }) => (hasVideo && `
    @media (min-width: ${breakpoints.medium + 1}px) {
      display: none;
    }
  `)};
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: flex;
  justify-content: flex-end;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .1;
    background-color: rgb(38,38,38);
  }


    @media ${medium} {
      display: none;
    }
`;

const ImageInner = styled.div`
  position: relative;
  display: inline-flex;
  width: auto;
  height: 100%;  

   &::before {
     content: '';
     position: absolute;
     width: 200px;
     height: 100%;
     background: linear-gradient(to right, rgba(38,38,38,1) 10%,rgba(38,38,38, 0) 100%);
   }

  @media ${medium} {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top,  rgba(38,38,38,1) 0%,rgba(4,4,4,0) 88%,rgba(0,0,0,0) 99%);
    }
  }

  @media (min-width: ${breakpoints.xxlarge}px) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to left,  rgba(38,38,38,1) 10%,rgba(4,4,4,0) 88%,rgba(0,0,0,0) 99%);
    }
  }
`;

const VideoInner = styled(ImageInner)`
  width: auto;
  margin-right: auto;
  margin-left: auto;

  &:before,
  &:after {
    z-index: 1;
  }

  @media (min-width: ${breakpoints.medium + 1}px) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -20px;
      width: calc(50% + 20px);
      height: 100%;
      background: linear-gradient(to left,  rgba(38,38,38,1) 10%,rgba(4,4,4,0) 88%,rgba(0,0,0,0) 99%);
    }
  }

  @media (min-width: ${breakpoints.xxlarge}px) {
    &::after {
      right: -20px;
      width: 50%;
    }
  }

  @media ${xlarge} {
    height: ${XL_HEADER_HEIGHT};
  }
`;

const BackgroundVideo = styled.video`
  width: auto;
  height: 100%;
`;

const BackgroundImage = styled.img`
    width: auto;
    max-width: none;
    max-height: 100%;
`;

const Title = styled.div`
  position: relative;
  z-index: 2;
  padding-bottom: 17px;
  color: #fff;
  font-weight: 300;
  font-size: 60px;
  line-height: 1.2;

  strong {
    font-weight: bold;
  }

  @media ${xlarge} {
     font-size: 48px;
  }

  @media ${medium} {
    font-size: 36px;
  }
`;

const Subtitle = styled.div`
  position: relative;
  z-index: 2;
  width: fit-content;
  font-weight: normal;
  font-size: 20px;
  font-family: "fira_sansregular", sans-serif;
  line-height: 2;

  @media ${xlarge} {
     font-size: 16px;
  }
`;

const HomeHeader = ({
  image,
  subtitle,
  navbarSubtitle,
  title,
  menuItems,
  socialItems,
  video,
  openMenu,
  revealMenuButton,
  setRevealMenuButton,
  setOpenedFromNavigation,
}) => {
  const rainEl = useRef(null);

  useEffect(() => {
    let rainRegistered = false;
    const viewportWidth = typeof window !== 'undefined' && document.documentElement.clientWidth;

    if (viewportWidth && viewportWidth <= breakpoints.small) {
      return;
    }

    if (rainEl.current && !rainRegistered) {
      new RainSection(rainEl.current);
      rainRegistered = true;
    }
  }, [rainEl]);

  return (
    <header>
      <HeaderNavbar
        menuItems={menuItems}
        openMenu={openMenu}
        revealMenuButton={revealMenuButton}
        setOpenedFromNavigation={setOpenedFromNavigation}
        setRevealMenuButton={setRevealMenuButton}
        socialItems={socialItems}
        subtitle={navbarSubtitle}
      />
      <Jumbotron>
        <CenterContainer>

          <TitleContainer>
            <Rain
              rain-section="rain-section"
              ref={rainEl}
            >
              <Title dangerouslySetInnerHTML={{ __html: title.replace('&nbsp;', ' ') }} />
              <Subtitle>
                <span dangerouslySetInnerHTML={{ __html: subtitle }} />
              </Subtitle>
            </Rain>
          </TitleContainer>

          {video && (
            <VideoContainer>
              <VideoInner>
                <BackgroundVideo
                  muted
                  autoPlay
                  loop
                  src={video}
                />
              </VideoInner>
            </VideoContainer>
          )}

          <ImageContainer hasVideo={!!video}>
            <ImageInner>
              <BackgroundImage
                alt=""
                src={image}
              />
            </ImageInner>
          </ImageContainer>
        </CenterContainer>
      </Jumbotron>
    </header>
  );
};

HomeHeader.propTypes = {
  image: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  navbarSubtitle: PropTypes.string,
  openMenu: PropTypes.func.isRequired,
  revealMenuButton: PropTypes.bool.isRequired,
  setOpenedFromNavigation: PropTypes.func.isRequired,
  setRevealMenuButton: PropTypes.func.isRequired,
  socialItems: PropTypes.arrayOf(PropTypes.shape({})),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.string,
};

HomeHeader.defaultProps = {
  menuItems: [],
  navbarSubtitle: undefined,
  socialItems: [],
  video: undefined,
};

export default HomeHeader;
