import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Col, Container, Row,
} from 'react-awesome-styled-grid';
import {
  borderMixin,
  config,
} from '@utils/grid';

const { columns: visibleColumns } = config;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
  opacity: 0.3;
  width: 100%;
  pointer-events: none;
`;
const Border = styled.div`
  position: relative;
  height: 100%;

    &::before {
      ${borderMixin()};
    }

    &:last-child::after {
      ${borderMixin('right')};
    }
`;
const ContainerBorder = styled(Border)`
    &::before {
      width: 2px;
    }
    &:last-child::after {
      width: 2px;
    }
`;
// eslint-disable-next-line react/jsx-props-no-spreading
const TransparentCol = styled((props) => <Col {...props} />)`
   display: ${({ index }) => (index >= visibleColumns.xs ? 'none' : 'block')};
   height: 100%;

   @media (min-width: 800px) {
     display: ${({ index }) => (index >= visibleColumns.sm ? 'none' : 'block')};
   }

   @media (min-width: 1100px) {
     display: ${({ index }) => (index >= visibleColumns.md ? 'none' : 'block')};
   }
`;

const GridTransparent = ({
  className, zIndex,
}) => (
  <Wrapper
    className={className}
    zIndex={zIndex}
  >
    <Container style={{
      height: '100%',
      pointerEvents: 'none',
      position: 'relative',
    }}
    >
      <ContainerBorder>
        <Row style={{
          height: '100%',
          position: 'relative',
          zIndex: '1',
        }}
        >
          {[...Array(12)].map((x, i) => (
            <TransparentCol
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              // eslint-disable-next-line
              key={`col${i}`}
              index={i}
            >
              <Border />
            </TransparentCol>
          ))}
        </Row>
      </ContainerBorder>
    </Container>
  </Wrapper>
);

GridTransparent.propTypes = {
  className: PropTypes.string,
  zIndex: PropTypes.number,
};

GridTransparent.defaultProps = {
  className: undefined,
  zIndex: -1,
};

export default GridTransparent;
