import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';
import ThumbnailImage from '@assets/images/services@2x.png';
import { Icon } from '@components';
import { colors } from '@utils/vars';
import { below } from '@utils/media-queries';
import {
  BackButton,
  Categories,
  ColumnContent,
  ColumnWithThumb,
  Item,
  Label,
  ListItem,
  ListItemAnchor,
  ReadMoreButton,
  ReadMoreIconStyled,
} from '../../OverlaySubMenu.styled';

const {
  highlight,
  white,
} = colors;

export const ServicesContainer = styled.div`
  ${below.large`
    height: 100%;
  `}
`;

const coreServicesItems = [
  {
    id: 1,
    label: 'Web app development',
    url: 'https://www.merixstudio.com/services/web-app-development/',
  },
  {
    id: 2,
    label: 'Mobile app development',
    url: 'https://www.merixstudio.com/services/mobile-app-development/',
  },
  {
    id: 3,
    label: 'Software development consulting',
    url: 'https://www.merixstudio.com/services/software-development-consulting/',
  },
  {
    id: 4,
    label: 'Product design',
    url: 'https://www.merixstudio.com/services/product-design/',
  },
];

const dedicatedSolutionsItems = [
  {
    id: 1,
    label: 'Startup DNA',
    url: 'https://www.merixstudio.com/services/startup-dna/',
  },
  {
    id: 2,
    label: 'Software Modernization',
    url: 'https://www.merixstudio.com/services/software-modernization/',
  },
  {
    id: 3,
    label: 'Machine Learning & AI',
    url: 'https://www.merixstudio.com/services/machine-learning-ai//',
  },
];

const componentTexts = {
  categoryOneLabel: 'Core services',
  categoryTwoLabel: 'Dedicated solutions',
  readMoreBtnText: 'Read more',
};
const {
  categoryOneLabel,
  categoryTwoLabel,
  readMoreBtnText,
} = componentTexts;

export const Services = ({ closeMenu }) => (
  <ServicesContainer>
    <Item>
      <ColumnContent>
        <FadeIn>
          <BackButton onClick={closeMenu}>
            <Icon
              color={highlight}
              name="MenuBackIcon"
            />
          </BackButton>
          <Categories>
            <Label>{categoryOneLabel}</Label>
            <ul>
              {coreServicesItems.map((element) => (
                <ListItem key={element.id}>
                  <ListItemAnchor href={element.url}>{element.label}</ListItemAnchor>
                </ListItem>
              ))}
            </ul>
          </Categories>
          <Categories>
            <Label>{categoryTwoLabel}</Label>
            <ul>
              {dedicatedSolutionsItems.map((element) => (
                <ListItem key={element.id}>
                  <ListItemAnchor href={element.url}>{element.label}</ListItemAnchor>
                </ListItem>
              ))}
            </ul>
          </Categories>
          <ReadMoreButton
            href="https://merixstudio.com/services/"
            reduceMargin
          >
            {readMoreBtnText}
            <ReadMoreIconStyled>
              <Icon
                name="ReadMoreIcon"
                color={white}
              />
            </ReadMoreIconStyled>
          </ReadMoreButton>
        </FadeIn>
      </ColumnContent>
      <ColumnWithThumb>
        <img
          alt="thumbnail"
          src={ThumbnailImage}
        />
      </ColumnWithThumb>
    </Item>
  </ServicesContainer>
);
Services.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};
