import styled, { css } from 'styled-components';
import {
  above,
  below,
} from '@utils/media-queries';
import {
  scaleFadeIn,
  scaleFadeOut,
} from '@utils/menu-animations';

export const OverlayContentStyled = styled.nav`
  ${scaleFadeOut}

  ${({ isVisible }) => isVisible && css`
    ${scaleFadeIn};
  `}

  position: relative;
  display: flex;
  align-items: center;
  max-width: 1310px;
  height: 100%;
  margin: 0 auto;
  padding: 85px 0;

  ${above.desktopXl`
    max-width: 1810px;
  `}

  ${below.large`
    align-items: flex-start;
    max-width: none;
    padding: 75px 0 0;
  `}


  ${below.small`
    padding: 65px 0 0;
  `}

  ${({ isExpanded }) => isExpanded && css`
    ${below.large`
      opacity: 1;
      transition: transform 0.3s ease;
    `};
  `}
`;

export const OverlayContentUlStyled = styled.ul`
  flex: 0 0 430px;
  transition: transform 0.2s ease;

  ${({ isExpanded }) => !isExpanded && css`
    transform: translateX(210%);
    ${above.desktopXl`
      flex: 0 0 430px;
      transition: transform 0.2s ease;
    `}

    ${below.desktopXl`
      flex-basis: 24%;
    `}

    ${below.large`
      overflow: auto;
      flex-basis: 100%;
      max-height: 100%;
      padding-bottom: 94px;
      transform: translateX(0);
    `}
  `}

  ${({ isExpanded }) => isExpanded && css`
    transition: transform 0.2s ease;

    ${above.desktopXl`
      transform: translateX(0);
    `}

    ${below.desktop`
      transform: translateX(0%);
    `}

    ${below.large`
      display: none;
    `}
  `}
`;
