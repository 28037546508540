import styled from 'styled-components';

export const OverlayListItemIconStyled = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 0 3px 6px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  [data-section="contact"] & {
    display: none;
  }
`;
