import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, InfoText, ListProgressBar, ProgressContainer,
} from './ListProgress.styled';

const ListProgress = ({
  allItemsCount,
  itemsCount,
}) => (
  <Container>
    <InfoText>
      {`You are viewing ${itemsCount} of ${allItemsCount} content pieces...`}
    </InfoText>
    <ProgressContainer>
      <ListProgressBar
        value={itemsCount}
        total={allItemsCount}
      />
    </ProgressContainer>
  </Container>
);

ListProgress.propTypes = {
  allItemsCount: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
};

export default ListProgress;
