import React from 'react';
import PropTypes from 'prop-types';
import FadeIn from 'react-fade-in';
import ThumbnailImage from '@assets/images/about-us@2x.png';
import { Icon } from '@components';
import { colors } from '@utils/vars';
import {
  BackButton,
  ColumnContent,
  ColumnWithThumb,
  Item,
  ReadMoreButton,
  ReadMoreIconStyled,
  Title,
} from '../../OverlaySubMenu.styled';

import {
  AboutUsContainer,
  Description,
} from './AboutUs.styled';

const {
  highlight,
  white,
} = colors;

const componentTexts = {
  descriptionText: 'We are Merixstudio - neither Jedi nor gurus (nor any other kind of superheroes). From a business perspective, we\'re an agile software team crafting and launching fully-fledged web and mobile apps for clients worldwide. But in our geeky day-to-day life, we\'re a bunch of friends fully committed to our passions.',
  readMoreBtnText: 'Read more',
  titleText: 'We do custom software and a great workplace too!',
};

export const AboutUs = ({ closeMenu }) => (
  <AboutUsContainer>
    <Item>
      <ColumnContent>
        <FadeIn>
          <BackButton onClick={closeMenu}>
            <Icon
              color={highlight}
              name="MenuBackIcon"
            />
          </BackButton>
          <Title>
            {componentTexts.titleText}
          </Title>
          <Description>{componentTexts.descriptionText}</Description>
          <ReadMoreButton href="https://merixstudio.com/about/">
            {componentTexts.readMoreBtnText}
            <ReadMoreIconStyled>
              <Icon
                name="ReadMoreIcon"
                color={white}
              />
            </ReadMoreIconStyled>
          </ReadMoreButton>
        </FadeIn>
      </ColumnContent>
      <ColumnWithThumb>
        <img
          alt="thumbnail"
          src={ThumbnailImage}
        />
      </ColumnWithThumb>
    </Item>
  </AboutUsContainer>
);
AboutUs.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};

