import React, { Suspense } from 'react';

const ReactCustomScroller = React.lazy(() => import('react-custom-scroller'));
const CustomScroller = (props) => {
  const isSSR = typeof document === 'undefined';

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <ReactCustomScroller {...props} />
        </Suspense>
      )}
    </>
  );
};

export default CustomScroller;
