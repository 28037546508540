import React from 'react';
import { Icon } from '@components';
import { OverlaySocialsIconsStyled } from './OverlaySocialsIcons.styled';

const iconsArray = [
  'Facebook',
  'Linkedin',
  'Twitter',
  'Instagram',
  'Dribble',
  'Behance',
];

export const OverlaySocialsIcons = () => (
  <>
    {iconsArray.map((icon) => (
      <OverlaySocialsIconsStyled
        href="#"
        key={icon}
      >
        <Icon name={icon} />
      </OverlaySocialsIconsStyled>
    ))}
  </>
);
