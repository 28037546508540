import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  colors,
  transitions,
} from '@utils/vars';
import mediaQueries from '@utils/media-queries';

const {
  xxsmall,
  xsmall,
  small,
  medium,
  xlarge,
} = mediaQueries;

const {
  darkGray,
  highlight: highlightColor,
  lightGray,
} = colors;

const { duration } = transitions;

const Element = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin: ${({ type }) => (!type || type === 'download' ? '0 0 20px' : '')};
  border: none;
  background-color: #fff;
  color: ${lightGray};
  transition: color ${duration};
  cursor: pointer;
  ${({ type }) => ((type === 'cta' || type === 'download') && css`
    background-color: ${highlightColor};
    color: #fff;
    font-weight: 700;
    font-size: 16px;
  `)}
  ${({ type }) => (type === 'download' && css`
    max-width: 180px;
  `)}
  ${({ type }) => (type === 'toggler' && css`
    display: none;
  `)}

  &:hover {
    ${({ type }) => !type && css`
      color: #000;
    `}
  }
  ${({ isLoading }) => isLoading &&
      css`animation: button-opacity-animation 0.5s both alternate infinite;`}

  @keyframes button-opacity-animation {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.3;
    }
  }

  @media ${xlarge} {
    height: 35px;
  }

  @media ${medium} {
    height: 40px;
    font-size: 14px;
    ${({ type }) => !type && css`
      font-size: 16px;
    `}
  }

  @media ${small} {
    width: 50%;
    height: 40px;
    font-size: 16px;
    ${({ type }) => !type && css`
      display: none;
`}
    ${({ type }) => (type === 'toggler' && css`
      position: relative;
      display: block;
      background-color: ${darkGray};
      color: #fff;
      font-weight: 700;

      ::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 23px;
        border-right: 6px solid transparent;
        border-bottom: 8px solid ${highlightColor};
        border-left: 6px solid transparent;
        transition: transform ${duration};
        transform: ${({ navigationVisibility }) => (navigationVisibility ? 'translate3d(0, -50%, 0) rotate3d(1, 0, 0, 180deg)' : 'translate3d(0, -50%, 0)')};

        @media ${xsmall} {
          right: 8px;
        }
      }
    `)}
  }

  @media ${xsmall} {
    font-size: 14px;
  }

  @media ${xxsmall} {
    font-size: 12px;
  }
`;

const Button = ({
  isLoading,
  onClick,
  navigationVisibility,
  type,
  text,
}) => (
  <Element
    disabled={isLoading}
    isLoading={isLoading}
    type={type}
    navigationVisibility={navigationVisibility}
    onClick={onClick}
  >
    {text}
  </Element>
);

Button.propTypes = {
  isLoading: PropTypes.bool,
  navigationVisibility: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Button.defaultProps = {
  isLoading: false,
  navigationVisibility: false,
  onClick: () => {},
  type: 'button',
};

export default Button;
