import styled, { css } from 'styled-components';
import { below } from '@utils/media-queries';

export const OverlayEstimateButtonStyles = styled.button`
  ${below.large`
    width: 100%;
    justify-content: space-between;
  `};
  position: absolute;
  padding: 0 32px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 273px;
  height: 72px;
  border: none;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  white-space: nowrap;
  transition: all 0.3s ease;

    ${({ isExpanded }) => isExpanded && css`
      background-color: #fff;
      color: #000;

      ${below.large`
      background-color: #000;
      color: #fff;
      transition: all 0.3s ease;
      transform: translateX(calc(-100% - 32px));
    `}
  `}

  .resizing & {
    transition: none!important;
  }
`;

