import React from 'react';
import PropTypes from 'prop-types';
import { OverlayListItemIcon } from '../OverlayListItemIcon/OverlayListItemIcon';
import { OverlayListButtonStyled } from './OverlayListButton.styled';
import { MenuContext } from '../../../../MenuContext';

export const OverlayListButton = ({
  label,
  isActive,
  isOpened,
  dataSection,
}) => (
  <MenuContext.Consumer>
    {({ isExpanded }) => (
      <OverlayListButtonStyled
        isExpanded={isExpanded}
        data-section={dataSection}
        type="button"
        className={isActive}
        setActiveElement={isActive}
        onClick={() => isOpened(dataSection)}
      >
        {label}
        <OverlayListItemIcon />
      </OverlayListButtonStyled>
    )}
  </MenuContext.Consumer>
);

OverlayListButton.propTypes = {
  dataSection: PropTypes.string.isRequired,
  isActive: PropTypes.string,
  isOpened: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

OverlayListButton.defaultProps = {
  isActive: 'null',
};
