import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonCube } from '@components/index';
import { driftHandler } from '@utils/helpers';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';

const {
  xxsmall, xsmall, xlarge,
} = mediaQueries;

const StyledButtonCube = styled(ButtonCube)`
  font-size: 18px;

  @media ${xlarge} {
    font-size: 16px;
  }

  @media ${xsmall} {
    font-size: 14px;
  }

  @media ${xxsmall} {
    font-size: 12px;
  }
`;

const ConsultationButton = ({
  activeText, loadingText, text, driftId,
}) => {
  const [
    isChatActive,
    setChatActive,
  ] = useState(false);
  const [
    isChatLoading,
    setChatLoading,
  ] = useState(false);

  const handleChatButtonClick = driftHandler({
    driftId,
    isChatActive,
    setChatActive,
    setChatLoading,
  });

  return (
    <StyledButtonCube onClick={handleChatButtonClick}>
      {isChatActive && activeText }
      {isChatLoading && loadingText }
      {!isChatLoading && !isChatActive && text}
    </StyledButtonCube>
  );
};

ConsultationButton.propTypes = {
  activeText: PropTypes.string,
  driftId: PropTypes.number.isRequired,
  loadingText: PropTypes.string,
  text: PropTypes.string,
};

ConsultationButton.defaultProps = {
  activeText: 'Close chat',
  loadingText: 'Opening chat',
  text: 'Get a consultation',
};

export default ConsultationButton;
