import React from 'react';
import PropTypes from 'prop-types';
import FadeIn from 'react-fade-in';
import ThumbnailImage from '@assets/images/hex@2x.png';
import { Icon } from '@components';
import { colors } from '@utils/vars';
import {
  BackButton,
  ColumnContent,
  ColumnWithThumb,
  Item,
  ListItem,
  ReadMoreButton,
  ReadMoreIconStyled,
  Title,
} from '../../OverlaySubMenu.styled';
import {
  Description,
  SubTitle,
  UnorderedList,
  WhyMerixContainer,
} from './WhyMerix.styled';

const {
  highlight,
  white,
} = colors;

const componentTexts = {
  readMoreBtnText: 'Read more',
  subTitleText: 'We have 200+ full-stack agile software experts ready to support your business',
  titleText: 'Build your IT strategy with the right tech team',
};

const listTextItems = [
  {
    id: 1,
    text: 'Trusted partner since 1999',
  },
  {
    id: 2,
    text: 'Awarded for custom solutions',
  },
  {
    id: 3,
    text: 'Serving global customers',
  },
  {
    id: 4,
    text: 'Flexible cooperation models',
  },
  {
    id: 5,
    text: 'Diverse tech stack capabilities',
  },
  {
    id: 6,
    text: 'Full range of IT services',
  },
];

export const WhyMerix = ({
  closeMenu,
  isExpanded,
}) => (
  <WhyMerixContainer>
    <Item>
      <ColumnContent>
        <FadeIn>
          <BackButton onClick={closeMenu}>
            <Icon
              color={highlight}
              name="MenuBackIcon"
            />
          </BackButton>
          <Title isExpanded={isExpanded}>
            {componentTexts.titleText}
          </Title>
          <SubTitle isExpanded={isExpanded}>
            {componentTexts.subTitleText}
          </SubTitle>
          <Description>
            <UnorderedList isExpanded={isExpanded}>
              {listTextItems.map((element) => (
                <ListItem
                  isExpanded={isExpanded}
                  key={element.id}
                  withMarker
                >
                  {element.text}
                </ListItem>
              ))}
            </UnorderedList>
          </Description>
          <ReadMoreButton href="https://merixstudio.com/why-merix/">
            {componentTexts.readMoreBtnText}
            <ReadMoreIconStyled>
              <Icon
                name="ReadMoreIcon"
                color={white}
              />
            </ReadMoreIconStyled>
          </ReadMoreButton>
        </FadeIn>
      </ColumnContent>
      <ColumnWithThumb isExpanded={isExpanded}>
        <img
          alt="thumbnail"
          src={ThumbnailImage}
        />
      </ColumnWithThumb>
    </Item>
  </WhyMerixContainer>
);

WhyMerix.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};
