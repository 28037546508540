import styled from 'styled-components';

export const StyledCloseButton = styled.span`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 141px;
  height: 44px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
`;

export const StyledCloseButtonIcon = styled.span`
  margin-left: 16px;
`;
