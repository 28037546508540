import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';
import ThumbnailImage from '@assets/images/careers@2x.png';
import { Icon } from '@components';
import { colors } from '@utils/vars';
import { below } from '@utils/media-queries';
import api from '@utils/api';
import {
  BackButton,
  Categories,
  ColumnContent,
  ColumnWithThumb,
  Item,
  Label,
  ListItem,
  ListItemAnchor,
  ReadMoreButton,
  ReadMoreIconStyled,
} from '../../OverlaySubMenu.styled';

const {
  highlight,
  white,
} = colors;

const CareersContainer = styled.div`
  ${below.large`
    height: 100%;
  `}
`;
const componentTexts = {
  categoryOneLabel: 'Current openings',
  readMoreBtnText: 'Read more',
};

const {
  categoryOneLabel,
  readMoreBtnText,
} = componentTexts;

const BASE_URL = 'https://www.merixstudio.com';

const { API_URL } = process.env;
const { API_BASIC_AUTH_USERNAME: username } = process.env;
const { API_BASIC_AUTH_PASSWORD: password } = process.env;

export const Careers = ({ closeMenu }) => {
  const [
    jobs,
    setJobs,
  ] = useState([]);

  const auth = process.env.NODE_ENV === 'development' && username && password ?
    {
      auth: {
        password,
        username,
      },
    } :
    {};

  const getJobs = async () => {
    const { data } = await api.get(`${API_URL}/traffit-jobs/`, auth);

    setJobs(data);
  };

  useEffect(() => {
    getJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CareersContainer>
      <Item>
        <ColumnContent>
          <FadeIn>
            <BackButton onClick={closeMenu}>
              <Icon
                color={highlight}
                name="MenuBackIcon"
              />
            </BackButton>
            <Categories>
              <Label>{categoryOneLabel}</Label>
              <ul>
                {jobs.map(({
                  title,
                  absolute_url,
                }) => (
                  <ListItem key={title}>
                    <ListItemAnchor
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${BASE_URL}${absolute_url}`}
                    >
                      {title}
                    </ListItemAnchor>
                  </ListItem>
                ))}
              </ul>
            </Categories>
            <ReadMoreButton
              href="https://merixstudio.com/careers/"
              reduceMargin
            >
              {readMoreBtnText}
              <ReadMoreIconStyled>
                <Icon
                  name="ReadMoreIcon"
                  color={white}
                />
              </ReadMoreIconStyled>
            </ReadMoreButton>
          </FadeIn>
        </ColumnContent>
        <ColumnWithThumb>
          <img
            alt="thumbnail"
            src={ThumbnailImage}
          />
        </ColumnWithThumb>
      </Item>
    </CareersContainer>
  );
};

Careers.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};
