import React from 'react';
import PropTypes from 'prop-types';
import { OverlaySocialsStyled } from './OverlaySocials.styled';
import { OverlaySocialsIcons } from './OverlaySocialsIcons/OverlaySocialsIcons';
import { OverlaySocialsLabel } from './OverlaySocialsLabel/OverlaySocialsLabel';

export const OverlaySocials = ({ hideSocialLabel }) => (
  <OverlaySocialsStyled>
    <OverlaySocialsLabel hideSocialLabel={hideSocialLabel} />
    <OverlaySocialsIcons />
  </OverlaySocialsStyled>
);

OverlaySocials.propTypes = {
  hideSocialLabel: PropTypes.bool.isRequired,
};
