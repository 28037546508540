import React, {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import { Card } from '@components';
import {
  colors,
  spacings,
} from '@utils/vars';
import mediaQueries, { breakpoints } from '@utils/media-queries';

const {
  small,
} = mediaQueries;

const { highlight: highlightColor } = colors;
const { mobilePadding } = spacings;

const CarouselWrapper = styled.section`

  :not(:last-child) {
    margin-bottom: 56px;
  }

  .slide__inner--custom {
    position: unset;
  }

  .slide--custom {
    height: unset;
    padding-bottom: unset !important;

    :not(:first-child) {
      .slide__inner--custom {
        padding-left: 16px;

        @media ${small} {
          padding-left: 0;
        }
      }
    }
  }

  .carousel__slider-tray-wrapper,
  .carousel__slider-tray {
    height: inherit;
  }

  .carousel-button {
    width: 31px;
    border: none;
    padding: 0;
    background: transparent;


    &[disabled] {
      opacity: 0.4;
    }

    @media ${small} {
      width: ${mobilePadding};
    }
  }

  @media ${small} {
    margin: 0 -${mobilePadding};
  }
`;

const Title = styled.h3`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-bottom: 24px;
  font-weight: bold;
  font-size: 20px;
`;

const Line = styled.hr`
  width: 100%;
  margin: auto 0 auto 30px;
  border-top: 1px solid ${highlightColor};
  border-bottom: none;
`;

const Arrow = styled.div`
  width: 0;
  height: 0;
  margin-left: ${({ direction }) => (direction === 'right' ? 'auto' : 0)};
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  ${({ direction }) => (direction === 'right' ? 'border-left' : 'border-right')}: 15px solid ${highlightColor};
`;

const Carousel = ({
  carousel,
  filterTags,
  selectedTag,
  slug,
  windowWidth,
}) => {
  const {
    items,
    name,
  } = carousel;
  const slider = useRef(null);
  const [
    highest,
    setHighest,
  ] = useState(null);
  const visibleSlides = useMemo(() => (windowWidth > breakpoints.small ? 3 : 1), [windowWidth]);
  const areButtonsVisible = useMemo(() => (items.length > visibleSlides), [
    items,
    visibleSlides,
  ]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (slider.current) {
        const heights = [...slider.current.instance.sliderTrayElement.children]
          .map((slide) => slide.querySelector('a').clientHeight);

        setHighest(Math.max(...heights));
      }
    }, 700);
  }, [
    slider,
    windowWidth,
  ]);

  return (
    <>
      <Title>
        {name}
        <Line />
      </Title>
      <CarouselWrapper>
        <CarouselProvider
          naturalSlideHeight={100}
          naturalSlideWidth={100}
          style={{ display: 'flex' }}
          totalSlides={items.length}
          visibleSlides={visibleSlides}
        >
          {areButtonsVisible && (
          <ButtonBack className="carousel-button">
            <Arrow direction="left" />
          </ButtonBack>
          )}
          <Slider
            style={{
              flex: '1',
            }}
            ref={slider}
          >
            {items.map((item, index) => {
              const data = {
                ...item,
              };

              return (
                <Slide
                  className="slide--custom"
                  index={index}
                  innerClassName="slide__inner--custom"
                  key={data.src}
                >
                  <Card
                    content={data}
                    filterTags={filterTags}
                    height={highest}
                    key={data.innerSlug}
                    selectedTag={selectedTag}
                    slug={slug}
                  />
                </Slide>
              );
            })}
          </Slider>
          {areButtonsVisible && (
            <ButtonNext className="carousel-button">
              <Arrow direction="right" />
            </ButtonNext>
          )}
        </CarouselProvider>
      </CarouselWrapper>
    </>
  );
};

Carousel.propTypes = {
  carousel: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
  filterTags: PropTypes.func.isRequired,
  selectedTag: PropTypes.string,
  slug: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

Carousel.defaultProps = {
  selectedTag: null,
};

export default Carousel;
