import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { driftHandler } from '@utils/helpers';
import { OverlayEstimateButtonStyles } from './OverlayEstimateButton.styles';
import { OverlayEstimateIcon } from './OverlayEstimateIcon/OverlayEstimateIcon';
import { MenuContext } from '../../MenuContext';

export const OverlayEstimateButton = ({
  activeText,
  chatId,
  loadingText,
  text,
}) => {
  const [
    isChatActive,
    setChatActive,
  ] = useState(false);

  const [
    isChatLoading,
    setChatLoading,
  ] = useState(false);

  const handleClick = driftHandler({
    driftId: chatId,
    isChatActive,
    setChatActive,
    setChatLoading,
  });

  return (
    <MenuContext.Consumer>
      {({ isExpanded }) => (
        <OverlayEstimateButtonStyles
          isExpanded={isExpanded}
          onClick={handleClick}
        >
          {isChatActive && activeText }
          {isChatLoading && loadingText }
          {!isChatLoading && !isChatActive && text}
          <OverlayEstimateIcon />
        </OverlayEstimateButtonStyles>
      )}
    </MenuContext.Consumer>
  );
};

OverlayEstimateButton.propTypes = {
  activeText: PropTypes.string,
  chatId: PropTypes.number,
  loadingText: PropTypes.string,
  text: PropTypes.string,
};

OverlayEstimateButton.defaultProps = {
  activeText: 'Close chat',
  chatId: undefined,
  loadingText: 'Opening chat',
  text: 'Get an estimate',
};
