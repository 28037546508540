import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';
import ThumbnailImage from '@assets/images/our-work@2x.png';
import { Icon } from '@components';
import { colors } from '@utils/vars';
import { below } from '@utils/media-queries';
import {
  BackButton,
  Categories,
  ColumnContent,
  ColumnWithThumb,
  CustomScrollElement,
  Item,
  Label,
  ListItem,
  ListItemAnchor,
  ReadMoreButton,
  ReadMoreIconStyled,
} from '../../OverlaySubMenu.styled';

const {
  highlight,
  white,
} = colors;

const OurWorkContainer = styled.div`
  ${below.large`
    height: 100%;
  `}
`;

const industryWorkItems = [
  {
    id: 1,
    label: 'fintech',
    url: 'https://www.merixstudio.com/work/fintech/',
  },
  {
    id: 2,
    label: 'education',
    url: 'https://www.merixstudio.com/work/education/',
  },
  {
    id: 3,
    label: 'travel & hospitality',
    url: 'https://www.merixstudio.com/work/travel-hospitality/',
  },
  {
    id: 4,
    label: 'Product design',
    url: 'https://www.merixstudio.com/work/internet-media/',
  },
  {
    id: 5,
    label: 'entertainment',
    url: 'https://www.merixstudio.com/work/entertainment/',
  },
  {
    id: 6,
    label: 'e-commerce',
    url: 'https://www.merixstudio.com/work/e-commerce/',
  },
  {
    id: 7,
    label: 'other',
    url: 'https://www.merixstudio.com/work/other/',
  },
];

const expertiseWorkItems = [
  {
    id: 1,
    label: 'web app',
    url: 'https://www.merixstudio.com/work/web-app/',
  },
  {
    id: 2,
    label: 'mobile app',
    url: 'https://www.merixstudio.com/work/mobile-app/',
  },
  {
    id: 3,
    label: 'poc, mvp, prototype',
    url: 'https://www.merixstudio.com/work/poc-mvp-prototype/',
  },
  {
    id: 4,
    label: 'ui design',
    url: 'https://www.merixstudio.com/work/ui-design/',
  },
  {
    id: 5,
    label: 'saas',
    url: 'https://www.merixstudio.com/work/saas/',
  },
  {
    id: 6,
    label: 'machine learning',
    url: 'https://www.merixstudio.com/work/machine-learning/',
  },
  {
    id: 7,
    label: 'software modernization',
    url: 'https://www.merixstudio.com/work/software-modernization/',
  },
];
const componentTexts = {
  categoryOneLabel: 'Industry',
  categoryTwoLabel: 'Expertise',
  readMoreBtnText: 'Read more',
};

const {
  categoryOneLabel,
  categoryTwoLabel,
  readMoreBtnText,
} = componentTexts;

export const OurWork = ({ closeMenu }) => (
  <OurWorkContainer>
    <Item>
      <ColumnContent>
        <FadeIn>
          <BackButton onClick={closeMenu}>
            <Icon
              color={highlight}
              name="MenuBackIcon"
            />
          </BackButton>
          <CustomScrollElement>
            <Categories>
              <Label>{categoryOneLabel}</Label>
              <ul>
                {industryWorkItems.map((element) => (
                  <ListItem key={element.id}>
                    <ListItemAnchor href={element.url}>{element.label}</ListItemAnchor>
                  </ListItem>
                ))}
              </ul>
            </Categories>
            <Categories reduceMargin>
              <Label>{categoryTwoLabel}</Label>
              <ul>
                {expertiseWorkItems.map((element) => (
                  <ListItem key={element.id}>
                    <ListItemAnchor href={element.url}>{element.label}</ListItemAnchor>
                  </ListItem>
                ))}
              </ul>
            </Categories>
          </CustomScrollElement>
          <ReadMoreButton href="https://merixstudio.com/work/">
            {readMoreBtnText}
            <ReadMoreIconStyled>
              <Icon
                name="ReadMoreIcon"
                color={white}
              />
            </ReadMoreIconStyled>
          </ReadMoreButton>
        </FadeIn>
      </ColumnContent>
      <ColumnWithThumb>
        <img
          alt="thumbnail"
          src={ThumbnailImage}
        />
      </ColumnWithThumb>
    </Item>
  </OurWorkContainer>
);

OurWork.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};
