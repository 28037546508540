import styled, { css } from 'styled-components';
import { colors } from '@utils/vars';
import { menuBreakpoints } from '../../utils/media-queries';

const {
  black,
  highlight,
  white,
} = colors;

export const OverlayMenuButton = styled.button`
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 110;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 141px;
  height: 64px;
  border: none;
  background-color: ${({ isExpanded }) => (isExpanded ? black : highlight)};
  color: ${white};
  font-weight: 400;
  font-size: 16px;
  font-family: 'Pontiac', sans-serif;
  line-height: 20px;
  transition: opacity 0.3s ease, visibility 0.3s ease, background-color 0.2s ease;
  cursor: pointer;
  visibility: hidden;

  @media (max-width: ${menuBreakpoints.desktop}px) {
    top: 16px;
    right: 16px;
  };

  @media (max-width: ${menuBreakpoints.large}px) {
    top: 0;
    right: 0;
  }

  ${({ showMenuBtn }) => showMenuBtn && css`
    opacity: 1;
    visibility: visible;
  `}
`;
