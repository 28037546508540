import styled from 'styled-components';
import { colors } from '@utils/vars';

export const Background = styled.div`
  overflow: hidden;
  width: 100%;
  height: 20px;
  border-radius: 1000px;
  background-color: #ddd;
`;

export const Progress = styled.div`
  width: ${({ percentage }) => percentage}%;
  height: 100%;
  border-radius: 1000px;
  background-color: ${colors.highlight};
  transition: width 0.5s ease-in-out;
`;

