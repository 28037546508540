import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@utils/vars';
import ReactSelect, { components } from 'react-select';

const { highlight } = colors;
const valueStyles = {
  color: highlight,
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'calc(100% - 5px)',
};
const customStyles = {
  container: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '0',
    boxShadow: '0 2px 5px 0 rgba(184,184,184,0.5)',
    minHeight: '36px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    alignItems: 'center',
    display: 'flex',
    padding: '0 9px',
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '0',
    boxShadow: '0 2px 5px 0 rgba(184,184,184,0.5)',
    marginBottom: '0',
    marginTop: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingBottom: '8px',
    paddingTop: '8px',
  }),
  option: (provided, {
    isSelected,
    isDisabled,
  }) => {
    if (isDisabled) {
      return {
        ...provided,
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.5)',
      };
    }

    return ({
      ...provided,
      '&:hover': {
        background: 'rgba(255, 78, 0, 0.06)',
        color: highlight,
      },
      background: isSelected ? 'rgba(255, 78, 0, 0.06)' : '#fff',
      color: isSelected ? highlight : 'rgba(0,0,0,0.87)',
      padding: '12px',
    });
  },
  placeholder: (provided) => ({
    ...provided,
    ...valueStyles,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...valueStyles,
  }),
};

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  ${({ direction }) => (direction === 'up' ? 'border-bottom' : 'border-top')}: 8px solid #f00;
`;

const DropdownIndicator = (props) => {
  const { selectProps: { menuIsOpen } } = props;

  return (
    <components.DropdownIndicator {...props}>
      <Arrow direction={menuIsOpen ? 'up' : 'down'} />
    </components.DropdownIndicator>
  );
};

const Select = ({
  placeholder,
  handleChange,
  isClearable,
  options,
  selectedOption,
}) => {
  const value = selectedOption && options.find((item) => item.value === selectedOption);

  return (
    <ReactSelect
      components={{
        DropdownIndicator,
      }}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      value={value || (selectedOption &&
          {
            label: selectedOption,
            value: selectedOption,
          })}
      isClearable={isClearable}
    />
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }).isRequired,
};

Select.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedOption: PropTypes.string,
};

Select.defaultProps = {
  isClearable: true,
  selectedOption: null,
};

export default Select;
