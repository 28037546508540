import React from 'react';
import PropTypes from 'prop-types';
import InsightsTag from '@components/Insights/InsightsTag/InsightsTag';
import {
  Container,
  Icon,
  IconTitle,
  IconWrapper,
  TagsContainer,
  Thumbnail,
  ThumbnailContainerLink,
  Title,
  TitleLink,
} from './SinglePost.styled';

// blog: {
//   margin: '2px',
//     name: 'blog',
//     size: '39px',
//     title: 'read',
// },
// pdf: {
//   margin: '6px',
//     name: 'pdf-file',
//     size: '35px',
//     title: 'view',
// },
// url: {
//   margin: '7px',
//     name: 'url',
//     size: '35px',
//     title: 'browse',
//
// },
// video: {
//   margin: '7px',
//     name: 'play-circle',
//     size: '56px',
//     title: 'play',
// },

const TypeIcon = ({ type }) => {
  switch (type) {
  case 'blog':
    return (
      <IconWrapper type={type}>
        <Icon
          type={type}
          iconName="blog"
        />
        <IconTitle type={type}>read</IconTitle>
      </IconWrapper>
    );
  case 'pdf':
    return (
      <IconWrapper type={type}>
        <Icon
          type={type}
          iconName="pdf-file"
          test="asdf"
        />
        <IconTitle type={type}>view</IconTitle>
      </IconWrapper>
    );
  case 'link':
    return (
      <IconWrapper type={type}>
        <Icon
          type={type}
          iconName="url"
        />
        <IconTitle type={type}>browse</IconTitle>
      </IconWrapper>
    );
  case 'video':
    return (
      <IconWrapper type={type}>
        <Icon
          type={type}
          iconName="play-circle"
        />
        <IconTitle type={type}>play</IconTitle>
      </IconWrapper>
    );
  default:
    return (
      <IconWrapper>
        <Icon
          type="url"
          iconName="url"
        />
        <IconTitle type={type}>browse</IconTitle>
      </IconWrapper>
    );
  }
};

const SinglePost = ({
  data, activeFilters, onTagClick, pagePath, filtersQueryString,
}) => {
  const {
    title, image, industry = [], subtopics = [], technology = [], url,
  } = data;

  const handleTagClick = (filterName, value) => () => {
    onTagClick(filterName, value);
  };

  const contentUrl = filtersQueryString ? `${url}?${filtersQueryString}` : url;

  return (
    <Container>
      <ThumbnailContainerLink
        to={contentUrl}
        state={{ prevPath: pagePath }}
      >
        <Thumbnail src={image} />
        <TypeIcon type={data.content_type.medium_type} />
      </ThumbnailContainerLink>
      <TitleLink
        to={contentUrl}
        state={{ prevPath: pagePath }}
      >
        <Title>{title}</Title>
      </TitleLink>
      <TagsContainer>
        {subtopics.map((item) => (
          item.name && (
            <InsightsTag
              key={item.name}
              isActive={item.name === activeFilters.theme}
              onClick={handleTagClick('theme', item.name)}
            >
              {item.name}
            </InsightsTag>
          )
        ))}
        {industry.map((item) => (
          item.name && (
            <InsightsTag
              key={item.name}
              isActive={item.name === activeFilters.industry}
              onClick={handleTagClick('industry', item.name)}
            >
              {item.name}
            </InsightsTag>
          )
        ))}
        {technology.map((item) => (
          item.name && (
            <InsightsTag
              key={item.name}
              isActive={item.name === activeFilters.technology}
              onClick={handleTagClick('technology', item.name)}
            >
              {item.name}
            </InsightsTag>
          )
        ))}
      </TagsContainer>
    </Container>
  );
};

SinglePost.propTypes = {
  activeFilters: PropTypes.shape({
    industry: PropTypes.string,
    ordering: PropTypes.string,
    persona: PropTypes.string,
    technology: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    content_type: PropTypes.shape({
      medium_type: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.string.isRequired,
    industry: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    subtopics: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    technology: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    title: PropTypes.string.isRequired,
    type: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    url: PropTypes.string.isRequired,
  }).isRequired,
  filtersQueryString: PropTypes.string,
  onTagClick: PropTypes.func.isRequired,
  pagePath: PropTypes.string.isRequired,
};

SinglePost.defaultProps = {
  filtersQueryString: undefined,
};

TypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SinglePost;
