import styled, { css } from 'styled-components';
import { below } from '@utils/media-queries';
import { colors } from '@utils/vars';

const { white } = colors;

export const OverlayListButtonStyled = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  color: ${white};
  font-weight: 700;
  font-size: 48px;
  transition: color 0.2s ease;
  padding: 0;

  ${below.small`
    font-size: 32px;
    line-height: 1.3;
  `};

  ${({ isExpanded }) => isExpanded && css`
    color: rgba(255, 255, 255, 0.2);
  `}

  // TODO: dunno how to convert this to sc
  &.${({ setActiveElement }) => setActiveElement} {
    color: ${white};
  }
`;
