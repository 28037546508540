import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '@utils/vars';
import mediaQueries from '@utils/media-queries';

const {
  xxsmall,
  xsmall,
} = mediaQueries;

const {
  highlight: highlightColor,
} = colors;

const Element = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 200px;
  max-width: 710px;
  height: 71px;
  border: none;
  padding: 0 30px;
  background: none;
  color: ${highlightColor};
  font-weight: bold;
  font-size: 19px;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:focus:not(:focus-visible) {
    outline-style: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 71px;
    background-position-y: 0;
    background-size: 378px 71px;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  &::before {
    right: 50%;
    left: 0;
    background-image: url("/btn-left-side.svg");
    background-position-x: 0;
  }

  &::after {
    right: 0;
    left: 50%;
    background-image: url("/btn-right-side.svg");
    background-position-x: 100%;
  }
  ${({ isLoading }) => isLoading && css`
    &::before,
    &::after {
      animation: button-opacity-animation 0.5s both alternate infinite;
    }`}

  @keyframes button-opacity-animation {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.3;
    }
  }

  @media ${xsmall} {
    max-width: 100%;
    margin: auto;
  }

  @media ${xsmall} {
    padding: 0 30px;
    font-size: 14px;
  }

  @media ${xxsmall} {
    font-size: 12px;
  }`;

export const Label = styled.span`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: inherit;
    padding: 17px 17px 17px 18px;
    transition: transform 0.2s ease-out;
    transform: translate(0, 0);

  ${Element}:hover & {
    transform: translate(-1px, 1px);
  }

  ${Element}:active & {
    transform: translate(2px, -1px);
  }

  ${Element}:focus &::before {
    content: '';
    position: absolute;
    top: 8.5px;
    right: 8.5px;
    bottom: 8.5px;
    left: 9px;
    background: rgba(${highlightColor}, 0.2);
  }
`;
const Button = ({
  children,
  onClick,
  isLoading,
  className,
}) => (
  <Element
    disabled={isLoading}
    isLoading={isLoading}
    onClick={onClick}
    className={className}
  >
    <Label>
      {children}
    </Label>
  </Element>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: undefined,
  isLoading: false,
  onClick: null,
};

export default Button;
