import styled from 'styled-components';

export const OverlayEstimateIconStyled = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -4px 0 0 16px;
  transition: color ease;
  color:  ${({ isExpanded }) => (isExpanded ? '#000000' : '#ffffff')};
`;
