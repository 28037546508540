/* eslint-disable no-underscore-dangle */
/* eslint-disable sort-keys */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-multiple-empty-lines */

import throttle from '@utils/throttle';

const DEBUG = false;
const controlFPS = false;
const WORLD = {
  gravity: 0.15,
  wind: 0.15,
  windDirection: -1,
  rainIntensity: 0.5,
  rainLength: 1, // int only
  brightenStep: 0.01,
};

class Vector {
  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  add(v) {
    if (v.x != null && v.y != null) {
      this.x += v.x;
      this.y += v.y;
    } else {
      this.x += v;
      this.y += v;
    }

    return this;
  }

  copy() {
    return new Vector(this.x, this.y);
  }
}

class Drop {
  constructor(x, y, world, depth, index = 0) {
    this.world = world;
    this.index = index + 1;
    const dist = 2 + (Math.random() * 6);
    const angle = Math.PI + (Math.random() * Math.PI);

    this.pos = new Vector(x, y);
    this.vel = new Vector(
      Math.cos(angle) * dist,
      Math.sin(angle) * dist
    );

    this.opacity = depth;
    this.rgb = '255, 78, 0';

    if (DEBUG) {
      this.rgb = `${this._r(255)}, ${this._r(255)}, ${this._r(255)}`;
    }
  }

  _r(max) {
    return Math.floor(Math.random() * max);
  }

  _setOpacity(opacity) {
    return `rgba(${this.rgb}, ${opacity})`;
  }

  update() {
    this.color = this._setOpacity(this.opacity);
    this.opacity *= 0.97;

    this.vel.y += this.world.gravity;
    this.vel.x *= 0.9;
    this.vel.y *= 0.9;
    this.pos.add(this.vel);
  }

  draw(ctx) {
    const r = 1;

    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.arc(this.pos.x, this.pos.y, r, 0, Math.PI * 2);
    ctx.fill();

    if (DEBUG) {
      ctx.font = '10px sans-serif';
      const text = `x: ${this.pos.x.toFixed(2)} | y: ${this.pos.y.toFixed(2)}`;
      const textX = this.pos.x + 15;
      const textY = this.pos.y - r - 10 - (this.index * 20);

      ctx.fillText(text, textX, textY);
      ctx.beginPath();
      ctx.moveTo(this.pos.x, this.pos.y - r - 2);
      ctx.lineTo(textX, textY + 2);
      ctx.strokeStyle = this.color;
      ctx.stroke();
    }
  }
}

class Rain {
  constructor(canvas, world) {
    const vectorX = (Math.random() * (canvas.width + (canvas.height * 2))) - canvas.height;

    this.pos = new Vector(vectorX, -50);
    this.world = world;
    this.prev = this.pos;
    this.prevs = [];
    this.vel = new Vector();
    this.depths = [
      0.25,
      0.5,
      1,
    ];
    this.depth = this.depths[Math.floor(Math.random() * 10) % this.depths.length];
    this.stroke = `rgba(255, 78, 0, ${this.depth})`;

    this.delay = world.rainLength;
  }

  update() {
    this.prevs.push(this.pos.copy());
    this.prevs.splice(0, Math.max(0, this.prevs.length - this.delay));
    this.vel.y += (this.world.gravity * this.depth);
    this.vel.x += (this.world.wind * this.world.windDirection * this.depth);
    this.pos.add(this.vel);
    this.prev = this.prevs[0];
  }

  draw(ctx) {
    ctx.strokeStyle = this.stroke;
    ctx.beginPath();
    ctx.moveTo(this.pos.x, this.pos.y);
    ctx.lineTo(this.prev.x, this.prev.y);
    ctx.stroke();
  }
}

export class RainSection {
  constructor(element) {
    this.element = element;
    this.blockName = element.getAttribute('rain-section');
    this.brightenTrigger = element.querySelector('[brightenTrigger]');
    this.colliders = Array.from(element.querySelectorAll('[collider]'));

    this.elementRect = element.getBoundingClientRect();
    this.triggerRect = this.brightenTrigger && this.brightenTrigger.getBoundingClientRect();

    this.controlFPS = controlFPS;
    this.fpsInterval = 1000 / 12;
    this.startTime = null;
    this.now = null;
    this.then = null;
    this.elapsed = null;
    this.stop = null;

    this.requestId = null;
    this.resizeTimeout = null;

    this.onKeyup = this._onKeyup.bind(this);
    this.onScroll = this._onScroll.bind(this);
    this.onResize = this._onResize.bind(this);
    this.onClick = this._onClick.bind(this);
    this._onResizeEnd = this._onResizeEnd.bind(this);
    this._animate = this._animate.bind(this);
    this._onTriggerEnter = this._onTriggerEnter.bind(this);
    this._onTriggerLeave = this._onTriggerLeave.bind(this);

    document.addEventListener('scroll', throttle(this, 200, this.onScroll), false);
    window.addEventListener('keyup', this.onKeyup, false);
    window.addEventListener('resize', this.onResize, false);
    window.addEventListener('click', this.onClick, false);

    this._initialize();
    this.startRaining();
  }

  destroy() {
    this.canvas.remove();
    this.canvas = null;

    if (this.brightenTrigger) {
      this.brightenTrigger.remove();
    }
    this._initialize();
  }

  startRaining() {
    this._performanceControl();
    this._onScroll();

    this.then = Date.now();
  }

  _startAnimate() {
    if (!this.requestId) {
      this._animate();
      this.running = true;
    }
  }

  _stopAnimate() {
    if (this.requestId) {
      cancelAnimationFrame(this.requestId);
      this.requestId = null;
      this.running = false;
    }
  }

  _toggleRun(shouldRun) {
    if (shouldRun) {
      this._startAnimate();
    } else {
      this._stopAnimate();
    }
  }

  _animate() {
    this.requestId = window.requestAnimationFrame(this._animate);
    this.running = true;

    if (this.DEBUG && this.clicked) {
      return;
    }

    if (this.controlFPS) {
      this.now = Date.now();
      this.elapsed = this.now - this.then;

      if (this.elapsed > this.fpsInterval) {
        this.then = this.now - (this.elapsed % this.fpsInterval);
      } else {
        return;
      }
    }

    this._render();
  }

  _render() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.lineWidth = 1;

    this._renderDrops();
    this._controlWeather();
    this._controlRaindropsCount();
  }

  _renderDrops() {
    let i = this.rain.length;

    while (i--) {
      const raindrop = this.rain[i];

      raindrop.update();

      if (
        this.brightenTrigger &&
        ((
          raindrop.pos.x >= ((this.triggerRect.left + 16) - this.elementRect.left) &&
          raindrop.pos.x < ((this.triggerRect.right + 16) - this.elementRect.left) &&
          raindrop.pos.y >= (this.triggerRect.top - this.elementRect.top) &&
          raindrop.pos.y < ((this.triggerRect.top - this.elementRect.top) + (this.triggerRect.height - 16))
        ) ||
        raindrop.pos.y >= this.canvas.height)
      ) {
        this._dropHit(raindrop, i);
      }

      raindrop.draw(this.ctx);
    }

    let k = this.drops.length;

    while (k--) {
      const drop = this.drops[k];

      drop.update();
      drop.draw(this.ctx);

      if (drop.pos.y > this.canvas.height) {
        this.drops.splice(k, 1);
      }
    }
  }

  _dropHit(raindrop, i) {
    let n = Math.round(2 + (Math.random() * 4));

    while (n--) {
      this.drops.push(new Drop(raindrop.pos.x, Math.min(raindrop.pos.y, this.canvas.height), this.world, raindrop.depth, n));
    }

    this.rain.splice(i, 1);
  }

  _controlWeather() {
    if (this.sholudRain) {
      if (this.world.rainIntensity < WORLD.rainIntensity) {
        this.world.rainIntensity += WORLD.brightenStep / 3;
      }
    } else if (this.world.rainIntensity > 0) {
      this.world.rainIntensity -= WORLD.brightenStep;
    }
  }

  _controlRaindropsCount() {
    if (Math.random() < this.world.rainIntensity) {
      this.rain.push(new Rain(this.canvas, this.world));
    }
  }

  get _elementSize() {
    return {
      width: this.element.clientWidth,
      height: this.element.clientHeight,
    };
  }

  _isInView() {
    const rect = this.element.getBoundingClientRect();

    return rect.top < window.innerHeight && rect.bottom >= 0;
  }

  _initialize() {
    this.rain = [];
    this.drops = [];
    this.clicked = false;
    this.running = this._isInView();
    this.sholudRain = true;
    this.world = {
      ...WORLD,
    };

    this._createCanvas();

    if (this.brightenTrigger) {
      this.brightenTrigger.addEventListener('mouseenter', this._onTriggerEnter, false);
      this.brightenTrigger.addEventListener('mouseleave', this._onTriggerLeave, false);
    }
  }

  _createCanvas() {
    this.canvas = document.createElement('canvas');
    this.canvas.classList.add(`${this.blockName}__canvas`);
    this.canvas.width = this._elementSize.width;
    this.canvas.height = this._elementSize.height;
    this.element.appendChild(this.canvas);

    this.ctx = this.canvas.getContext('2d');
  }

  _onKeyup(event) {
    if (DEBUG) {
      if (event.key === 'r') {
        this.sholudRain = !this.sholudRain;
      }
    }
  }

  _onScroll() {
    this._toggleRun(this._isInView());
  }

  _onResize() {
    this.canvas.width = this._elementSize.width;
    this.canvas.height = this._elementSize.height;
    this.elementRect = this.element.getBoundingClientRect();
    this.triggerRect = this.brightenTrigger && this.brightenTrigger.getBoundingClientRect();
    this._stopAnimate();

    if (this.resizeTimeout !== null) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }

    this.resizeTimeout = setTimeout(this._onResizeEnd, 300);
  }

  _onResizeEnd() {
    this._startAnimate();
  }

  _onClick() {
    this.clicked = !this.clicked;
  }

  _onTriggerEnter() {
    this.sholudRain = false;
  }

  _onTriggerLeave() {
    this.sholudRain = true;
  }

  _performanceControl() {
    this._onVisibilistyChange = this._onVisibilistyChange.bind(this);

    if ('hidden' in document) {
      document.addEventListener('visibilitychange', this._onVisibilistyChange, false);
    } else {
      document.addEventListener('mozvisibilitychange', this._onVisibilistyChange, false);
      document.addEventListener('webkitvisibilitychange', this._onVisibilistyChange, false);
      document.addEventListener('msvisibilitychange', this._onVisibilistyChange, false);
    }
  }

  _onVisibilistyChange(event) {
    this._toggleRun(event.target.visibilityState === 'visible');
  }
}

