/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';
import PropTypes from 'prop-types';

import {
  getAllTags,
  getTagsCount,
} from '@utils/helpers';

import {
  colors,
  transitions,
} from '@utils/vars';

const {
  medium,
  small,
} = mediaQueries;
const { highlight: highlightColor } = colors;
const { duration } = transitions;

const FiltersButton = styled.button`
  position: relative;
  display: block;
  width: 100%;
  border: none;
  padding: 12px 23px;
  background: none;
  color: ${highlightColor};
  font-weight: 700;
  text-align: left;
  cursor: pointer;

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 23px;
    border-right: 6px solid transparent;
    border-bottom: 8px solid ${highlightColor};
    border-left: 6px solid transparent;
    transition: transform ${duration};
    transform: ${({ filtersVisible }) => (filtersVisible ? 'translate3d(0, -50%, 0)' : 'translate3d(0, -50%, 0) rotate3d(1, 0, 0, 180deg)')};
  }

  @media ${medium} {
    padding: 10px;

    &::after {
      right: 10px;
    }
  }

  @media ${small} {
    display: none;
  }
`;

const SubTopics = styled.ul`
  height: 100vh;
  padding: 23px 23px 0;

  @media ${medium} {
    padding: 23px 10px 0;
  }
`;

const SubTopic = styled.li`
  padding: 7px 0;

  @media ${medium} {
    padding: 10px 0;
  }
`;

const SingleFilter = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  padding: 0;
  background: none;
  color: ${highlightColor};
  line-height: 20px;
  cursor: pointer;
`;

const ResetFilters = styled.span`
  margin: 0 5px;
  font-weight: 100;
`;

const SubTopicCount = styled.span`
  width: 30%;
  font-size: 11px;
  text-align: right;

  @media ${medium} {
    width: 40%;
  }
`;

const SubTopicName = styled.span`
  font-weight: ${({
    selectedTag,
    tagName,
  }) => (selectedTag === tagName ? 'bold' : 'normal')};
`;

const FilterDropdown = ({
  filterTags,
  items,
  selectedTag,
}) => {
  const allTags = getAllTags(items);
  const tagsCount = getTagsCount(allTags, items, selectedTag);
  const [
    filtersVisible,
    setFiltersVisible,
  ] = useState(false);

  return (
    <nav>
      <FiltersButton
        onClick={() => setFiltersVisible(!filtersVisible)}
        filtersVisible={filtersVisible}
      >
        {selectedTag ? `Theme: ${selectedTag.charAt(0).toUpperCase() + selectedTag.slice(1)}` : 'Filter by theme'}
        { selectedTag && (
        <ResetFilters
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            filterTags(null);
          }}
        >
          ⨉
        </ResetFilters>
        )}
      </FiltersButton>
      { filtersVisible && (
        <SubTopics>
          { tagsCount.map((tag) => (
            <SubTopic key={`${tag.tagName}`}>
              <SingleFilter
                onClick={() => {
                  filterTags(tag.tagName);
                  setFiltersVisible(!filtersVisible);
                }}
              >
                <SubTopicName
                  selectedTag={selectedTag}
                  tagName={tag.tagName}
                >
                  {tag.tagName.charAt(0).toUpperCase() + tag.tagName.slice(1)}
                </SubTopicName>
                <SubTopicCount>{`${tag.count} assets`}</SubTopicCount>
              </SingleFilter>
            </SubTopic>
          ))}
        </SubTopics>
      )}
    </nav>
  );
};

FilterDropdown.propTypes = {
  filterTags: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedTag: PropTypes.string,
};

FilterDropdown.defaultProps = {
  selectedTag: null,
};

export default FilterDropdown;
