import React from 'react';
import PropTypes from 'prop-types';
import { OverlayContentListStyled } from './OverlayList.styled';
import { OverlayListButton } from './OverlayListButton/OverlayListButton';
import { OverlayListButtonSmall } from './OverlayListButtonSmall/OverlayListButtonSmall';

export const OverlayList = ({
  listItems,
  isOpened,
  activeElement,
}) => (
  <>
    {listItems.map((element) => (
      <OverlayContentListStyled key={element.dataSection}>
        { element && element.small ? (
          <OverlayListButtonSmall
            isActive={activeElement === element.dataSection ? 'active' : null}
            dataSection={element.dataSection}
            isOpened={isOpened}
            label={element.label}
          />
        ) : (
          <OverlayListButton
            isActive={activeElement === element.dataSection ? 'active' : null}
            dataSection={element.dataSection}
            isOpened={isOpened}
            label={element.label}
          />
        )}
      </OverlayContentListStyled>
    ))}
  </>
);

OverlayList.propTypes = {
  activeElement: PropTypes.string,
  isOpened: PropTypes.func.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      dataSection: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      small: PropTypes.bool,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

OverlayList.defaultProps = {
  activeElement: '',
};
