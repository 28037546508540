import React from 'react';
import PropTypes from 'prop-types';
import { OverlayMenu } from './OverlayMenu/OverlayMenu';
import { CloseIcon } from './CloseIcon/CloseIcon';
import { HamburgerIcon } from './HamburgerIcon/HamburgerIcon';
import { OverlayMenuButton } from './HamburgerMenu.styled';

const HamburgerMenu = ({
  activeElement,
  chatId,
  closeExpand,
  icon,
  isExpanded,
  manageMenuButtonState,
  showMenuBtn,
  toggleClick,
  toggleMenu,
}) => (
  <>
    <OverlayMenuButton
      isExpanded={isExpanded}
      onClick={manageMenuButtonState}
      showMenuBtn={showMenuBtn}
    >
      { icon === 'menu' ? <HamburgerIcon /> : <CloseIcon /> }
    </OverlayMenuButton>
    <OverlayMenu
      activeElement={activeElement}
      closeExpand={closeExpand}
      isExpanded={isExpanded}
      isOpened={toggleClick}
      visible={toggleMenu}
      chatId={chatId}
    />
  </>
);

HamburgerMenu.propTypes = {
  activeElement: PropTypes.string,
  chatId: PropTypes.number,
  closeExpand: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  manageMenuButtonState: PropTypes.func.isRequired,
  showMenuBtn: PropTypes.bool,
  toggleClick: PropTypes.func.isRequired,
  toggleMenu: PropTypes.bool.isRequired,
};

HamburgerMenu.defaultProps = {
  activeElement: '',
  chatId: undefined,
  showMenuBtn: false,
};

export default HamburgerMenu;
