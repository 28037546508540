/* eslint-disable sort-keys */
import { css } from 'styled-components';
import { breakpoints } from '@utils/media-queries';
import {
  colors, font, spacings,
} from '@utils/vars';

const pxToInt = (px) => px.replace(/[^\d]/g, '');

const calculateBreakpoint = (breakpoint) => breakpoint / pxToInt(font.size);

const {
  transparentGray,
} = colors;

const {
  small,
  medium,
  large,
} = breakpoints;

const {
  desktopPadding, mobilePadding, tabletPadding,
} = spacings;

export const config = {
  mediaQuery: 'only screen',
  columns: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 12,
    xl: 12,
  },
  gutterWidth: {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
  },
  paddingWidth: {
    xs: calculateBreakpoint(pxToInt(mobilePadding)),
    sm: calculateBreakpoint(pxToInt(tabletPadding)),
    md: calculateBreakpoint(pxToInt(desktopPadding)),
    lg: calculateBreakpoint(pxToInt(desktopPadding)),
    xl: calculateBreakpoint(pxToInt(desktopPadding)),
  },
  container: {
    xs: 'full',
    sm: 'full',
    md: 'full',
    lg: 'full',
    xl: 'full',
  },
  breakpoints: {
    xs: 1,
    sm: calculateBreakpoint(small),
    md: calculateBreakpoint(medium),
    lg: calculateBreakpoint(large),
    xl: calculateBreakpoint(1729),
  },
};

export const insightsGridConfig = {
  ...config,
  container: {
    ...config.container,
    xl: 120,
  },
};

export const borderMixin = (side = 'left') => css`
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: ${transparentGray};
  ${side}: 0;
`;

export const headerStairs = [
  70,
  20,
  124,
  24,
  -16,
  20,
  -16,
  0,
  -40,
  0,
  0,
  0,
  -54,
  -106,
];

export const homeHeaderStairs = [
  -90,
  -150,
  -150,
  -80,
  -80,
  -80,
  0,
  -60,
  0,
  -152,
  -74,
  -103,
  -175,
  -72,
];
export const homeHeaderStairsMedium = [
  -30,
  -50,
  -50,
  -26,
  -26,
  -26,
  0,
  -20,
  0,
  -50,
  -18,
  -35,
  -60,
  -35,
];

export const headerStairsMobile = [
  0,
  20,
  -10,
  -30,
  -20,
  -80,
];

export const homeHeaderStairsMobile = [
  0,
  20,
  -10,
  -30,
  0,
  -30,
];

export const reviewsStairs = [
  248,
  280,
  212,
  184,
  214,
  144,
  282,
  218,
  24,
  58,
  146,
  0,
  60,
  60,
];

export const reviewsStairsMobile = [
  30,
  60,
  30,
  10,
  40,
  70,
];

export const homeContentStairs = [
  70,
  20,
  124,
  24,
  -16,
  20,
  -16,
  0,
  -40,
  0,
  0,
  0,
  -54,
  -106,
];
export const homeCollectionsStairs = [
  10,
  50,
  90,
  40,
  40,
  50,
  60,
  30,
  90,
  70,
  100,
  100,
  80,
  90,
];
export const homeLeadersStairs = [
  -10,
  -20,
  -90,
  -40,
  -50,
  -60,
  -70,
  -30,
  -20,
  -60,
  -70,
  -10,
  -70,
  -40,
];

export default config;
