import { css } from 'styled-components';

export const fadeInFromBottom = () => css`
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: 0.15s;
  transition-timing-function: ease, ease;
  transform: translateY(0);
`;

export const fadeOutToBottom = () => css`
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.15s;
  transition-timing-function: ease, ease;
  transform: translateY(20px);
`;

export const scaleFadeIn = css`
  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: 0.15s;
  transition-timing-function: ease, ease;
  transform: scale(1);
`;

export const scaleFadeOut = css`
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.15s;
  transition-timing-function: ease, ease;
  transform: scale(0.8);
`;

export const animatedRowAnimation = () => {
  let styles = '';

  for (let i = 0; i <= 6; i += 1) {
    styles += `
      &:nth-child(#{6-${i}}) {
        transition-delay: #{(${i} - 1) * 50}ms, #{(${i} - 1) * 50}ms;
      }
    `;
  }

  return css`${styles}`;
};
