import React from 'react';
import PropTypes from 'prop-types';
import { OverlaySocialsLabelStyled } from './OverlaySocialsLabel.styled';

const componentLabel = 'Socials';

export const OverlaySocialsLabel = ({ hideSocialLabel }) => (
  <OverlaySocialsLabelStyled hideSocialLabel={hideSocialLabel}>
    {componentLabel}
  </OverlaySocialsLabelStyled>
);

OverlaySocialsLabel.propTypes = {
  hideSocialLabel: PropTypes.bool.isRequired,
};

