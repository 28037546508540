import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';
import { Link } from 'gatsby';
import {
  Icon,
  Tag,
} from '@components';
import {
  colors,
  transitions,
} from '@utils/vars';
import { getUrlParams } from '@utils/helpers';
import { linkIcons } from '@utils/maps';

const {
  medium,
  small,
} = mediaQueries;

const { highlight: highlightColor } = colors;

const { duration } = transitions;

const CardItem = styled.div`
  position: relative;
  display: flex;
  min-width: 33.3%;
  height: ${({ height }) => height}px;
  border: solid 1px #CCCCCC;
  font-family: 'Pontiac', sans-serif;
  line-height: 18px;
  transition: color ${duration};

  &:hover {
    color: ${highlightColor};
  }

  @media ${small} {
    width: 100%;
    scroll-snap-align: center;
  }
`;

const Title = styled.div`
  padding-top: 17px;
  font-size: 15px;
  line-height: 25px;
`;

const LinkElement = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 21px;

  @media ${medium} {
    flex-direction: column;
  }
`;

const Tags = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0 0;
`;

const Thumbnail = styled.figure`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 140px;
`;

const HoverContainer = styled.div`
  position: absolute;
  opacity: ${({ isIconVisible }) => (isIconVisible ? '1' : '0')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: ${highlightColor};
  transition: ${transitions.duration} ease-out;

  ${LinkElement}:hover & {
    opacity: 1;
    background-color: rgba(0,0,0,0.7);
  }
`;
const iconTitleHeight = '24px';
const IconTitle = styled.h5`
  opacity: 0;
  height: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: ${iconTitleHeight};
  transition: ${transitions.duration} ease-out;

  ${LinkElement}:hover & {
    opacity: 1;
    height: ${iconTitleHeight};
  }
`;

const Card = ({
  content,
  content: {
    id,
    index,
    innerSlug,
    thumbnail,
    tags,
    title,
    type,
  },
  filterTags,
  height,
  selectedTag,
  slug,
}) => {
  const urlParams = getUrlParams(selectedTag);

  return (
    <CardItem
      id={innerSlug}
      index={index}
      height={height}
    >
      <LinkElement
        id={id}
        state={{ content }}
        to={`/${slug}/${innerSlug}/${urlParams ? `${urlParams}` : ''}`}
      >
        <Thumbnail>
          <HoverContainer isIconVisible={type === 'video'}>
            <Icon
              margin={`0 0 ${linkIcons[type].margin} 0`}
              name={linkIcons[type].name}
              size={linkIcons[type].size}
            />
            <IconTitle>
              {linkIcons[type].title}
            </IconTitle>
          </HoverContainer>
          <img
            alt="thumbnail"
            src={thumbnail}
          />
        </Thumbnail>
        <Title>
          {title}
          <Tags>
            {tags.map((tag) => (
              <Tag
                key={`tag-${tag}`}
                onClick={(event) => {
                  event.preventDefault();
                  filterTags(tag);
                }}
                selectedTag={selectedTag}
                tag={tag}
                large
              >
                {tag}
              </Tag>
            ))}
          </Tags>
        </Title>
      </LinkElement>
    </CardItem>
  );
};

Card.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    innerSlug: PropTypes.string,
    tags: PropTypes.array,
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  filterTags: PropTypes.func.isRequired,
  height: PropTypes.number,
  selectedTag: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

Card.defaultProps = {
  height: 300,
  selectedTag: null,
};

export default Card;
