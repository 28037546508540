import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';
import { Link } from 'gatsby';
import {
  Icon,
  Tag,
  Tags,
  Thumbnail,
} from '@components';
import {
  colors,
  dimensions,
  transitions,
} from '@utils/vars';

const {
  xlarge,
  medium,
  small,
} = mediaQueries;

const {
  border: borderColor,
  highlight: highlightColor,
  lightGray: lightGrayColor,
  shadow: shadowColor,
} = colors;

const {
  nextPageBoxWidth,
  nextPageBoxWithTagsWidth,
} = dimensions;

const { duration } = transitions;

const Header = styled.header`
  position: relative;
  box-shadow: 0 2px 5px 0 ${shadowColor};
  padding: ${({ hasTags }) => (hasTags ? '10px 13vw 10px 0' : '10px 11vw 10px 0')};
  color: ${highlightColor};
  font-weight: 700;
  font-size: 20px;
  text-align: right;
  transition: padding ${duration};

  @media ${xlarge} {
    font-size: 18px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 65%;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid ${highlightColor};
    transition: right ${duration}, transform ${duration};
    transform: translateY(-50%);
  }

  @media ${xlarge} {
    padding: 10px 15vw 10px 0;
  }

  @media ${medium} {
    padding: 5px 18vw 5px 0;
  }
`;

const Content = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: ${({ hasTags }) => (hasTags ? 'flex-start' : 'center')};
  max-height: 0;
  padding: 0 28px 0 40px;
  color: #000;
  font-weight: 700;
  line-height: 18px;
  transition: color ${duration}, max-height ${duration};

  @media ${xlarge} {
    padding: 0 15px 0 15px;
  }

  @media ${medium} {
    flex-direction: column;
    padding: 0 10px;
  }
`;

const Wrapper = styled.nav`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  width: ${({ hasTags }) => (hasTags ? `${nextPageBoxWithTagsWidth}` : `${nextPageBoxWidth}`)};
  border: 1px solid ${borderColor};
  background-color: #fff;
  transition: transform ${duration};
  transform: translate3d(50%, calc(100% - 130px), 0);

  &:hover ${Content} {
    max-height: 200px;
    padding: 32px 28px 32px 40px;
    color: ${highlightColor};

    @media ${xlarge} {
      padding: 20px 15px;
    }

    @media ${medium} {
      max-height: 250px;
      padding: 10px;
    }
  }

  &:hover ${Header} {
    padding: 10px 70px 10px 0;
    &::after {
      right: 10%;
      transform: translate3d(20px, -50%, 0);
    }
  }

  &:hover {
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }

  @media ${xlarge} {
    width: ${({ hasTags }) => (hasTags ? '20vw' : '18vw')};
  }

  @media ${medium} {
    width: ${({ hasTags }) => (hasTags ? '25vw' : '22vw')};
    transform: translate3d(50%, calc(100% - 76px), 0);
  }

  @media ${small} {
    display: none;
  }
`;

const Title = styled.div`
  width: ${({ hasTags }) => (hasTags ? '55%' : 'calc(100% - 41px)')};

  @media ${medium} {
    width: 100%;
    padding: 5px 0 0;
  }
`;

const NextPageBox = ({
  currentIndex,
  data,
  filterTags,
  hasTags,
  selectedTag,
  slug,
  text,
}) => {
  const nextId = currentIndex + 1;
  const content = {
    index: nextId,
    ...data[nextId],
  };

  const {
    innerSlug,
    title,
    thumbnail,
    tags,
    type,
  } = content || {};

  if (title) {
    if (hasTags) {
      return (
        <Wrapper hasTags={hasTags}>
          <Link
            to={`/${slug}/${innerSlug}${selectedTag ? `?tag=${selectedTag}` : ''}`}
            state={{ content }}
          >
            <Header hasTags={hasTags}>
              {text}
            </Header>
            <Content hasTags={hasTags}>
              <Thumbnail
                src={thumbnail}
                type={type}
              />
              <Title hasTags={hasTags}>
                {title}
                <Tags>
                  {tags.map((tag) => (
                    <Tag
                      key={`tag-${tag}`}
                      onClick={(event) => {
                        event.preventDefault();
                        filterTags(tag);
                      }}
                      selectedTag={selectedTag}
                      tag={tag}
                    >
                      {tag}
                    </Tag>
                  ))}
                </Tags>
              </Title>
            </Content>
          </Link>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Link
          to={`/${slug}/${innerSlug}`}
          state={{ content }}
        >
          <Header>
            { text }
          </Header>
          <Content>
            <Icon
              name={type}
              color={lightGrayColor}
            />
            <Title>{ title }</Title>
          </Content>
        </Link>
      </Wrapper>
    );
  }

  return null;
};

NextPageBox.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterTags: PropTypes.func.isRequired,
  hasTags: PropTypes.bool,
  selectedTag: PropTypes.string,
  slug: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

NextPageBox.defaultProps = {
  hasTags: true,
  selectedTag: null,
};

export default NextPageBox;
