import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';
import ThumbnailImage from '@assets/images/insights@2x.png';
import { Icon } from '@components';
import { colors } from '@utils/vars';
import { below } from '@utils/media-queries';
import {
  BackButton,
  Categories,
  ColumnContent,
  ColumnWithThumb,
  CustomScrollElement,
  Item,
  Label,
  ListItem,
  ListItemAnchor,
  ReadMoreButton,
  ReadMoreIconStyled,
} from '../../OverlaySubMenu.styled';

const {
  highlight,
  white,
} = colors;

const InsightsContainer = styled.div`
  ${below.large`
    height: 100%;
  `}
`;

const contentCollectionItems = [
  {
    id: 1,
    label: 'IT sourcing',
    url: 'https://content.merixstudio.com/insights/it-outsourcing',
  },
  {
    id: 2,
    label: 'product development',
    url: 'https://content.merixstudio.com/insights/product-development',
  },
  {
    id: 3,
    label: 'software modernization',
    url: 'https://content.merixstudio.com/insights/software-modernization',
  },
  {
    id: 4,
    label: 'web app development',
    url: 'https://content.merixstudio.com/insights/web-app-development',
  },
  {
    id: 5,
    label: 'mobile app development',
    url: 'https://content.merixstudio.com/insights/mobile-app-development',
  },
];

const contentTypeItems = [
  {
    id: 1,
    label: 'video',
    url: 'https://content.merixstudio.com/insights/?type=video',
  },
  {
    id: 2,
    label: 'article',
    url: 'https://content.merixstudio.com/insights/?type=article',
  },
  {
    id: 3,
    label: 'ebook',
    url: 'https://content.merixstudio.com/insights/?type=ebook',
  },
  {
    id: 4,
    label: 'slide deck',
    url: 'https://content.merixstudio.com/insights/?type=slide%20deck',
  },
  {
    id: 5,
    label: 'report',
    url: 'https://content.merixstudio.com/insights/?type=report',
  },
  {
    id: 6,
    label: 'interview',
    url: 'https://content.merixstudio.com/insights/?type=interview',
  },
  {
    id: 7,
    label: 'online talk',
    url: 'https://content.merixstudio.com/insights/?type=online%20talk',
  },
  {
    id: 8,
    label: 'white papter',
    url: 'https://content.merixstudio.com/insights/?type=whitepaper',
  },
  {
    id: 9,
    label: 'design stories',
    url: 'https://content.merixstudio.com/insights/?type=design%20stories',
  },
];

const componentTexts = {
  categoryOneLabel: 'Content collections',
  categoryTwoLabel: 'Content type',
  readMoreBtnText: 'Read more',
};
const {
  categoryOneLabel, categoryTwoLabel, readMoreBtnText,
} = componentTexts;

export const Insights = ({ closeMenu }) => (
  <InsightsContainer>
    <Item>
      <ColumnContent>
        <FadeIn>
          <BackButton onClick={closeMenu}>
            <Icon
              color={highlight}
              name="MenuBackIcon"
            />
          </BackButton>
          <CustomScrollElement>
            <Categories>
              <Label>{categoryOneLabel}</Label>
              <ul>
                {contentCollectionItems.map((el) => (
                  <ListItem key={el.id}>
                    <ListItemAnchor href={el.url}>{el.label}</ListItemAnchor>
                  </ListItem>
                ))}
              </ul>
            </Categories>
            <Categories reduceMargin>
              <Label>{categoryTwoLabel}</Label>
              <ul>
                {contentTypeItems.map((element) => (
                  <ListItem key={element.id}>
                    <ListItemAnchor href={element.url}>{element.label}</ListItemAnchor>
                  </ListItem>
                ))}
              </ul>
            </Categories>
          </CustomScrollElement>
          <ReadMoreButton href="/insights/">
            {readMoreBtnText}
            <ReadMoreIconStyled>
              <Icon
                name="ReadMoreIcon"
                color={white}
              />
            </ReadMoreIconStyled>
          </ReadMoreButton>
        </FadeIn>
      </ColumnContent>
      <ColumnWithThumb>
        <img
          alt="thumbnail"
          src={ThumbnailImage}
        />
      </ColumnWithThumb>
    </Item>
  </InsightsContainer>
);

Insights.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};
